import { useList, useTranslate } from "@pankod/refine-core";

import {
    Form,
    FormProps,
    Input,
    DatePicker,
    Row,
    Col,
    Button,
    Select,
} from "@pankod/refine-antd";
import { IAreaIncident } from "interfaces";
import { OILED_CHOICES, ZOOGROUP_CHOICES } from "helpers/constants";
import { useState } from "react";

const { Option } = Select;

export interface FilterFormProps extends FormProps {
    applyFilterCallback?: (values?:any) => void;    
}

export const Filter: React.FC<{ formProps: FilterFormProps }> = (props) => {
    const t = useTranslate();
    const { RangePicker } = DatePicker;
    const areas = useList<IAreaIncident>({ resource: "area_incidents" });
    const taxonomics = useList<IAreaIncident>({ resource: "taxonomics" });
    const [zooGroup, setZooGroup] = useState<string | null>(null);

    const onSubmit = (e: any) => {
        props.formProps.applyFilterCallback ? props.formProps.applyFilterCallback(props.formProps.form?.getFieldsValue()) : null;
    }

    return (

        <Form onSubmitCapture={onSubmit} layout="vertical" {...props.formProps} style={{ padding: 15 }}>
            <Row gutter={[10, 0]} align="bottom">
                <Col xs={24} xl={24} md={12}>
                    <Row gutter={[8, 16]} wrap>
                        <Col xs={24} lg={12}>
                            <Form.Item label={t("sightings.fields.id")} name="id">
                                <Input
                                    placeholder={t("sightings.fields.id")}
                                />
                            </Form.Item>
                        </Col>

                        <Col xs={24} lg={12}>
                            <Form.Item label={t("sightings.fields.monitoring")} name={["monitoring", "id"]}>
                                <Input
                                    placeholder={t("sightings.fields.monitoring")}
                                />
                            </Form.Item>
                        </Col>
                    </Row>
                </Col>


                <Col xs={24} xl={24} md={12}>
                    <Form.Item
                        label={t("sightings.filter.date")}
                        name="date"
                    >
                        <RangePicker format={'DD/MM/YYYY'} placeholder={[t('sightings.filter.startDate'), t('sightings.filter.endDate')]} style={{ width: "100%" }} />
                    </Form.Item>
                </Col>

                <Col xs={24} xl={24} md={12}>
                    <Form.Item
                        label={t("sightings.filter.areaIncident")}
                        name={["monitoring", "areaIncident"]}
                    >
                        <Select
                            allowClear
                            showSearch
                            filterOption={(input, option) =>
                                (option!.children as unknown as string).toLowerCase().includes(input.toLowerCase())
                            }
                        >
                            {
                                areas?.data &&
                                areas?.data?.data.map((el: any) => <Option key={el.id} value={el['@id']}>{el.description}</Option>)
                            }
                        </Select>
                    </Form.Item>
                </Col>

                <Col xs={24} xl={24} md={12}>
                    <Form.Item
                        label={t("sightings.fields.specie")}
                        name={["specie", "id"]}
                    >
                        <Select
                            style={{fontStyle:'italic'}}
                            allowClear
                            showSearch
                            filterOption={(input, option) =>
                                (option!.children as unknown as string).toLowerCase().includes(input.toLowerCase())
                            }
                        >
                            {
                                taxonomics?.data &&
                                taxonomics?.data?.data.map((el: any) => <Option style={{fontStyle:'italic'}} key={el.id} value={el['@id']}>{el.fullName}</Option>)
                            }
                        </Select>
                    </Form.Item>
                </Col>

                <Col xs={24} xl={24} md={12}>
                    <Form.Item label={t("sightings.fields.speciesDescription")} name="speciesDescription">
                        <Input
                            placeholder={t("sightings.fields.speciesDescription")}
                        />
                    </Form.Item>
                </Col>

                <Col xs={24} xl={24} md={12}>
                    <Form.Item
                        label={t("sightings.fields.zooGroup")}
                        name="zooGroup"
                    >
                        <Select 
                            style={{fontStyle:(zooGroup !== 'other' ? 'italic' : 'normal')}}
                            onChange={v => setZooGroup(v)}
                            showSearch 
                            allowClear
                            filterOption={(input, option) =>
                                (option!.children as unknown as string).toLowerCase().includes(input.toLowerCase())
                            }
                        >
                            {
                                ZOOGROUP_CHOICES.map(el => <Option style={{ fontStyle: (el.value !== 'other' ? 'italic' : 'normal') }} key={el.value} value={el.value}>{t(el.label)}</Option>)
                            }
                        </Select>
                    </Form.Item>
                </Col>

                <Col xs={24} xl={24} md={12}>
                    <Row gutter={[8, 16]} wrap>
                        <Col xs={24} lg={12}>
                            <Form.Item
                                label={t("sightings.fields.condition")}
                                name="condition"
                            >
                                <Select showSearch allowClear
                                    filterOption={(input, option) =>
                                        (option!.children as unknown as string).toLowerCase().includes(input.toLowerCase())
                                    }
                                >
                                    <Option value={true}>{t('words.alive')}</Option>
                                    <Option value={false}>{t('words.dead')}</Option>
                                </Select>
                            </Form.Item>
                        </Col>

                        <Col xs={24} lg={12}>
                            <Form.Item
                                label={t("sightings.fields.oiled")}
                                name="oiled"
                            >
                                <Select showSearch allowClear
                                    filterOption={(input, option) =>
                                        (option!.children as unknown as string).toLowerCase().includes(input.toLowerCase())
                                    }
                                >
                                    {
                                        OILED_CHOICES.map(el => <Option key={el.value} value={el.value}>{t(el.label)}</Option>)
                                    }
                                </Select>
                            </Form.Item>
                        </Col>
                    </Row>
                </Col>

                <Col xs={24} xl={24} md={8}>
                    <Form.Item>
                        <Button
                            style={{ width: "100%" }}
                            htmlType="submit"
                            type="primary"
                        >
                            {t("words.filter")}
                        </Button>
                    </Form.Item>
                </Col>
            </Row>
        </Form>
    );
};