import React, { useState, useEffect } from "react";
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/styles';

import {
    DragDropContext,
    Droppable,
} from 'react-beautiful-dnd';
import LayerItem from "./LayerItem";
import { LAYERS } from "../defaultLayers";

const useStyles = makeStyles({
    flexPaper: {
        flex: 1,
        margin: 16,
        minWidth: 350
    },
    root: {
        display: 'flex',
        flexWrap: 'wrap'
    }
});

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 600,
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
    p: 4,
};

export const reorder = (
    list,
    startIndex,
    endIndex
) => {
    const result = Array.from(list);
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);

    return result;
};


const MapMenu = ({
    layers,
    applyFilterCallback,
    handleLayerCallback,
    dragLayerCallback,
    handleFilterCallBack
}) => {

    const classes = useStyles();
    const [items, setItems] = useState([]);

    useEffect(() => {
        if (layers && layers.length > 0) {
            setItems(layers);
        } else {
            setItems(LAYERS);
        }


    }, [layers])

    const onDragEnd = ({ destination, source }) => {
        if (!destination) return;

        const newItems = reorder(items, source.index, destination.index);

        setItems(newItems);

        dragLayerCallback(newItems);
    };

    return (
        <div className={classes.root}>
            <div className={classes.flexPaper}>
                <DragDropContext onDragEnd={onDragEnd}>
                    <Droppable droppableId="droppable-list">
                        {provided => (
                            <div 
                            ref={provided.innerRef} 
                            {...provided.droppableProps}>
                                {items.map((item, index) => (
                                    <LayerItem {...item}
                                        filterDefaultValues={item.filterDefaultValues}
                                        handleLayerCallback={handleLayerCallback}
                                        filterButtonCallback={applyFilterCallback}
                                        handleFilterCallBack={handleFilterCallBack}
                                        index={index}
                                        key={item.id} />
                                ))}
                                {provided.placeholder}
                            </div>
                        )}
                    </Droppable>
                </DragDropContext>

            </div>
        </div>
    )
}

MapMenu.propTypes = {
    layers: PropTypes.array,
    filterCallback: PropTypes.func,
    handleLayerCallback: PropTypes.func,
    dragLayerCallback: PropTypes.func,
}

MapMenu.defaultProps = {
    layers: [],
    applyFilterCallback: () => { },
    handleLayerCallback: () => { },
    dragLayerCallback: () => { },
    handleFilterCallBack: () => { }
}

export default MapMenu;

/*


*/