import { HttpError, IResourceComponentsProps, useTranslate } from "@pankod/refine-core";

import {
    Create,
    Form,
    Input,
    useForm,
    Row,
    Col,
    Edit,
} from "@pankod/refine-antd";

import { ITaxonomic } from "interfaces";

import { useFromErrorsProviver } from '../../helpers/formErrorsProvider'

const FormComponent = (props: any) => {
    const t = useTranslate();
    const { formProps } = props;


    return <Form
        {...formProps}
        layout="vertical"
        initialValues={{
            isActive: true,
            ...formProps.initialValues,
        }}
    >
        <Row gutter={[64, 0]} wrap>
            <Col xs={24} lg={{span:8, offset:8}}>
                <Form.Item
                    label={t("taxonomics.fields.name")}
                    name="name"
                    rules={[
                        {
                            required: false,
                        },
                    ]}
                >
                    <Input />
                </Form.Item>
                <Form.Item
                    label={t("taxonomics.fields.class")}
                    name="class"
                    rules={[
                        {
                            required: true,
                        },
                    ]}
                >
                    <Input />
                </Form.Item>
                <Form.Item
                    label={t("taxonomics.fields.order")}
                    name="order"
                    rules={[
                        {
                            required: true,
                        },
                    ]}
                >
                    <Input />
                </Form.Item>
                <Form.Item
                    label={t("taxonomics.fields.suborder")}
                    name="suborder"
                    rules={[
                        {
                            required: false,
                        },
                    ]}
                >
                    <Input />
                </Form.Item>
                <Form.Item
                    label={t("taxonomics.fields.family")}
                    name="family"
                    rules={[
                        {
                            required: false,
                        },
                    ]}
                >
                    <Input />
                </Form.Item>
                <Form.Item
                    label={t("taxonomics.fields.genus")}
                    name="genus"
                    rules={[
                        {
                            required: false,
                        },
                    ]}
                >
                    <Input />
                </Form.Item>
                <Form.Item                    
                    label={t("taxonomics.fields.specie")}
                    name="specie"
                    rules={[
                        {
                            required: true,
                        },
                    ]}
                >
                    <Input style={{fontStyle:'italic'}} />
                </Form.Item>

            </Col>
        </Row>
    </Form>


}

export const TaxonomicForm: React.FC<IResourceComponentsProps> = (props: any) => {

    const { formProps, form, saveButtonProps, queryResult, id } = useForm<ITaxonomic>({
        onMutationError: (httpError: HttpError) => {
            form?.setFields(useFromErrorsProviver(httpError));
        }
    });


    saveButtonProps.size = 'large';

    if (id) {
        return <Edit
            isLoading={queryResult?.isFetching}
            saveButtonProps={saveButtonProps}
            recordItemId={id}
            canDelete={false}
        >
            <FormComponent formProps={formProps} />
        </Edit>
    }

    return (
        <Create
            isLoading={queryResult?.isFetching}
            saveButtonProps={saveButtonProps}
        >
            <FormComponent formProps={formProps} />
        </Create>
    );
};