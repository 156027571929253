import { HttpError, IResourceComponentsProps, useList, useTranslate } from "@pankod/refine-core";

import {
    Create,
    Form,
    useForm,
    Row,
    Col,
    Edit,
    Select,
    Input,
    Radio,
    RadioChangeEvent,
    Typography
} from "@pankod/refine-antd";

import { IState, ICity, IAreaIncident, IIncident, ICountry } from "interfaces";
import { useFromErrorsProviver } from '../../helpers/formErrorsProvider'
import { useEffect, useState } from "react";
import { FilesForm } from "components/form/files";
const { Title, Text } = Typography;

const { Option } = Select;

const FormComponent = (props: any) => {
    const t = useTranslate();
    const { formProps } = props;
    const { initialValues } = formProps;
    const [stateFilter, setStateFilter] = useState<any>(0);
    const [type, setType] = useState(null);

    const incidentList = useList<IIncident>({
        resource: "incidents", config: {
            filters: [{
                field: "finished",
                operator: "eq",
                value: false,
            }]
        }
    });

    const countries = useList<ICountry>({ resource: "countries" });
    const states = useList<IState>({ resource: "states" });
    const cities = useList<ICity>({
        resource: "cities", config: {
            filters: [{
                field: "state",
                value: stateFilter,
                operator: "eq"
            }]
        }
    });

    useEffect(() => {
        if (initialValues?.area?.state && stateFilter === 0) {
            setStateFilter(initialValues.area.state['@id']);
        }

        if (type === null && initialValues?.area.type) {
            setType(initialValues?.area.type);
        }
    }, [initialValues])

    const getCities = (value: any) => {
        if (!value)
            return;

        setStateFilter(value);
    }

    return <Form
        {...formProps}
        layout="vertical"
        initialValues={{
            area: {
                name: initialValues?.area?.name,
                country: initialValues?.area?.country ? initialValues.area.country['@id'] : null,
                state: initialValues?.area?.state ? initialValues.area.state['@id'] : null,
                city: initialValues?.area?.city ? initialValues.area.city['@id'] : null,
                shpFile: initialValues?.area?.shpFile,
                shxFile: initialValues?.area?.shxFile,
                dbfFile: initialValues?.area?.dbfFile,
                type: initialValues?.area?.type,
                initialCoordinate: initialValues?.area?.initialCoordinate,
                finalCoordinate: initialValues?.area?.finalCoordinate
            },
            incident: initialValues?.incident ? initialValues?.incident['@id'] : null,
        }}
    >

        <Row >
            <Col xs={24} xl={{ span: 8, offset: 8 }}>


                {
                    (!initialValues?.incident || initialValues?.incident.finished === false) &&

                    <Form.Item
                        label={t("area_incidents.fields.incident")}
                        name="incident"
                        rules={[
                            {
                                required: true,
                            },
                        ]}
                    >
                        <Select showSearch
                            filterOption={(input, option) =>
                                (option!.children as unknown as string).toLowerCase().includes(input.toLowerCase())
                            }
                        >
                            {
                                incidentList?.data &&
                                incidentList.data.data.map((el: any) => <Option key={el.id} value={el['@id']}>{el.name}</Option>)
                            }
                        </Select>
                    </Form.Item>
                }

                {
                    (initialValues?.incident && initialValues?.incident?.finished === true) && 
                    <Row gutter={[16, 16]} wrap>
                        <Col span={24} style={{textAlign:'center', marginBottom:10}}>
                            <Title level={5} style={{ marginTop: 10 }}>{t('area_incidents.fields.incident')}</Title>
                            <Text>{initialValues?.incident.name}</Text>
                        </Col>
                    </Row>
                }



                <Form.Item
                    label={t("areas.fields.name")}
                    name={['area', 'name']}
                    rules={[
                        {
                            required: true,
                        },
                    ]}
                >
                    <Input />
                </Form.Item>

                <Form.Item
                    label={t("areas.fields.type")}
                    name={["area", "type"]}
                >
                    <Radio.Group onChange={(e: RadioChangeEvent) => {
                        let values = formProps.form.getFieldsValue();
                        values.country = null;
                        values.state = null;
                        values.city = null;
                        formProps.form.setFieldsValue(values);

                        setType(e.target.value);
                    }}>
                        <Radio value={'offshore'}>{t("words.offshore")}</Radio>
                        <Radio value={'nearshore'}>{t("words.nearshore")}</Radio>
                        <Radio value={'terrestrial'}>{t("words.terrestrial")}</Radio>
                    </Radio.Group>
                </Form.Item>

                {
                    type === 'offshore' &&

                    <Form.Item
                        label={t("areas.fields.country")}
                        name={['area', 'country']}
                        rules={[
                            {
                                required: true,
                            },
                        ]}
                    >
                        <Select
                            showSearch
                            filterOption={(input, option) =>
                                (option!.children as unknown as string).toLowerCase().includes(input.toLowerCase())
                            }
                        >
                            {
                                countries?.data &&
                                countries.data.data.map((el: any) => <Option key={el.id} value={el['@id']}>{el.name}</Option>)
                            }
                        </Select>
                    </Form.Item>

                }

                {
                    type === 'offshore' &&

                    <Row gutter={[8, 16]} wrap>
                        <Col xs={12} lg={12}>
                            <Form.Item
                                label={t("areas.fields.startLatitude")}
                                name={["area", "initialCoordinate", "latitude"]}
                                rules={[
                                    {
                                        required: true,
                                    },
                                ]}
                            >
                                <Input type="number" />
                            </Form.Item>
                        </Col>
                        <Col xs={12} lg={12}>
                            <Form.Item
                                label={t("areas.fields.startLongitude")}
                                name={["area", "initialCoordinate", "longitude"]}
                                rules={[
                                    {
                                        required: true,
                                    },
                                ]}
                            >
                                <Input type="number" />
                            </Form.Item>
                        </Col>
                    </Row>

                }

                {
                    type === 'offshore' &&

                    <Row gutter={[8, 16]} wrap>
                        <Col xs={12} lg={12}>
                            <Form.Item
                                label={t("areas.fields.endLatitude")}
                                name={["area", "finalCoordinate", "latitude"]}
                                rules={[
                                    {
                                        required: true,
                                    },
                                ]}
                            >
                                <Input type="number" />
                            </Form.Item>
                        </Col>
                        <Col xs={12} lg={12}>
                            <Form.Item
                                label={t("areas.fields.endLongitude")}
                                name={["area", "finalCoordinate", "longitude"]}
                                rules={[
                                    {
                                        required: true,
                                    },
                                ]}
                            >
                                <Input type="number" />
                            </Form.Item>
                        </Col>
                    </Row>

                }

                {
                    (type && type !== 'offshore') &&

                    <Form.Item
                        label={t("areas.fields.state")}
                        name={['area', 'state']}
                    >
                        <Select onSelect={getCities}
                            showSearch
                            allowClear
                            filterOption={(input, option) =>
                                (option!.children as unknown as string).toLowerCase().includes(input.toLowerCase())
                            }
                        >
                            {
                                states?.data &&
                                states.data.data.map((el: any) => <Option key={el.id} value={el['@id']}>{el.name}</Option>)
                            }
                        </Select>
                    </Form.Item>
                }

                {
                    (type && type !== 'offshore') &&

                    <Form.Item
                        label={t("areas.fields.city")}
                        name={['area', 'city']}
                    >
                        <Select
                            showSearch
                            allowClear
                            filterOption={(input, option) =>
                                (option!.children as unknown as string).toLowerCase().includes(input.toLowerCase())
                            }
                        >
                            {
                                cities?.data &&
                                cities.data.data.map((el: any) => <Option key={el.id} value={el['@id']}>{el.name}</Option>)
                            }
                        </Select>
                    </Form.Item>
                }

                {
                    (type && type !== 'offshore') &&
                    <>
                        <FilesForm form={formProps.form} name={['area', 'shpFile']} accept=".shp" maxFiles={1} label="areas.fields.shpFile" />
                        <FilesForm form={formProps.form} name={['area', 'shxFile']} accept=".shx" maxFiles={1} label="areas.fields.shxFile" />
                        <FilesForm form={formProps.form} name={['area', 'dbfFile']} accept=".dbf" maxFiles={1} label="areas.fields.dbfFile" />
                    </>
                }
            </Col>
        </Row>
    </Form>


}

export const AreaIncidentForm: React.FC<IResourceComponentsProps> = (props: any) => {

    const { formProps, form, saveButtonProps, queryResult, id } = useForm<IAreaIncident>({
        onMutationError: (httpError: HttpError) => {
            form?.setFields(useFromErrorsProviver(httpError));
        }
    });

    saveButtonProps.size = 'large';

    if (id) {
        return <Edit
            isLoading={queryResult?.isFetching}
            saveButtonProps={saveButtonProps}
            recordItemId={id}
        >
            <FormComponent formProps={formProps} />
        </Edit>
    }

    return (
        <Create
            isLoading={queryResult?.isFetching}
            saveButtonProps={saveButtonProps}
        >
            <FormComponent formProps={formProps} />
        </Create>
    );
};