import { HttpError, IResourceComponentsProps, useList, useTranslate } from "@pankod/refine-core";

import {
    Create,
    Form,
    useForm,
    Row,
    Col,
    Edit,
    Select,
    Input,
} from "@pankod/refine-antd";

import { IProject, IProjectCollaborator, IUser, IRoles } from "interfaces";
import { useFromErrorsProviver } from '../../helpers/formErrorsProvider'
import { ROLES } from '../../helpers/constants';
import { getCurrentProject } from "helpers/functions";
import { useEffect, useState } from "react";
const { Option } = Select;
import { accessControlProvider } from "accessProvider";

const FormComponent = (props: any) => {
    const t = useTranslate();
    const add = props.add === 'true';
    const { formProps } = props;
    const { initialValues } = formProps;
    const projectListQueryResult = useList<IProject>({ resource: "projects" });
    const userListQueryResult = add ? useList<IProject>({ resource: "users" }) : null;
    const [project, setProject] = useState<any>(null);
    const [userList, setUserList] = useState<IUser[]>([]);
    const [isAdmin, setIsAdmin] = useState(true);

    useEffect(() => {
        const pj = getCurrentProject();
        checkIsAdmin();

        if (projectListQueryResult?.data?.data && project === null) {
            projectListQueryResult.data.data.map((el: any) => {
                if (el.id === pj) {
                    setProject(el);
                }
            })
        }
    }, [projectListQueryResult])

    useEffect(() => {
        if (project && userListQueryResult?.data?.data && userList.length === 0) {
            const list:any = userListQueryResult?.data?.data;
            let newList = [];

            for(let i in list) {
                const projects:any = list[i]?.projects || [];
                let add = true;

                for (let j in projects) {
                    if (projects[j].project.id === project.id) {
                        add = false;
                    }
                }

                if (add) {
                    newList.push(list[i]);
                }
            }

            console.log(newList);
            setUserList(newList);
        }
    }, [project, userListQueryResult])

    const checkIsAdmin = async () => {
        const res = await accessControlProvider.can({ resource: 'is_admin', action: '' });
        setIsAdmin(res.can);
    }

    return <Form
        {...formProps}
        layout="vertical"
        initialValues={
            {
                ...initialValues,
                ...{
                    project: initialValues?.project ? initialValues?.project['@id'] : project?.['@id'],
                }
            }
        }
    >
        <Row gutter={[64, 0]} wrap>
            <Col xs={24} lg={{ span: 8, offset: 8 }}>
                {
                    (initialValues && !add) &&
                    <Form.Item
                        name={["user", "@id"]}
                        rules={[{ required: true }]}
                    >
                        <Input type="hidden"></Input>
                    </Form.Item>
                }

                {
                    (project && !isAdmin) &&
                    <Form.Item
                        name="project"
                        rules={[
                            {
                                required: true,
                            },
                        ]}

                        initialValue={project?.['@id']}
                    >
                        <Input type="hidden" />
                    </Form.Item>
                }

                {
                    (!project || isAdmin) &&
                    <Form.Item
                        label={t("project_collaborators.fields.project")}
                        name="project"
                        rules={[
                            {
                                required: true,
                            },
                        ]}
                    >
                        <Select showSearch
                            filterOption={(input, option) =>
                                (option!.children as unknown as string).toLowerCase().includes(input.toLowerCase())
                            }
                            onSelect={(value:any) => {
                                if (projectListQueryResult?.data) {
                                    projectListQueryResult.data.data.map((el: any) => {
                                        if (el['@id'] === value) {
                                            setProject(el);
                                            setUserList([]);

                                            let values = formProps.form.getFieldsValue();
                                            values.user["@id"] = null;
                                            values.user.name = null;
                                            values.user.email = null;
                                            formProps.form.setFieldsValue(values);
                                        }
                                    })    
                                }
                            }}
                        >
                            {
                                projectListQueryResult?.data &&
                                projectListQueryResult.data.data.map((el: any) => <Option key={el.id} value={el['@id']}>{el.name}</Option>)
                            }
                        </Select>
                    </Form.Item>
                }

                {
                    add &&
                    <>
                    <Form.Item
                        label={t("project_collaborators.fields.user")}
                        name={["user", "@id"]}
                        rules={[
                            {
                                required: true,
                            },
                        ]}
                    >
                        <Select showSearch
                            filterOption={(input, option) =>
                                (option!.children as unknown as string).toLowerCase().includes(input.toLowerCase())
                            }                            
                            onSelect={(value:any) => {                                
                                userList.map((el: any) => {
                                    if (el['@id'] === value) {
                                        let values = formProps.form.getFieldsValue();
                                        values.user.name = el.name;
                                        values.user.email = el.email;
                                        formProps.form.setFieldsValue(values);
                                    }
                                })
                            }}
                        >
                            {
                                userList &&
                                userList.map((el: any) => <Option key={el.id} value={el['@id']}>{el.name}</Option>)
                            }
                        </Select>
                    </Form.Item>

                    <Form.Item
                        name={["user", "name"]}
                        rules={[{ required: true }]}
                        noStyle={true}
                    >
                        <Input type="hidden"></Input>
                    </Form.Item>

                    <Form.Item
                        name={["user", "email"]}
                        rules={[{ required: true }]}
                        noStyle={true}
                    >
                        <Input type="hidden"></Input>
                    </Form.Item>

                    </>
                }


                {!add && <>
                    <Form.Item
                        label={t("project_collaborators.fields.name")}
                        name={["user", "name"]}
                        rules={[
                            {
                                required: true,
                            },
                        ]}
                    >
                        <Input />
                    </Form.Item>

                    <Form.Item
                        label={t("project_collaborators.fields.email")}
                        name={["user", "email"]}
                        rules={[
                            {
                                required: true,
                            },
                        ]}
                    >
                        <Input type="email" />
                    </Form.Item>

                    {
                        !initialValues?.id &&
                        <Form.Item
                            label={t("project_collaborators.fields.plainPassword")}
                            name={["user", "plainPassword"]}
                            rules={[
                                {
                                    required: initialValues?.id ? false : true,
                                    min: 8,
                                },
                            ]}
                        >
                            <Input type="password" />
                        </Form.Item>
                    }
                </>}

                <Form.Item
                    label={t("project_collaborators.fields.type")}
                    name={["type"]}
                    rules={[
                        {
                            required: true,
                        },
                    ]}
                >
                    <Select options={ROLES.ROLE_PROJECT_MANAGER} />
                </Form.Item>

            </Col>
        </Row>
    </Form>


}

export const ProjectCollaboratorForm: React.FC<IResourceComponentsProps> = (props: any) => {

    const { formProps, form, saveButtonProps, queryResult, id } = useForm<IProjectCollaborator>({
        onMutationError: (httpError: HttpError) => {
            form?.setFields(useFromErrorsProviver(httpError));
        }
    });

    const queryString = window.location.search;
    const urlParams = new URLSearchParams(queryString);

    saveButtonProps.size = 'large';

    if (id) {
        return <Edit
            isLoading={queryResult?.isFetching}
            saveButtonProps={saveButtonProps}
            recordItemId={id}
        >
            <FormComponent formProps={formProps} />
        </Edit>
    }

    return (
        <Create
            isLoading={queryResult?.isFetching}
            saveButtonProps={saveButtonProps}
        >
            <FormComponent add={urlParams.get('add')} formProps={formProps} />
        </Create>
    );
};