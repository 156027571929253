import Popup from "./Popup";
import moment from 'moment';
import bird from "../../../styles/40261.svg";

const resource = {
    id: 'sighting',
    popup: Popup,
    label: 'Avistamentos',
    hasFilter: true,
    filterDefaultValues: [
        {
            field: "startDate",
            operator: "gte",
            value: moment().subtract(1, "month").format('YYYY-MM-DD')
        },
        {
            field: "startDate",
            operator: "lte",
            value: moment().format('YYYY-MM-DD')
        }
    ],
    icon: bird,
    layerIcon: bird,
    visible: false,
    loadOnInit: false
};

export default resource;