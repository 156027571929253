import { useList, useTranslate } from "@pankod/refine-core";

import {
    Form,
    FormProps,
    Input,
    DatePicker,
    Row,
    Col,
    Button,
    Select,
} from "@pankod/refine-antd";
import { IAreaIncident } from "interfaces";
import { MONITORING_STRATEGIES, MONITORING_TYPES } from "helpers/constants";
import moment from 'moment';

const { Option } = Select;

export interface FilterFormProps extends FormProps {
    applyFilterCallback?: (values?: any) => void;
}

export const Filter: React.FC<{ formProps: FilterFormProps }> = (props) => {
    const t = useTranslate();
    const { RangePicker } = DatePicker;
    const areas = useList<IAreaIncident>({ resource: "area_incidents" });

    const onSubmit = (e: any) => {
        props.formProps.applyFilterCallback ? props.formProps.applyFilterCallback(props.formProps.form?.getFieldsValue()) : null;
    }

    return (

        <Form onSubmitCapture={onSubmit} layout="vertical" {...props.formProps} style={{ padding: 15 }}>
            <Row gutter={[10, 0]} align="bottom">
                <Col xs={24} xl={24} md={12}>
                    <Form.Item label={t("monitorings.filter.id")} name="id">
                        <Input/>
                    </Form.Item>
                </Col>

                <Col xs={24} xl={24} md={12}>
                    <Form.Item
                        label={t("monitorings.filter.date")}
                        name="startDate"                        
                    >
                        <RangePicker format={'DD/MM/YYYY'}                         
                          placeholder={[t('monitorings.fields.startDate'), t('monitorings.fields.endDate')]} style={{ width: "100%" }} />
                    </Form.Item>
                </Col>

                <Col xs={24} xl={24} md={12}>
                    <Form.Item
                        label={t("monitorings.fields.area")}
                        name="areaIncident"
                    >
                        <Select
                            allowClear
                            showSearch
                            filterOption={(input, option) =>
                                (option!.children as unknown as string).toLowerCase().includes(input.toLowerCase())
                            }
                        >
                            {
                                areas?.data &&
                                areas?.data?.data.map((el: any) => <Option key={el.id} value={el['@id']}>{el.description}</Option>)
                            }
                        </Select>
                    </Form.Item>
                </Col>

                <Col xs={24} xl={24} md={12}>
                    <Row gutter={[8, 16]} wrap>
                        <Col xs={24} lg={12}>
                            <Form.Item
                                label={t("monitorings.fields.type")}
                                name="type"
                            >
                                <Select showSearch allowClear
                                    filterOption={(input, option) =>
                                        (option!.children as unknown as string).toLowerCase().includes(input.toLowerCase())
                                    }
                                >
                                    {
                                        MONITORING_TYPES.map(el => <Option key={el.value} value={el.value}>{t(el.label)}</Option>)
                                    }
                                </Select>
                            </Form.Item>
                        </Col>

                        <Col xs={24} lg={12}>
                            <Form.Item
                                label={t("monitorings.fields.strategy")}
                                name="strategy"
                            >
                                <Select showSearch allowClear
                                    filterOption={(input, option) =>
                                        (option!.children as unknown as string).toLowerCase().includes(input.toLowerCase())
                                    }
                                >
                                    {
                                        MONITORING_STRATEGIES.map(el => <Option key={el.value} value={el.value}>{t(el.label)}</Option>)
                                    }
                                </Select>
                            </Form.Item>
                        </Col>
                    </Row>
                </Col>

                <Col xs={24} xl={24} md={8}>
                    <Form.Item>
                        <Button
                            style={{ width: "100%" }}
                            htmlType="submit"
                            type="primary"
                        >
                            {t("users.filter.submit")}
                        </Button>
                    </Form.Item>
                </Col>
            </Row>
        </Form>
    );
};