import { useState } from "react";

import {
    useTranslate,
    IResourceComponentsProps,
    useRouterContext,
    useResourceWithRoute,
    HistoryType,
    HttpError,
    CrudFilters
} from "@pankod/refine-core";

import {
    List,
    Table,
    useTable,
    Dropdown,
    Icons,
    TagField,
    Drawer,
    Button,
    Grid,
} from "@pankod/refine-antd";

import { IProjectCollaborator, ICollaboratorFilterVariables } from "interfaces";
import { ROLES } from "helpers/constants";
import { getFooterInfo } from "helpers/functions";
import { PlusSquareOutlined, SearchOutlined } from '@ant-design/icons';
import { MoreMenu } from "components/list/MoreMenu";
import { Filter } from "./filter";

export const ProjectCollaboratorList: React.FC<IResourceComponentsProps> = () => {

    const { tableProps, searchFormProps } = useTable<
        IProjectCollaborator,
        HttpError,
        ICollaboratorFilterVariables
    >({
        initialSorter: [
            {
                field: "id",
                order: "desc",
            },
        ],
        onSearch: (params) => {

            onClose();
            const filters: CrudFilters = [];
            const { user } = params;

            filters.push({
                field: "user.name",
                operator: "contains",
                value: user.name
            });

            filters.push({
                field: "user.email",
                operator: "contains",
                value: user.email
            });

            return filters;
        },
        syncWithLocation: false,
    });

    const breakpoint = Grid.useBreakpoint();
    const { useHistory } = useRouterContext();
    const history = useHistory();
    const resourceWithRoute = useResourceWithRoute();
    const [open, setOpen] = useState(false);

    const showDrawer = () => {
        setOpen(true);
    };

    const onClose = () => {
        setOpen(false);
    };

    const t = useTranslate();
    const roles: any = ROLES;

    const handleUrl = (url: string, type: HistoryType = "push") => {
        type === "push" ? history.push(url) : history.replace(url);
    };

    const createUrl = (resource: string) => {
        const resourceName = resourceWithRoute(resource);
        return `/${resourceName.route}/create?add=true`;
    };

    const create = (resource: string, type: HistoryType = "push") => {
        handleUrl(createUrl(resource), type);
    };

    return (
        <>
            <List headerButtons={({ defaultButtons }) => (
                <>
                    {defaultButtons}
                    <Button icon={<PlusSquareOutlined />} onClick={() => { create("project_collaborators") }} >{t('phrases.linkCollaborator')}</Button>
                    <Button icon={<SearchOutlined />} onClick={showDrawer}>{t('words.filter')}</Button>
                </>
            )}>
                <Table {...tableProps} rowKey="id" footer={() => getFooterInfo(tableProps.pagination)}>
                    <Table.Column
                        dataIndex="id"
                        align="center"
                        title={t("project_collaborators.fields.id")}
                    />

                    <Table.Column
                        dataIndex={['user', 'name']}
                        title={t("project_collaborators.fields.name")}
                    />

                    <Table.Column
                        dataIndex={['project', 'name']}
                        title={t("project_collaborators.fields.project")}
                    />

                    <Table.Column
                        dataIndex="type"
                        align="center"
                        title={t("project_collaborators.fields.type")}
                        render={(value: string) => <TagField value={roles.ROLE_PROJECT_MANAGER.find((e: any) => e.value === value)?.label} />}
                    />

                    <Table.Column
                        dataIndex="enabled"
                        title={t("project_collaborators.fields.enabled")}
                        render={((value: boolean) => value === true ? t('words.yes') : t('words.no'))}
                    />

                    <Table.Column<IProjectCollaborator>
                        fixed="right"
                        title={t("table.actions")}
                        dataIndex="actions"
                        key="actions"
                        align="center"
                        render={(_, record) => (
                            <Dropdown
                                overlay={<MoreMenu record={record} id={record.id} resource="project_collaborators" canEdit={true} canShow={true} canChangeStatus={true} statusAttribute="enabled" />}
                                trigger={["click"]}
                            >
                                <Icons.MoreOutlined
                                    style={{
                                        fontSize: 24,
                                    }}
                                />
                            </Dropdown>
                        )}
                    />
                </Table>
            </List>


            <Drawer title={t('words.filters')}
                placement="right"
                bodyStyle={{ padding: 0 }}
                width={breakpoint.sm ? "500px" : "100%"}
                zIndex={1001}
                onClose={onClose}
                visible={open}>
                <Filter
                    formProps={searchFormProps}
                />
            </Drawer>
        </>
    );
};
