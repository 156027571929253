import { usePermissions } from "@pankod/refine-core";
import { ROLES } from "./constants";

const checkPermission = () => {
    
    const hasPermission = (role, project = null) => {
        const { data: permissionsData } = usePermissions();
        if (!permissionsData) {
            return false;
        }

        if (!project && permissionsData.roles.indexOf(role) !== -1) {
            return true;
        }

        if (project) {
            for (let i in permissionsData.projects) {
                if (project === permissionsData.projects[project].id && permissionsData.projects[project].role === role) {
                    return true;
                }
            }
        }

        return false;
    }

    const getRolesList = (project = null) => {
        const { data: permissionsData } = usePermissions();
        let list = [];

        if (!permissionsData) {
            return list;
        }

        if (!project) {
            for (let i in permissionsData.roles) {
                if (permissionsData.roles[i] !== "ROLE_USER") {
                    try {
                        list = ROLES[permissionsData.roles[i]];
                    } catch (error) {}
                }
            }
        }

        if (project) {
            for (let i in permissionsData.projects) {
                if (project === permissionsData.projects[project].id) {
                    for(let i in permissionsData.projects.roles) {
                        if (permissionsData.projects.roles[i] !== "ROLE_USER") {
                            list = ROLES[permissionsData.projects.roles[i]];
                        }
                    }
                }
            }
        }

        return list;
    }

    return {
        hasPermission,
        getRolesList
    }
}

export default checkPermission;