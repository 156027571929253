import React, { createElement, useEffect, useState } from 'react';
import { Draggable } from 'react-beautiful-dnd';
import { makeStyles } from '@material-ui/styles';
import PropTypes from 'prop-types';
import { Avatar, Button, Card, Checkbox, Col, Icons, Image, Row } from '@pankod/refine-antd';
import { SearchOutlined } from '@ant-design/icons';

const useStyles = makeStyles({
    draggingListItem: {
        background: 'rgb(235,235,235)'
    }
});


const LayerItem = ({
    id,
    label,
    handleLayerCallback,
    filterButtonCallback,
    index,
    icon,
    visible,
    handleFilterCallBack,
}) => {
    const [open, setOpen] = useState(false);

    const handleCheckbox = (event) => {
        const checked = event.target.checked;

        if (checked === undefined)
            return;

        handleLayerCallback(id, checked);
    }

    const handleFilter = () => {
        handleFilterCallBack();
    }

    const handleCloseFilter = () => {
        setOpen(false);
    }

    const handleApplyFilterButton = (values) => {
        filterButtonCallback(id, values);
        handleCloseFilter();
    }

    return (
        <>
            <Draggable draggableId={id} index={index}>
                {(provided, snapshot) => (
                    <Card 
                    {...provided.draggableProps}
                    {...provided.dragHandleProps}
                    ref={provided.innerRef} 
                    bodyStyle={{ padding: 10 }}
                    >

                        <Row gutter={[16, 16]} align="middle">
                            <Col span={4}>
                                <Avatar src={icon}></Avatar>
                            </Col>
                            <Col span={16}>
                                <Checkbox
                                    checked={visible}
                                    onChange={handleCheckbox}
                                >
                                    {label}
                                </Checkbox>
                            </Col>
                            <Col span={4}>
                                <Button shape="circle" icon={<SearchOutlined />} onClick={() => { handleFilterCallBack(id) }} disabled={!visible} />
                            </Col>
                        </Row>
                    </Card>

                )}
            </Draggable>
        </>
    );

    return <></>
};


LayerItem.propTypes = {
    label: PropTypes.string,
    defaultChecked: PropTypes.bool,
    hasFilter: PropTypes.bool,
    filterButtonCallback: PropTypes.func,
    handleLayerCallback: PropTypes.func,
    filterDefaultValues: PropTypes.array,
    icon: PropTypes.any,
    handleFilterCallBack: PropTypes.func
}

LayerItem.defaultProps = {
    label: 'Label',
    defaultChecked: false,
    hasFilter: true,
    filterButtonCallback: () => { },
    handleLayerCallback: () => { },
    filterDefaultValues: [],
    icon: null,
    handleFilterCallBack: () => { }
}

export default LayerItem;


/*


//<List.Item
                    // ref={provided.innerRef}
                    // {...provided.draggableProps}
                    // {...provided.dragHandleProps}
                    // className={snapshot.isDragging ? classes.draggingListItem : ''}
                    //>

*/