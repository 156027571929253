import { useShow, useOne, useTranslate } from "@pankod/refine-core";
import { Show, Typography, Tag, DateField } from "@pankod/refine-antd";
import { IProject } from "interfaces";

const { Title, Text } = Typography;

export const ProjectShow: React.FC = () => {
    const { queryResult } = useShow<IProject>();
    const { data, isLoading } = queryResult;
    const record = data?.data;
    const t = useTranslate();

    return (
        <Show isLoading={isLoading}>
            <Title level={5}>{t('projects.fields.id')}</Title>
            <Text>{record?.id}</Text>

            <Title level={5}>{t('projects.fields.name')}</Title>
            <Text>{record?.name}</Text>

            <Title level={5}>{t('projects.fields.initials')}</Title>
            <Text>{record?.initials}</Text>

            <Title level={5}>{t('projects.fields.description')}</Title>
            <Text>{record?.description}</Text>

            <Title level={5}>{t('projects.fields.startDate')}</Title>
            <DateField format="DD/MM/YYYY" value={record?.startDate ?? "-"} />

            <Title level={5}>{t('projects.fields.endDate')}</Title>
            <DateField format="DD/MM/YYYY" value={record?.endDate ?? "-"} />
        </Show>
    );
};