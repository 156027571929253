import { useState, useEffect } from "react";
import {
    useGetLocale,
    useSetLocale,
    useGetIdentity,
    useTranslate,
    useList,
    useApiUrl,
} from "@pankod/refine-core";

import {
    AntdLayout,
    Menu,
    Icons,
    Dropdown,
    Input,
    Avatar,
    Typography,
    Space,
    Grid,
    Row,
    Col,
    AutoComplete,
    Drawer,
} from "@pankod/refine-antd";

import RefineReactRouter from "@pankod/refine-react-router-v6";

import { useTranslation } from "react-i18next";
import debounce from "lodash/debounce";

const { Link } = RefineReactRouter;
const { SearchOutlined, DownOutlined } = Icons;
const { Text } = Typography;
const { useBreakpoint } = Grid;

import { IOrder, IStore, ICourier } from "interfaces";
import "./style.less";
import { Constants } from "helpers/constants";
import { UserProfile } from "../../pages/users";

interface IOptionGroup {
    value: string;
    label: string | React.ReactNode;
}

interface IOptions {
    label: string | React.ReactNode;
    options: IOptionGroup[];
}

export const Header: React.FC = () => {
    const { i18n } = useTranslation();
    const locale = useGetLocale();
    const changeLanguage = useSetLocale();
    const { data } = useGetIdentity();
    const screens = useBreakpoint();
    const t = useTranslate();
    const [projects, setProjects] = useState<any>(null);
    const [currentProject, setCurrentProject] = useState<any>(null);
    const [open, setOpen] = useState(false);
    const [user, setUser] = useState<any>();
    const apiUrl = useApiUrl();

    const currentLocale = locale();

    const renderTitle = (title: string) => (
        <div className="header-title">
            <Text style={{ fontSize: "16px" }}>{title}</Text>
            <Link to={`/${title.toLowerCase()}`}>{t("search.more")}</Link>
        </div>
    );

    const renderItem = (title: string, imageUrl: string, link: string) => ({
        value: title,
        label: (
            <Link to={link} style={{ display: "flex", alignItems: "center" }}>
                <Avatar size={64} src={imageUrl} style={{ minWidth: "64px" }} />
                <Text style={{ marginLeft: "16px" }}>{title}</Text>
            </Link>
        ),
    });

    const [value, setValue] = useState<string>("");
    const [options, setOptions] = useState<IOptions[]>([]);

    useEffect(() => {
        if (!user || user?.id !== data?.id) {
            console.log(data)
            setUser(data);
        }
    }, [data])

    const { refetch: refetchOrders } = useList<IOrder>({
        resource: "orders",
        config: {
            filters: [{ field: "q", operator: "contains", value }],
        },
        queryOptions: {
            enabled: false,
            onSuccess: (data) => {
                const orderOptionGroup = data.data.map((item) =>
                    renderItem(
                        `${item.store.title} / #${item.orderNumber}`,
                        "/images/default-order-img.png",
                        `/orders/show/${item.id}`,
                    ),
                );
                if (orderOptionGroup.length > 0) {
                    setOptions((prevOptions) => [
                        ...prevOptions,
                        {
                            label: renderTitle(t("orders.orders")),
                            options: orderOptionGroup,
                        },
                    ]);
                }
            },
        },
    });

    const { refetch: refetchStores } = useList<IStore>({
        resource: "stores",
        config: {
            filters: [{ field: "q", operator: "contains", value }],
        },
        queryOptions: {
            enabled: false,
            onSuccess: (data) => {
                const storeOptionGroup = data.data.map((item) =>
                    renderItem(
                        item.title,
                        "/images/default-store-img.png",
                        `/stores/edit/${item.id}`,
                    ),
                );
                if (storeOptionGroup.length > 0) {
                    setOptions((prevOptions) => [
                        ...prevOptions,
                        {
                            label: renderTitle(t("stores.stores")),
                            options: storeOptionGroup,
                        },
                    ]);
                }
            },
        },
    });

    const { refetch: refetchCouriers } = useList<ICourier>({
        resource: "couriers",
        config: {
            filters: [{ field: "q", operator: "contains", value }],
        },
        queryOptions: {
            enabled: false,
            onSuccess: (data) => {
                const courierOptionGroup = data.data.map((item) =>
                    renderItem(
                        `${item.name} ${item.surname}`,
                        item.avatar[0].url,
                        `/couriers/show/${item.id}`,
                    ),
                );
                if (courierOptionGroup.length > 0) {
                    setOptions((prevOptions) => [
                        ...prevOptions,
                        {
                            label: renderTitle(t("couriers.couriers")),
                            options: courierOptionGroup,
                        },
                    ]);
                }
            },
        },
    });

    useEffect(() => {
        setOptions([]);
        updateProjects();
        // refetchOrders();
        // refetchCouriers();
        // refetchStores();
    }, [value]);


    const updateProjects = () => {
        try {
            setProjects(JSON.parse(localStorage.getItem(Constants.PROJECTS_KEY) || ''));
        } catch (error) { }

        try {
            setCurrentProject(JSON.parse(localStorage.getItem(Constants.CURRENT_PROJECT_KEY) || ''));
        } catch (error) { }
    }

    // const menu = (
    //     <Menu selectedKeys={currentLocale ? [currentLocale] : []}>
    //         {[...(i18n.languages ?? [])].sort().map((lang: string) => (
    //             <Menu.Item
    //                 key={lang}
    //                 onClick={() => changeLanguage(lang)}
    //                 icon={
    //                     <span style={{ marginRight: 8 }}>
    //                         <Avatar
    //                             size={16}
    //                             src={`/images/flags/${lang}.svg`}
    //                         />
    //                     </span>
    //                 }
    //             >
    //                 {lang === "en" ? "English" : "Português"}
    //             </Menu.Item>
    //         ))}
    //     </Menu>
    // );

    const handleMenuClick = (e: any) => {
        const id = parseInt(e.key);
        if (id !== currentProject?.id && projects?.length > 0) {
            for (let k in projects) {
                if (projects[k]?.id === id) {
                    localStorage.setItem(Constants.CURRENT_PROJECT_KEY, JSON.stringify(projects[k]));
                    setCurrentProject(projects[k]);
                    location.reload();
                }
            }
        }
    }

    const menu = (
        <Menu onClick={handleMenuClick}>
            {
                projects &&
                projects.map((el: any) =>
                    <Menu.Item key={el.id}>
                        {el.name}
                    </Menu.Item>
                )
            }
        </Menu>
    );

    const showDrawer = () => {
        setOpen(true);
    };

    const onClose = () => {
        setOpen(false);
    };

    const updateUserInfo = (user: any) => {
        console.log(user)
        setUser(user);
        onClose();
    }

    return (
        <>



            <AntdLayout.Header
                style={{
                    padding: "0px 24px",
                    height: "64px",
                    backgroundColor: "#FFF",
                }}
            >
                <Row align="middle" justify={screens.sm ? "space-between" : "end"}>
                    <Col xs={0} sm={12}>
                        {
                            (projects && projects.length > 0) &&
                            <Dropdown.Button overlay={menu}>
                                {currentProject?.name || t('phrases.select_project')}
                            </Dropdown.Button>
                        }
                    </Col>
                    <Col>
                        <Space size="middle">

                            {/*<Dropdown overlay={menu}>
                            <a
                                style={{ color: "inherit" }}
                                onClick={(e) => e.preventDefault()}
                            >
                                <Space>
                                    <Avatar
                                        size={16}
                                        src={`/images/flags/${currentLocale}.svg`}
                                    />
                                    <div
                                        style={{
                                            display: screens.lg
                                                ? "block"
                                                : "none",
                                        }}
                                    >
                                        {currentLocale === "en"
                                            ? "English"
                                            : "Português"}
                                        <DownOutlined
                                            style={{
                                                fontSize: "12px",
                                                marginLeft: "6px",
                                            }}
                                        />
                                    </div>
                                </Space>
                            </a>
                        </Dropdown>*/}
                            <Text ellipsis strong>
                                {user?.name}
                            </Text>

                            {
                                !user?.image &&

                                <Avatar
                                    size="large"
                                    icon={<Icons.UserOutlined onClick={showDrawer} />}
                                    //src={user?.avatar}
                                    alt={user?.name}
                                />
                            }

                            {
                                user?.image &&
                                <div onClick={showDrawer}>
                                <Avatar
                                    size="large"
                                    src={`${apiUrl}/api/file/restore/${user?.image.id}`}
                                    //src={user?.avatar}
                                    alt={user?.name}
                                    style={{cursor:"pointer"}}
                                />
                                </div>
                            }
                            
                        </Space>
                    </Col>
                </Row>
            </AntdLayout.Header>

            <Drawer title={t('words.profile')}
                destroyOnClose={true}
                placement="right"
                bodyStyle={{ padding: 0 }}
                width={"30%"}
                zIndex={1001}
                onClose={onClose}
                visible={open}>

                <UserProfile updateCallback={updateUserInfo} />
            </Drawer>
        </>
    );
};
