import { useShow, useTranslate } from "@pankod/refine-core";
import { Show, Typography, TagField, Row, Col } from "@pankod/refine-antd";
import { IProjectCollaborator } from "interfaces";
import { ROLES } from "helpers/constants";
const { Title, Text } = Typography;

export const ProjectCollaboratorShow: React.FC = () => {
    const { queryResult } = useShow<IProjectCollaborator>();
    const { data, isLoading } = queryResult;
    const record = data?.data;
    const t = useTranslate();
    const roles = ROLES;

    return (
        <Show isLoading={isLoading}>

            <Row gutter={[16, 16]} wrap>
                <Col span={12}>
                    <Title level={5}>{t('project_collaborators.fields.id')}</Title>
                    <Text>{record?.id}</Text>
                </Col>
                <Col span={12}>
                    <Title level={5}>{t('project_collaborators.fields.name')}</Title>
                    <Text>{record?.user?.name}</Text>
                </Col>
            
                <Col span={12}>
                    <Title level={5}>{t('project_collaborators.fields.project')}</Title>
                    <Text>{record?.project?.name}</Text>
                </Col>
                <Col span={12}>
                    <Title level={5}>{t('project_collaborators.fields.type')}</Title>
                    <TagField value={roles.ROLE_PROJECT_MANAGER.find((e: any) => e.value === record?.type)?.label} />
                </Col>
            
                <Col span={12}>
                    <Title level={5}>{t('project_collaborators.fields.enabled')}</Title>
                    <Text>{record?.enabled === true ? t('words.yes') : t('words.no')}</Text>
                </Col>
                <Col span={12}>

                </Col>
            </Row>

        </Show>
    );
};