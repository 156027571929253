import {
    useTranslate,
    IResourceComponentsProps,
    CrudFilters,
    HttpError,
    useApiUrl,
} from "@pankod/refine-core";

import {
    List,
    Table,
    useTable,
    Dropdown,
    Icons,
    DateField,
    Button,
    Drawer,
    Grid,
    Typography,
} from "@pankod/refine-antd";

import { ISightings, ISightingsFilterVariables } from "interfaces";
import { MoreMenu } from "components/list/MoreMenu";
import { Constants, OILED_CHOICES, ZOOGROUP_CHOICES } from "helpers/constants";
import { SearchOutlined, DownloadOutlined } from '@ant-design/icons';
import { Filter } from "./filter";
import { useState } from "react";
import axios from "axios";
import moment from "moment";
import { getCurrentProject, getFooterInfo } from "helpers/functions";
const { Text } = Typography;

export const SightingList: React.FC<IResourceComponentsProps> = () => {
    const t = useTranslate();
    const breakpoint = Grid.useBreakpoint();
    const [open, setOpen] = useState(false);

    const apiUrl = useApiUrl();

    const showDrawer = () => {
        setOpen(true);
    };

    const onClose = () => {
        setOpen(false);
    };

    const { tableProps, searchFormProps } = useTable<
        ISightings,
        HttpError,
        ISightingsFilterVariables
    >({
        initialSorter: [
            {
                field: "id",
                order: "desc",
            },
        ],
        onSearch: (params) => {

            onClose();
            const filters: CrudFilters = [];
            const { id, date, monitoring, oiled, condition, specie, speciesDescription, zooGroup } = params;

            filters.push({
                field: "id",
                operator: "eq",
                value: id,
            });

            filters.push({
                field: "monitoring.id",
                operator: "eq",
                value: monitoring?.id,
            });

            filters.push({
                field: "oiled",
                operator: "eq",
                value: oiled,
            });

            filters.push({
                field: "condition",
                operator: "eq",
                value: condition,
            });

            filters.push({
                field: "monitoring.areaIncident",
                operator: "eq",
                value: monitoring?.areaIncident,
            });

            filters.push({
                field: "specie.id",
                operator: "eq",
                value: specie?.id
            });

            filters.push({
                field: "speciesDescription",
                operator: "contains",
                value: speciesDescription
            });

            filters.push({
                field: "zooGroup",
                operator: "eq",
                value: zooGroup
            });
            
            filters.push(
                {
                    field: "date",
                    operator: "gte",
                    value: date
                        ? date[0].startOf("day").toISOString()
                        : undefined,
                },
                {
                    field: "date",
                    operator: "lte",
                    value: date
                        ? date[1].endOf("day").toISOString()
                        : undefined,
                },
            );

            return filters;
        },
        syncWithLocation: false,
    });

    const downloadData = async () => {

        const filter: any = searchFormProps.form?.getFieldsValue();
        const project = getCurrentProject();        
        let query = `?project=${project}`;
        let and = '';
        

        Object.entries(filter).forEach(([key, val]) => {

            if (val !== "" && val !== undefined) {
                if (key === 'date') {
                    const dates: any = val;
                    const d1 = dates[0];
                    const d2 = dates[1];
                    query += `${and}startDate=${moment(d1.toISOString()).format('YYYY-MM-DD')} 00:00:00&endDate=${moment(d2.toISOString()).format('YYYY-MM-DD')} 23:59:59`;
                } else if (key === "monitoring") {
                    const monitoring: any = val;

                    if (monitoring?.id) {
                        query += `${and}monitoring.id=${monitoring.id}`;
                    }
                    if (monitoring?.areaIncident) {
                        query += `${and}monitoring.areaIncident=${monitoring.areaIncident}`;
                    }
                } else if (key === "specie") {
                    const specie: any = val;

                    if (specie?.id) {
                        query += `${and}specie.id=${specie.id}`;
                    }
                } else {
                    query += `${and}${key}=${val}`;
                }

                and = "&";
            }
        });

        axios({
            url: `${apiUrl}/api/sighting/download${query}`,
            method: 'GET',
            responseType: 'blob', // important
            headers: {
                'Authorization': `Bearer ${localStorage.getItem(Constants.TOKEN_KEY)}`
            }
        }).then((response) => {
            const name = `Avistamentos_${moment().format('YYYY-MM-DD_hh-mm-ss')}.xlsx`
            const url = window.URL.createObjectURL(new Blob([response.data]));
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', name); //or any other extension
            document.body.appendChild(link);
            link.click();
        });
    }

    return (
        <>
            <List canCreate={false} headerButtons={({ defaultButtons }) => (
                <>
                    {defaultButtons}
                    <Button icon={<SearchOutlined />} onClick={showDrawer}>{t('words.filter')}</Button>
                    <Button icon={<DownloadOutlined />} onClick={downloadData}>{t('words.download')}</Button>
                </>
            )}>
                <Table {...tableProps} rowKey="id" footer={() => getFooterInfo(tableProps.pagination)}>
                    <Table.Column
                        dataIndex="id"
                        align="center"
                        title={t("sightings.fields.id")}
                    />

                    <Table.Column
                        dataIndex={["monitoring", "id"]}
                        align="center"
                        title={t("sightings.fields.monitoring")}
                    />

                    <Table.Column
                        dataIndex={["monitoring", "areaIncident", "incident", "name"]}
                        align="center"
                        title={t("sightings.fields.incident")}
                    />

                    <Table.Column
                        dataIndex={["monitoring", "areaIncident", "areaName"]}
                        align="center"
                        title={t("sightings.fields.area")}
                    />

                    <Table.Column
                        dataIndex="date"
                        align="center"
                        title={t("sightings.fields.date")}
                        render={(value) => <DateField value={value} format="DD/MM/YYYY HH:mm" />}
                    />

                    <Table.Column
                        dataIndex="quantity"
                        align="center"
                        title={t("sightings.fields.quantity")}
                    />

                    <Table.Column
                        dataIndex="condition"
                        align="center"
                        title={t("sightings.fields.condition")}
                        render={(value) => value === true ? t('words.alive') : t('words.dead')}
                    />

                    <Table.Column
                        dataIndex="oiled"
                        align="center"
                        title={t("sightings.fields.oiled")}
                        render={(value: string) => t(OILED_CHOICES.find((e: any) => e.value === value)?.label || '')}
                    />

                    <Table.Column
                        align="center"
                        title={t("sightings.fields.specie")}
                        render={(value: any) => <Text style={{fontStyle:(value?.identifiedSpecies ? 'italic' : 'normal')}} >{value?.identifiedSpecies ? value.specie.fullName : value.speciesIdentification}</Text>}
                    />

                    <Table.Column
                        dataIndex="zooGroup"
                        align="center"
                        title={t("sightings.fields.zooGroup")}
                        render={(value: string) => <Text style={{fontStyle:(value !== 'other' ? 'italic' : 'normal')}}>{t(ZOOGROUP_CHOICES.find((e: any) => e.value === value)?.label || '')}</Text>}
                    />

                    <Table.Column<ISightings>
                        fixed="right"
                        title={t("table.actions")}
                        dataIndex="actions"
                        key="actions"
                        align="center"
                        render={(_, record) => (
                            <Dropdown
                                overlay={<MoreMenu canEdit={false} id={record.id} resource="sightings" />}
                                trigger={["click"]}
                            >
                                <Icons.MoreOutlined
                                    style={{
                                        fontSize: 24,
                                    }}
                                />
                            </Dropdown>
                        )}
                    />
                </Table>
            </List>

            <Drawer title={t('words.filters')}
                placement="right"
                bodyStyle={{ padding: 0 }}
                width={breakpoint.sm ? "500px" : "100%"}
                zIndex={1001}
                onClose={onClose}
                visible={open}>
                <Filter
                    formProps={searchFormProps}
                />
            </Drawer>



        </>
    );
};