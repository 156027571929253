import { useTranslate } from "@pankod/refine-core";

import {
    Form,
    FormProps,
    DatePicker,
    Row,
    Col,
    Button,
    Select,
    Input,
} from "@pankod/refine-antd";
import { MONITORING_STRATEGIES, AREA_TYPES } from "../../../helpers/constants";
import { useEffect, useState } from "react";
import { getChartFilterConfiguration } from "helpers/functions";

const { Option } = Select;

export interface MonitoringChartFilterProps extends FormProps {
    applyfiltercallback?: (values?: any) => void;
    chart: string | undefined;
}

export const MonitoringFilter: React.FC<{ formProps: MonitoringChartFilterProps }> = (props) => {
    const t = useTranslate();
    const [isDateError, setIsDateError] = useState<boolean>(false);

    useEffect(() => {
        props.formProps.form?.resetFields();
        const values: any = {};

        if (props.formProps?.chart) {
            const filters = getChartFilterConfiguration(props.formProps?.chart);

            if (filters) {
                for (let i in filters) {
                    if (filters[i].field === 'startDate' && filters[i].operator === 'gte') {
                        const d = filters[i].value.split(' ');
                        values['startDate'] = d[0];
                    } else if (filters[i].field === 'startDate' && filters[i].operator === 'lte') {
                        const d = filters[i].value.split(' ');
                        values['endDate'] = d[0];
                    } else {
                        values[filters[i].field] = filters[i].value;
                    }
                }
            }
        }

        props.formProps.form?.setFieldsValue(values);
    }, [props])

    const onSubmit = (e: any) => {
        let values = props.formProps.form?.getFieldsValue() || {};
        values["chart"] = props.formProps.chart;

        console.log(values)

        if ((values.startDate && !values.endDate) || (!values.startDate && values.endDate)) {
            setIsDateError(true);
            return;
        } else {
            setIsDateError(false);
        }

        if (values.startDate && values.endDate) {
            values.startDate = [values.startDate + ' 00:00:00', values.endDate + ' 23:59:59'];
            delete values.endDate;
        }

        props.formProps?.applyfiltercallback ? props.formProps.applyfiltercallback(values) : null;
    }


    return (

        <Form onSubmitCapture={onSubmit} layout="vertical" form={props.formProps.form} style={{ padding: 15 }}>

            <Row gutter={[10, 0]} align="bottom">
                {
                    isDateError &&
                    <div role="alert" style={{ marginLeft: 5, marginBottom: 5 }} className="ant-form-item-explain-error">{t('phrases.chartDateFilterError')}</div>
                }

                <Col xs={24} xl={24} md={12}>
                    <Row gutter={[8, 16]} wrap>
                        <Col xs={24} lg={12}>
                            <Form.Item
                                label={t("projects.fields.startDate")}
                                name="startDate"
                                normalize={val => val === "" ? null : val}
                            >
                                <Input type="date" />
                            </Form.Item>
                        </Col>

                        <Col xs={24} lg={12}>
                            <Form.Item
                                label={t("projects.fields.endDate")}
                                name="endDate"
                                normalize={val => val === "" ? null : val}
                            >
                                <Input type="date" />
                            </Form.Item>
                        </Col>
                    </Row>

                </Col>

                <Col xs={24} xl={24} md={12}>
                    <Row gutter={[8, 16]} wrap>
                        <Col xs={24} lg={12}>
                            <Form.Item
                                label={t("monitorings.fields.strategy")}
                                name="strategy"
                            >
                                <Select showSearch allowClear
                                    filterOption={(input, option) =>
                                        (option!.children as unknown as string).toLowerCase().includes(input.toLowerCase())
                                    }
                                >
                                    {
                                        MONITORING_STRATEGIES.map(el => <Option key={el.value} value={el.value}>{t(el.label)}</Option>)
                                    }
                                </Select>
                            </Form.Item>
                        </Col>

                        <Col xs={24} lg={12}>
                            <Form.Item
                                label={t("monitorings.filter.areaType")}
                                name="areaType"
                            >
                                <Select showSearch allowClear
                                    filterOption={(input, option) =>
                                        (option!.children as unknown as string).toLowerCase().includes(input.toLowerCase())
                                    }
                                >
                                    {
                                        AREA_TYPES.map(el => <Option key={el.value} value={el.value}>{t(el.label)}</Option>)
                                    }
                                </Select>
                            </Form.Item>
                        </Col>
                    </Row>
                </Col>


                <Col xs={24} xl={24} md={8}>
                    <Form.Item>
                        <Button
                            style={{ width: "100%" }}
                            htmlType="submit"
                            type="primary"
                        >
                            {t("words.filter")}
                        </Button>
                    </Form.Item>
                </Col>
            </Row>
        </Form>
    );
};