import {
    useTranslate,
    IResourceComponentsProps,
    CrudFilters,
    HttpError,
    useApiUrl,
} from "@pankod/refine-core";

import {
    List,
    Table,
    useTable,
    Dropdown,
    Icons,
    DateField,
    TagField,
    Button,
    Drawer,
    Grid,
} from "@pankod/refine-antd";

import { IMonitoring, IMonitoringFilterVariables } from "interfaces";
import { MoreMenu } from "components/list/MoreMenu";
import { MONITORING_TYPES, MONITORING_STRATEGIES, Constants, MONITORING_TYPES_COLORS, MONITORING_STRATEGIES_COLORS } from "helpers/constants";
import { SearchOutlined, DownloadOutlined } from '@ant-design/icons';
import { Filter } from "./filter";
import { useState } from "react";
import axios from "axios";
import moment from "moment";
import { downloadHelper } from "helpers/downloadHelper";
import { getCurrentProject, getFooterInfo } from "helpers/functions";

export const MonitoringList: React.FC<IResourceComponentsProps> = () => {
    const t = useTranslate();
    const breakpoint = Grid.useBreakpoint();
    const [open, setOpen] = useState(false);

    const apiUrl = useApiUrl();

    const showDrawer = () => {
        setOpen(true);
    };

    const onClose = () => {
        setOpen(false);
    };

    const { tableProps, searchFormProps } = useTable<
        IMonitoring,
        HttpError,
        IMonitoringFilterVariables
    >({
        initialSorter: [
            {
                field: "id",
                order: "desc",
            },
        ],
        onSearch: (params) => {
            onClose();
            const filters: CrudFilters = [];
            const { id, startDate, areaIncident, type, strategy } = params;

            filters.push({
                field: "id",
                operator: "eq",
                value: id,
            });

            filters.push({
                field: "type",
                operator: "eq",
                value: type,
            });

            filters.push({
                field: "strategy",
                operator: "eq",
                value: strategy,
            });

            filters.push({
                field: "areaIncident",
                operator: "eq",
                value: areaIncident,
            });

            filters.push(
                {
                    field: "startDate",
                    operator: "gte",
                    value: startDate
                        ? startDate[0].startOf("day").toISOString()
                        : undefined,
                },
                {
                    field: "startDate",
                    operator: "lte",
                    value: startDate
                        ? startDate[1].endOf("day").toISOString()
                        : undefined,
                },
            );

            return filters;
        },
        syncWithLocation: false,
    });

    const downloadData = async () => {
        const query = getQueryFilter();
        downloadHelper(`${apiUrl}/api/monitoring/download${query}`, `Monitoramentos_${moment().format('YYYY-MM-DD_hh-mm-ss')}.xlsx`);
    }

    const downloadGPX = async () => {
        const query = getQueryFilter();
        downloadHelper(`${apiUrl}/api/monitoring/downloadgpx${query}`, `GPX_Monitoramentos_${moment().format('YYYY-MM-DD_hh-mm-ss')}.zip`);
    }

    const getQueryFilter = () => {
        const filter: any = searchFormProps.form?.getFieldsValue();        
        const project = getCurrentProject();                
        let query = `?project=${project}`;
        let and = '&';

        Object.entries(filter).forEach(([key, val]) => {

            if (val !== "" && val !== undefined) {
                if (key === 'startDate') {
                    const dates: any = val;
                    const d1 = dates[0];
                    const d2 = dates[1];
                    query += `${and}startDate=${moment(d1.toISOString()).format('YYYY-MM-DD')} 00:00:00&endDate=${moment(d2.toISOString()).format('YYYY-MM-DD')} 23:59:59`;
                } else {
                    query += `${and}${key}=${val}`;
                }
            }
        });

        return query;
    }

    return (
        <>
            <List headerButtons={({ defaultButtons }) => (
                <>
                    {defaultButtons}
                    <Button icon={<SearchOutlined />} onClick={showDrawer}>{t('words.filter')}</Button>
                    <Button icon={<DownloadOutlined />} onClick={downloadData}>{t('words.download')}</Button>
                    <Button icon={<DownloadOutlined />} onClick={downloadGPX}>{t('phrases.downloadGpx')}</Button>
                </>
            )}>
                <Table {...tableProps} rowKey="id" footer={() => getFooterInfo(tableProps.pagination)}>
                    <Table.Column
                        dataIndex="id"
                        align="center"
                        title={t("monitorings.fields.id")}
                    />

                    <Table.Column
                        dataIndex={["areaIncident", "incident", "name"]}
                        align="center"
                        title={t("monitorings.fields.incident")}
                    />

                    <Table.Column
                        dataIndex={["areaIncident", "areaName"]}
                        align="center"
                        title={t("monitorings.fields.area")}
                    />

                    <Table.Column
                        dataIndex="startDate"
                        align="center"
                        title={t("monitorings.fields.startDate")}
                        render={(value) => <DateField value={value} format="DD/MM/YYYY HH:mm" />}
                    />

                    <Table.Column
                        dataIndex="endDate"
                        align="center"
                        title={t("monitorings.fields.endDate")}
                        render={(value) => <DateField value={value} format="DD/MM/YYYY HH:mm" />}
                    />

                    <Table.Column
                        dataIndex={["responsible", "name"]}
                        align="center"
                        title={t("monitorings.fields.responsible")}
                    />

                    <Table.Column
                        dataIndex="type"
                        align="center"
                        title={t("monitorings.fields.type")}
                        render={(value: any) => <TagField
                            color={(MONITORING_TYPES_COLORS.find((e: any) => e.value === value)?.label || 'default')}
                            value={t(MONITORING_TYPES.find((e: any) => e.value === value)?.label || '')} />}
                    />

                    <Table.Column
                        dataIndex="strategy"
                        align="center"
                        title={t("monitorings.fields.strategy")}
                        render={(value: string) => <TagField 
                            color={(MONITORING_STRATEGIES_COLORS.find((e: any) => e.value === value)?.label || 'default')}
                            value={t(MONITORING_STRATEGIES.find((e: any) => e.value === value)?.label || '')} />}
                    />

                    <Table.Column
                        dataIndex="validated"
                        align="center"
                        title={t("monitorings.fields.validated")}
                        render={(value: boolean) => <TagField color={value === true ? 'green' : 'red'} value={value === true ? t('words.yes') : t('words.no')} />}
                    />

                    <Table.Column<IMonitoring>
                        fixed="right"
                        title={t("table.actions")}
                        dataIndex="actions"
                        key="actions"
                        align="center"
                        render={(_, record) => (
                            <Dropdown
                                overlay={<MoreMenu record={record} id={record.id} resource="monitorings" canDelete={true} canValidate={true} />}
                                trigger={["click"]}
                            >
                                <Icons.MoreOutlined
                                    style={{
                                        fontSize: 24,
                                    }}
                                />
                            </Dropdown>
                        )}
                    />
                </Table>
            </List>

            <Drawer title={t('words.filters')}
                placement="right"
                bodyStyle={{ padding: 0 }}
                width={breakpoint.sm ? "500px" : "100%"}
                zIndex={1001}
                onClose={onClose}
                visible={open}>
                <Filter
                    formProps={searchFormProps}
                />
            </Drawer>



        </>
    );
};