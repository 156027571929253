import PropTypes from 'prop-types';
import { Divider, Typography, Row, Col } from "@pankod/refine-antd";
import { useTranslate } from '@pankod/refine-core';
import { ZOOGROUP_CHOICES } from 'helpers/constants';
const { Title, Text } = Typography;

const Popup = ({ record, showDataCallback, ...props }) => {

    const t = useTranslate();

    if (!record)
        return <></>

    return (
        <Row style={{ maxWidth: 500 }}>
            <Divider orientation="left" style={{ borderTopColor: 'rgba(0, 0, 0, 0.20)' }}>{t('phrases.sightingData')}</Divider>

            <Row gutter={[16, 16]} wrap>
                <Col span={12}>
                    <Title level={5}>{t('sightings.fields.id')}</Title>
                    <Text>{record?.id}</Text>
                </Col>

                <Col span={12}>
                    <Title level={5}>{t('monitorings.fields.responsible')}</Title>
                    <Text>{record?.responsible}</Text>
                </Col>

                <Col span={12}>
                    <Title level={5}>{t('sightings.fields.date')}</Title>
                    <Text>{`${record?.date} ${record?.hour}`}</Text>
                </Col>

                <Col span={12}>
                    <Title level={5}>{t('area_incidents.area_incidents')}</Title>
                    <Text>{`${record?.area} / ${record?.incident}`}</Text>
                </Col>

                {
                    record?.text_specie &&
                    <Col span={12}>
                        <Title level={5}>{t('sightings.fields.specie')}</Title>
                        <Text style={{fontStyle:'italic'}}>{record?.text_specie}</Text>
                    </Col>
                }

                {
                    (!record?.text_specie && record?.zoo_group === "other") &&
                    <Col span={12}>
                        <Title level={5}>{t('sightings.fields.speciesDescription')}</Title>
                        <Text>{record?.species_description}</Text>
                    </Col>
                }

                {
                    (!record?.text_specie && record?.zoo_group !== "other") &&
                    <Col span={12}>
                        <Title level={5}>{t('sightings.fields.zooGroup')}</Title>
                        <Text style={{fontStyle:'italic'}}>{t(ZOOGROUP_CHOICES.find((e) => e.value === record?.zoo_group)?.label || '')}</Text>
                    </Col>
                }

                <Col span={12}>
                    <Title level={5}>{t('sightings.fields.oiled')}</Title>
                    <Text>{record?.oiled}</Text>
                </Col>

            </Row>
        </Row>
    )

}

Popup.propTypes = {
    record: PropTypes.object,
    showDataCallback: PropTypes.func,
}

Popup.defaultProps = {
    record: null,
    showDataCallback: () => { }
}

export default Popup;