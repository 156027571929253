import { HttpError, IResourceComponentsProps, useTranslate } from "@pankod/refine-core";

import {
    Create,
    Form,
    Input,
    useForm,
    Row,
    Col,
    Edit,
} from "@pankod/refine-antd";

import { IProject } from "interfaces";

import { useFromErrorsProviver } from '../../helpers/formErrorsProvider'

const FormComponent = (props: any) => {
    const { TextArea } = Input;
    const t = useTranslate();
    const { formProps } = props;


    return <Form
        {...formProps}
        layout="vertical"
        initialValues={{
            isActive: true,
            ...formProps.initialValues,
        }}
    >
        <Row gutter={[64, 0]} wrap>
            <Col xs={24} lg={{span:8, offset:8}}>
                <Form.Item
                    label={t("projects.fields.name")}
                    name="name"
                    rules={[
                        {
                           required: true,
                        },
                    ]}
                >
                    <Input />
                </Form.Item>
                <Form.Item
                    label={t("projects.fields.initials")}
                    name="initials"
                    rules={[
                        {
                           required: true,
                           max:10
                        },
                    ]}
                >
                    <Input />
                </Form.Item>
                <Form.Item
                    label={t("projects.fields.description")}
                    name="description"
                    rules={[
                        {
                            required: true,
                        },
                    ]}
                >
                    <TextArea />
                </Form.Item>

                <Form.Item
                    label={t("projects.fields.startDate")}
                    name="startDate"
                    normalize={val => val === "" ? null : val}
                >
                    <Input type="date" />
                </Form.Item>

                <Form.Item
                    label={t("projects.fields.endDate")}
                    name="endDate"
                    normalize={val => val === "" ? null : val}
                >
                    <Input type="date" />
                </Form.Item>

            </Col>
        </Row>
    </Form>


}

export const ProjectForm: React.FC<IResourceComponentsProps> = (props: any) => {

    const { formProps, form, saveButtonProps, queryResult, id } = useForm<IProject>({
        onMutationError: (httpError: HttpError) => {
            form?.setFields(useFromErrorsProviver(httpError));
        }
    });


    saveButtonProps.size = 'large';
    
    if (id) {
        return <Edit
            isLoading={queryResult?.isFetching}
            saveButtonProps={saveButtonProps}
            recordItemId={id}
        >
            <FormComponent formProps={formProps} />
        </Edit>
    }

    return (
        <Create
            isLoading={queryResult?.isFetching}
            saveButtonProps={saveButtonProps}
        >
            <FormComponent formProps={formProps} />
        </Create>
    );
};
