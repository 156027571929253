import { useShow, useTranslate } from "@pankod/refine-core";
import { Show, Typography, TagField } from "@pankod/refine-antd";
import { IArea } from "interfaces";
const { Title, Text } = Typography;

export const AreaShow: React.FC = () => {
    const { queryResult } = useShow<IArea>();
    const { data, isLoading } = queryResult;
    const record = data?.data;
    const t = useTranslate();

    return (
        <Show isLoading={isLoading}>
            <Title level={5}>{t('areas.fields.id')}</Title>
            <Text>{record?.id}</Text>

            <Title level={5}>{t('areas.fields.name')}</Title>
            <Text>{record?.name}</Text>

            <Title level={5}>{t('areas.fields.state')}</Title>
            <Text>{record?.state?.name}</Text>

            <Title level={5}>{t('areas.fields.city')}</Title>
            <Text>{record?.city?.name}</Text>

            <Title level={5}>{t('areas.fields.enabled')}</Title>
            <Text>{record?.enabled === true ? t('words.yes') : t('words.no')}</Text>
        </Show>
    );
};