import {
    useTranslate,
    IResourceComponentsProps,
    useNavigation,
    useShow,
} from "@pankod/refine-core";

import {
    List,
    Table,
    useTable,
    Dropdown,
    Menu,
    Icons,
    DateField,
} from "@pankod/refine-antd";

const { FormOutlined, EyeFilled } = Icons;

import { IIncident } from "interfaces";
import { getFooterInfo } from "helpers/functions";

export const IncidentList: React.FC<IResourceComponentsProps> = () => {
    const { tableProps } = useTable<IIncident>();
    const { edit, show } = useNavigation();

    const t = useTranslate();

    const { queryResult, setShowId } = useShow<IIncident>();

    const { data: showQueryResult } = queryResult;
    const record = showQueryResult?.data;

    const moreMenu = (id: number) => (
        <Menu mode="vertical">
            <Menu.Item
                key="1"
                style={{
                    fontSize: 15,
                    fontWeight: 500,
                }}
                icon={
                    <FormOutlined
                        style={{ color: "green", fontSize: "15px" }}
                    />
                }
                onClick={() => edit("incidents", id)}
            >
                {t("buttons.edit")}
            </Menu.Item>

            <Menu.Item
                key="2"
                style={{
                    fontSize: 15,
                    fontWeight: 500,
                }}
                icon={
                    <EyeFilled
                        style={{ color: "green", fontSize: "15px" }}
                    />
                }
                onClick={() => show("incidents", id)}
            >
                {t("buttons.show")}
            </Menu.Item>
        </Menu>
    );

    return (
        <List>
            <Table {...tableProps} rowKey="id" footer={() => getFooterInfo(tableProps.pagination)}>
                <Table.Column
                    dataIndex="id"
                    align="center"
                    title={t("incidents.fields.id")}
                />
                <Table.Column
                    dataIndex={["project", "name"]}
                    align="center"
                    title={t("incidents.fields.project")}
                />
                <Table.Column
                    dataIndex="name"
                    title={t("incidents.fields.name")}
                />
                <Table.Column
                    dataIndex="startDate"
                    align="center"
                    title={t("incidents.fields.startDate")}
                    render={(value) => <DateField value={value} format="DD/MM/YYYY" />}
                />

                <Table.Column
                    dataIndex="endDate"
                    align="center"
                    title={t("incidents.fields.endDate")}
                    render={(value) => value && <DateField value={value} format="DD/MM/YYYY" />}
                />
                <Table.Column
                    dataIndex="finished"
                    title={t("incidents.fields.finished")}
                    render={v => v === true ? t('words.yes') : (v === false ? t('words.no') : '' )}
                />
                <Table.Column<IIncident>
                    fixed="right"
                    title={t("table.actions")}
                    dataIndex="actions"
                    key="actions"
                    align="center"
                    render={(_, record) => (
                        <Dropdown
                            overlay={moreMenu(record.id)}
                            trigger={["click"]}
                        >
                            <Icons.MoreOutlined
                                style={{
                                    fontSize: 24,
                                }}
                            />
                        </Dropdown>
                    )}
                />
            </Table>
        </List>
    );
};
