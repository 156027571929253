import { HttpError, IResourceComponentsProps, useList, useTranslate } from "@pankod/refine-core";

import {
    Create,
    Form,
    Input,
    useForm,
    Row,
    Col,
    Edit,
    Radio,
    RadioChangeEvent,
    Select,
} from "@pankod/refine-antd";

import { IIncident, IProject } from "interfaces";

import { useFromErrorsProviver } from '../../helpers/formErrorsProvider'
import { useEffect, useState } from "react";
import { getCurrentProject } from "helpers/functions";

const { Option } = Select;

const FormComponent = (props: any) => {
    const t = useTranslate();
    const { formProps } = props;
    const { initialValues } = formProps;
    const [finished, setFinished] = useState(null);
    const projects = useList<IProject>({ resource: "projects" });
    const [project, setProject] = useState(null);

    useEffect(() => {
        const pj = getCurrentProject();

        if (projects?.data?.data && project === null) {
            projects.data.data.map((el: any) => {
                if (el.id === pj) {
                    setProject(el);
                }
            })
        }
    }, [projects])

    useEffect(() => {

        if (initialValues && (finished === null || initialValues?.finished !== finished)) {
            console.log('AQUI', initialValues?.finished)
            setFinished(initialValues?.finished);
        }

    }, [initialValues])


    return <Form
        {...formProps}
        layout="vertical"
        initialValues={{
            ...initialValues,
            ...{ 
                project: initialValues?.project ? initialValues?.project['@id'] : project?.['@id'], 
            }
        }}
    >
        <Row gutter={[64, 0]} wrap>
            <Col xs={24} lg={{ span: 8, offset: 8 }}>

                {
                    project &&
                    <Form.Item
                        name="project"
                        rules={[
                            {
                                required: true,
                            },
                        ]}

                        initialValue={project?.['@id']}
                    >
                        <Input type="hidden" />
                    </Form.Item>
                }

                {
                    !project &&
                    <Form.Item
                        label={t("incidents.fields.project")}
                        name="project"
                        rules={[
                            {
                                required: true,
                            },
                        ]}
                    >
                        <Select showSearch
                            filterOption={(input, option) =>
                                (option!.children as unknown as string).toLowerCase().includes(input.toLowerCase())
                            }
                        >
                            {
                                projects?.data &&
                                projects.data.data.map((el: any) => <Option key={el.id} value={el['@id']}>{el.name}</Option>)
                            }
                        </Select>
                    </Form.Item>
                }

                <Form.Item
                    label={t("incidents.fields.name")}
                    name="name"
                    rules={[
                        {
                            required: true,
                        },
                    ]}
                >
                    <Input />
                </Form.Item>
                <Form.Item
                    label={t("incidents.fields.startDate")}
                    name="startDate"
                    normalize={val => val === "" ? null : val}
                    rules={[
                        {
                            required: true,
                        },
                    ]}
                >
                    <Input type="date" />
                </Form.Item>

                {
                    finished === true &&

                    <Form.Item
                        label={t("incidents.fields.endDate")}
                        name="endDate"
                        normalize={val => val === "" ? null : val}
                        rules={[
                            {
                                required: true,
                            },
                        ]}
                    >
                        <Input type="date" />
                    </Form.Item>
                }

                <Form.Item
                    label={t("incidents.fields.finished")}
                    name="finished"                    
                >
                    <Radio.Group onChange={(e: RadioChangeEvent) => {
                        setFinished(e.target.value)
                    }}>
                        <Radio value={true}>{t("words.yes")}</Radio>
                        <Radio value={false}>{t("words.no")}</Radio>
                    </Radio.Group>
                </Form.Item>

            </Col>
        </Row>
    </Form>


}

export const IncidentForm: React.FC<IResourceComponentsProps> = (props: any) => {

    const { formProps, form, saveButtonProps, queryResult, id } = useForm<IIncident>({
        onMutationError: (httpError: HttpError) => {
            form?.setFields(useFromErrorsProviver(httpError));
        }
    });


    saveButtonProps.size = 'large';

    if (id) {
        return <Edit
            isLoading={queryResult?.isFetching}
            saveButtonProps={saveButtonProps}
            recordItemId={id}
        >
            <FormComponent formProps={formProps} />
        </Edit>
    }

    return (
        <Create
            isLoading={queryResult?.isFetching}
            saveButtonProps={saveButtonProps}
        >
            <FormComponent formProps={formProps} />
        </Create>
    );
};
