import React, { useState } from "react";
import { Col, Drawer, Form, Grid, Row, useTable } from "@pankod/refine-antd";
import MapScreen from '../../Map';
import { Filter as MonitoringFilter } from "../monitorings/filter";
import { Filter as SightingFilter } from "../sightings/filter";
import { Filter as AreaFilter } from "../areaincidents/filter";
import { useTranslate } from "@pankod/refine-core";

export const MapPage: React.FC = () => {

    const breakpoint = Grid.useBreakpoint();
    const [open, setOpen] = useState(false);
    const t = useTranslate();
    const [module, setModule] = useState<string | undefined>(undefined);
    const [filters, setFilters] = useState<any | null>(null);

    const [formM] = Form.useForm();
    const [formS] = Form.useForm();
    const [formA] = Form.useForm();

    const showDrawer = () => {
        setOpen(true);
    };

    const onClose = () => {
        setOpen(false);
    };

    const applyFilterCallback = (values: any) => {
        const result: any[] = mountFilter(module, values);

        if (result && result.length > 0) {
            setFilters({ module: module, filters: result })
        }
        
        onClose();
    }

    const openFilter = (id: any) => {
        setModule(id);
        showDrawer();
    }

    const mountFilter = (fl?: string, params?: any) => {
        let filters = [];

        switch (fl) {
            case 'monitoring':
                const { id, startDate, areaIncident, type, strategy } = params;

                filters.push({
                    field: "id",
                    operator: "eq",
                    value: id,
                });

                filters.push({
                    field: "type",
                    operator: "eq",
                    value: type,
                });

                filters.push({
                    field: "strategy",
                    operator: "eq",
                    value: strategy,
                });

                filters.push({
                    field: "areaIncident",
                    operator: "eq",
                    value: areaIncident,
                });

                filters.push(
                    {
                        field: "startDate",
                        operator: "gte",
                        value: startDate
                            ? startDate[0].startOf("day").toISOString()
                            : undefined,
                    },
                    {
                        field: "startDate",
                        operator: "lte",
                        value: startDate
                            ? startDate[1].endOf("day").toISOString()
                            : undefined,
                    },
                );
                break;

            case 'sighting':
                const { date, monitoring, oiled, condition, specie, speciesDescription } = params;
                const recordId = params.id;

                filters.push({
                    field: "id",
                    operator: "eq",
                    value: recordId,
                });

                filters.push({
                    field: "monitoring.id",
                    operator: "eq",
                    value: monitoring?.id,
                });

                filters.push({
                    field: "oiled",
                    operator: "eq",
                    value: oiled,
                });

                filters.push({
                    field: "condition",
                    operator: "eq",
                    value: condition,
                });

                filters.push({
                    field: "monitoring.areaIncident",
                    operator: "eq",
                    value: monitoring?.areaIncident,
                });

                filters.push({
                    field: "specie.id",
                    operator: "eq",
                    value: specie?.id
                });

                filters.push({
                    field: "speciesDescription",
                    operator: "contains",
                    value: speciesDescription
                });

                filters.push(
                    {
                        field: "date",
                        operator: "gte",
                        value: date
                            ? date[0].startOf("day").toISOString()
                            : undefined,
                    },
                    {
                        field: "date",
                        operator: "lte",
                        value: date
                            ? date[1].endOf("day").toISOString()
                            : undefined,
                    },
                );
                break;

                case "areaincident":
                    const {area, incident} = params;
                    const areaId = params?.id;

                    filters.push({
                        field: "id",
                        operator: "eq",
                        value: areaId,
                    });
                    filters.push({
                        field: "area",
                        operator: "eq",
                        value: area,
                    });
                    filters.push({
                        field: "incident",
                        operator: "eq",
                        value: incident
                    });
                    break;

            default:
                break;
        }

        return filters;
    }

    return (
        <>

            <Drawer title={t('words.filters')}
                placement="right"
                bodyStyle={{ padding: 0 }}
                width={breakpoint.sm ? "500px" : "100%"}
                zIndex={1001}
                onClose={onClose}
                visible={open}>

                {
                    module === 'monitoring' &&

                    < MonitoringFilter
                        formProps={{ form: formM, applyFilterCallback: applyFilterCallback }}
                    />
                }

                {
                    module === 'sighting' &&

                    < SightingFilter
                        formProps={{ form: formS, applyFilterCallback: applyFilterCallback }}
                    />
                }

                {
                    module === 'areaincident' && 
                    <AreaFilter formProps={{ form: formA, applyFilterCallback: applyFilterCallback }}></AreaFilter>
                }

            </Drawer>

            <Row>
                <Col span={24} style={{ height:"87vh", width: '100%' }}>
                    <MapScreen
                        layersList={[]}
                        showLayersMenu={true}
                        showClickLocation={false}
                        initialZoom={undefined}
                        fitTo={null}
                        openFilterCallback={openFilter}
                        forceFilters={filters}
                    />
                </Col>
            </Row>
        </>
    );
};
