export const ROLES = {
    ROLE_ADMIN: [
        { label: "Administrador", value: "ROLE_ADMIN" },
        { label: "Gestor do projeto", value: "ROLE_PROJECT_MANAGER" },
    ],
    ROLE_PROJECT_MANAGER: [
        { label: "Gestor do projeto", value: "ROLE_PROJECT_MANAGER" },
        { label: "Coordenador do monitoramento", value: "ROLE_MONITORING_COORDINATOR" },
        { label: "Lider da força tarefa", value: "ROLE_TASK_FORCE_LEADER" },
        { label: "Monitor", value: "ROLE_MONITOR" },
        { label: "Visitante", value: "ROLE_VISITOR" },
    ],
    ROLE_MONITORING_COORDINATOR: [
        { label: "Coordenador do monitoramento", value: "ROLE_MONITORING_COORDINATOR" },
        { label: "Lider da força tarefa", value: "ROLE_TASK_FORCE_LEADER" },
        { label: "Monitor", value: "ROLE_MONITOR" },
        { label: "Visitante", value: "ROLE_VISITOR" },
    ]
}

export const MONITORING_TYPES = [
    { value: "opportunity", label: "words.opportunity" },
    { value: "dedicated", label: "words.dedicated" },
]

export const MONITORING_TYPES_COLORS = [
    { value: "opportunity", label: "yellow" },
    { value: "dedicated", label: "orange" },
]

export const MONITORING_STRATEGIES = [
    { value: "aerial", label: "words.aerial" },
    { value: "boarded", label: "words.boarded" },
    { value: "terrestrial", label: "words.terrestrial" },
]

export const MONITORING_STRATEGIES_COLORS = [
    { value: "aerial", label: "cyan" },
    { value: "boarded", label: "blue" },
    { value: "terrestrial", label: "volcano" },
]

export const AERIAL_VEHICLE_TYPES = [
    { value: "helicopter", label: "words.helicopter" },
    { value: "airplane", label: "words.airplane" },
    { value: "drone", label: "words.drone" },
]

export const TERRESTRIAL_VEHICLE_TYPES = [
    { value: "onfoot", label: "words.onfoot" },
    { value: "quadricycle", label: "words.quadricycle" },
    { value: "motorcycle", label: "words.motorcycle" },
    { value: "bicycle", label: "words.bicycle" },
    { value: "car", label: "words.car" },
]

export const TIDE_CHOICES = [
    { value: "flood", label: "words.flood" },
    { value: "ebb", label: "words.ebb" },
    { value: "flood_quadrature", label: "phrases.floodQuadrature" },
    { value: "flood_syzygy", label: "phrases.floodSyzygy" },
    { value: "ebb_quadrature", label: "phrases.ebbQuadrature" },
    { value: "ebb_syzygy", label: "phrases.ebbSyzygy" },
    { value: "not_applicable", label: "phrases.notApplicable" }
]

export const WIND_CHOICES = [
    { value: "1", label: "phrases.wind_1" },
    { value: "2", label: "phrases.wind_2" },
    { value: "3", label: "phrases.wind_3" },
    { value: "4", label: "phrases.wind_4" },
    { value: "5", label: "phrases.wind_5" },
    { value: "6", label: "phrases.wind_6" },
    { value: "7", label: "phrases.wind_7" },
    { value: "8", label: "phrases.wind_8" },
    { value: "9", label: "phrases.wind_9" },
    { value: "10", label: "phrases.wind_10" },
    { value: "11", label: "phrases.wind_11" },
    { value: "12", label: "phrases.wind_12" },
    { value: "absent", label: "words.absent" }
]

export const CLIMATE_CHOICES = [
    { value: "ens", label: "phrases.ens" },
    { value: "pnu", label: "phrases.pnu" },
    { value: "nub", label: "phrases.nub" },
    { value: "chu", label: "phrases.chu" },
    { value: "tem", label: "phrases.tem" },
    { value: "neb", label: "phrases.neb" }
]

export const OILED_CHOICES = [
    { value: "yes", label: "words.yes" },
    { value: "no", label: "words.no" },
    { value: "uninformed", label: "words.uninformed" }
]


export const AVES_BEHAVIOR_CHOICES = [
    { value: "ali", label: "phrases.ali" },
    { value: "voo", label: "phrases.voo" },
    { value: "pou", label: "phrases.pou" },
    { value: "des", label: "phrases.des" },
    { value: "rep", label: "phrases.rep" },
]

export const MAMMALIA_BEHAVIOR_CHOICES = [
    { value: "bcc", label: "phrases.bcc" },
    { value: "bca", label: "phrases.bca" },
    { value: "der", label: "phrases.der" },
    { value: "del", label: "phrases.del" },
    { value: "bor", label: "phrases.bor" },
    { value: "sal", label: "phrases.sal" },
    { value: "ali", label: "phrases.ali" },
    { value: "cop", label: "phrases.cop" },
    { value: "ven", label: "phrases.ven" },
    { value: "inm", label: "phrases.inm" },
    { value: "inr", label: "phrases.inr" },
]

export const AREA_TYPES = [
    { value: "offshore", label: "words.offshore" },
    { value: "nearshore", label: "words.nearshore" },
    { value: "terrestrial", label: "words.terrestrial" },
]

export const MONITORING_NOT_FINISHED_CHOICES = [
    { value: "environmental", label: "words.environmental" },
    { value: "operational", label: "words.operational" },
    { value: "other", label: "words.other" },
]

export const ZOOGROUP_CHOICES = [
    { value: "aves", label: "words.aves" },
    { value: "mammalia", label: "words.mammalia" },
    { value: "reptilia", label: "words.reptilia" },
    { value: "other", label: "words.other" },
]

export const Constants = {
    API_URL: 'http://191.252.223.128:8081/public',
    TOKEN_KEY: 'token_key',
    AUTH_KEY: 'auth_key',
    CURRENT_PROJECT_KEY: '@pmfesCurrentProject_',
    PROJECTS_KEY: '@pmfesProjects_',
    FILTER_MAP_KEY: '@pmfesFilterMap_',
    FILTER_MAP_VALUES_KEY: "@pmfesFilterMapValues",
}