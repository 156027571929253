import PropTypes from 'prop-types';
import { Divider, Typography, Row, Col } from "@pankod/refine-antd";
import { useTranslate } from '@pankod/refine-core';
import { MONITORING_STRATEGIES, MONITORING_TYPES } from 'helpers/constants';
const { Title, Text } = Typography;

const Popup = ({ record, showDataCallback, ...props }) => {

    const t = useTranslate();

    if (!record)
        return <></>

    return (
        <Row style={{maxWidth:500}}>
            <Divider orientation="left" style={{ borderTopColor: 'rgba(0, 0, 0, 0.20)' }}>{t('phrases.monitoringData')}</Divider>

            <Row gutter={[16, 16]} wrap>
                <Col span={12}>
                    <Title level={5}>{t('monitorings.fields.id')}</Title>
                    <Text>{record?.id}</Text>
                </Col>

                <Col span={12}>
                    <Title level={5}>{t('monitorings.fields.responsible')}</Title>
                    <Text>{record?.responsible}</Text>
                </Col>

                <Col span={12}>
                    <Title level={5}>{t('monitorings.fields.finished')}</Title>
                    <Text>{record?.finished === true ? t('words.yes') : t('words.no')}</Text>
                </Col>

                <Col span={12}>
                    <Title level={5}>{t('area_incidents.area_incidents')}</Title>
                    <Text>{`${record?.area} / ${record?.incident}`}</Text>
                </Col>

                <Col span={12}>
                    <Title level={5}>{t('monitorings.fields.startDate')}</Title>
                    <Text>{record?.startDate}</Text>
                </Col>

                <Col span={12}>
                    <Title level={5}>{t('monitorings.fields.endDate')}</Title>
                    <Text>{record?.endDate}</Text>
                </Col>

                <Col span={12}>
                    <Title level={5}>{t('monitorings.fields.type')}</Title>
                    <Text>{t(MONITORING_TYPES.find((e) => e.value === record?.type)?.label || '')}</Text>
                </Col>

                <Col span={12}>
                    <Title level={5}>{t('monitorings.fields.strategy')}</Title>
                    <Text>{t(MONITORING_STRATEGIES.find((e) => e.value === record?.strategy)?.label || '')}</Text>
                </Col>

            </Row>
        </Row>
    )

}

Popup.propTypes = {
    record: PropTypes.object,
    showDataCallback: PropTypes.func,
}

Popup.defaultProps = {
    record: null,
    showDataCallback: () => { }
}

export default Popup;