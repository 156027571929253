import {
    useTranslate,
    IResourceComponentsProps,
} from "@pankod/refine-core";

import {
    List,
    Table,
    useTable,
    Dropdown,
    Icons,
} from "@pankod/refine-antd";

import { IUser } from "interfaces";
import { getFooterInfo } from "helpers/functions";
import { MoreMenu } from "components/list/MoreMenu";

export const UserList: React.FC<IResourceComponentsProps> = () => {
    const { tableProps } = useTable<IUser>();
    const t = useTranslate();

    return (
        <>
            <List>
                <Table {...tableProps} rowKey="id" footer={() => getFooterInfo(tableProps.pagination)}>
                    <Table.Column
                        dataIndex="id"
                        align="center"
                        title={t("users.fields.id")}
                    />

                    <Table.Column
                        dataIndex="name"
                        title={t("users.fields.name")}
                    />

                    <Table.Column
                        dataIndex="email"
                        title={t("users.fields.email")}
                    />

                    <Table.Column
                        dataIndex="enabled"
                        title={t("users.fields.enabled")}
                        render={((value: boolean) => value === true ? t('words.yes') : t('words.no'))}
                    />
                    
                    <Table.Column<IUser>
                        fixed="right"
                        title={t("table.actions")}
                        dataIndex="actions"
                        key="actions"
                        align="center"
                        render={(_, record) => (
                            <Dropdown
                                overlay={<MoreMenu record={record} id={record.id} resource="users" canEdit={true} canShow={true} canChangeStatus={true} statusAttribute="enabled" />}
                                trigger={["click"]}
                            >
                                <Icons.MoreOutlined
                                    style={{
                                        fontSize: 24,
                                    }}
                                />
                            </Dropdown>
                        )}
                    />
                </Table>
            </List>

        </>
    );
};
