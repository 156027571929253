import Popup from "./Popup";
import moment from 'moment';
import bird from "../../../styles/bird.png";
import pin from "../../../styles/pin3.png";

const resource = {
    id: 'monitoring',
    popup: Popup,
    label: 'Monitoramentos',
    hasFilter: true,
    filterDefaultValues: [
        {
            field: "startDate",
            operator: "gte",
            value: moment().subtract(1, "month").format('YYYY-MM-DD')
        },
        {
            field: "startDate",
            operator: "lte",
            value: moment().format('YYYY-MM-DD')
        },
    ],    
    icon: pin,
    layerIcon: bird,
    visible: false,
    loadOnInit: false
};

export default resource;