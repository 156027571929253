import { useShow, useTranslate } from "@pankod/refine-core";
import { Show, Typography, DateField, Row, Col } from "@pankod/refine-antd";
import { IIncident } from "interfaces";

const { Title, Text } = Typography;

export const IncidentShow: React.FC = () => {
    const { queryResult } = useShow<IIncident>();
    const { data, isLoading } = queryResult;
    const record = data?.data;
    const t = useTranslate();

    return (
        <Show isLoading={isLoading}>

            <Row gutter={[16, 16]} wrap>
                <Col span={12}>
                    <Title level={5}>{t('incidents.fields.id')}</Title>
                    <Text>{record?.id}</Text>
                </Col>
                <Col span={12}>
                    <Title level={5}>{t('incidents.fields.project')}</Title>
                    <Text>{record?.project?.name}</Text>
                </Col>
            
                <Col span={12}>
                    <Title level={5}>{t('incidents.fields.name')}</Title>
                    <Text>{record?.name}</Text>
                </Col>
                <Col span={12}>
                    <Title level={5}>{t('incidents.fields.startDate')}</Title>
                    <DateField format="DD/MM/YYYY" value={record?.startDate ?? "-"} />
                </Col>
            
                <Col span={12}>
                    <Title level={5}>{t('incidents.fields.finished')}</Title>
                    <Text>{record?.finished === true ? t('words.yes') : t('words.no')}</Text>
                </Col>
                <Col span={12}>
                    <Title level={5}>{t('incidents.fields.endDate')}</Title>
                    <DateField format="DD/MM/YYYY" value={record?.endDate ?? "-"} />
                </Col>
            </Row>


        </Show>
    );
};