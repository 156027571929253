import { HttpError, IResourceComponentsProps, useApiUrl, useList, useTranslate } from "@pankod/refine-core";

import {
    Create,
    Form,
    useForm,
    Row,
    Col,
    Edit,
    Select,
    Input,
    Button,
    Icons,
    Radio,
    Checkbox,
    Card,
    Divider,
    InputNumber,
    Typography,
} from "@pankod/refine-antd";

import {
    IArea,
    IUser,
    IMonitoring,
    IIncident,
    ITaxonomic
} from "interfaces";
import { useFromErrorsProviver } from '../../helpers/formErrorsProvider'
import { useEffect, useState } from "react";
const { Option } = Select;
import { FilesForm } from "components/form/files";
import {
    AERIAL_VEHICLE_TYPES,
    CLIMATE_CHOICES,
    MONITORING_NOT_FINISHED_CHOICES,
    MONITORING_STRATEGIES,
    MONITORING_TYPES,
    TERRESTRIAL_VEHICLE_TYPES,
    TIDE_CHOICES,
    WIND_CHOICES
} from "helpers/constants";
import { SightingsComponent } from "./sightings";
const { Title, Text } = Typography;

const { TextArea } = Input;

const FormComponent = (props: any) => {
    const t = useTranslate();
    const { formProps } = props;
    const { initialValues } = formProps;
    const apiUrl = useApiUrl();

    const incidents = useList<IIncident>({
        resource: "incidents", config: {
            filters: [{
                field: "finished",
                operator: "eq",
                value: false,
            }]
        }
    });

    const taxonomics = useList<ITaxonomic>({ resource: "taxonomics" });
    const usersListQueryResult = useList<IUser>({ resource: "project_collaborators" });
    const [strategy, setStrategy] = useState(null);
    const [areas, setAreas] = useState([]);
    const [isGpx, setIsGpx] = useState(false);
    const [isFinished, setIsFinished] = useState(null);
    const [userList, setUserList] = useState([]);

    let sightings: any = [];

    initialValues?.sightings.map((e: any) => {
        let value = { ...e };
        value.specie = e.specie ? e.specie['@id'] : null;

        if (value.images) {
            value.images.map((item: any) => {
                if (item.url && !item.url.includes(apiUrl)) {
                    item.url = apiUrl + item.url;
                }
            })
        }

        sightings.push(value);
    })

    useEffect(() => {
        if (initialValues && (isFinished === null || isFinished !== initialValues?.finished)) {
            setStrategy(initialValues?.strategy);
            setIsGpx(initialValues?.gpxFile && initialValues?.gpxFile.length > 0);
            setIsFinished(initialValues?.finished);

            initialValues?.images.map((e: any) => {
                if (!e.url.includes(apiUrl)) {
                    e.url = apiUrl + e.url;
                }
            })

            if (!initialValues?.gpxFile?.url?.includes(apiUrl)) {
                initialValues.gpxFile.url = apiUrl + initialValues?.gpxFile?.url;
            }
        }
    }, [initialValues])

    useEffect(() => {
        if (initialValues && incidents?.data && areas.length === 0) {
            updateAreaList(initialValues?.areaIncident?.incident['@id']);
        }
    }, [initialValues, incidents])

    useEffect(() => {
        if (usersListQueryResult.data && userList.length === 0) {
            let list: any = [];

            usersListQueryResult.data.data.map((item: any) => {
                if (list.filter((el: any) => el.id == item.user.id).length === 0) {
                    list.push(item.user)
                }
            })

            setUserList(list);
        }
    }, [usersListQueryResult])

    const updateAreaList = (v: any) => {
        let list: any = [];
        const incident = incidents?.data?.data.find((el: any) => el['@id'] === v);
        incident?.areas.map((el: any) => list.push(el));
        setAreas(list);
    }

    return <Form
        {...formProps}
        layout="vertical"
        initialValues={
            {
                ...initialValues,
                ...{
                    incident: initialValues?.areaIncident?.incident['@id'],
                    responsible: initialValues?.responsible['@id'],
                    areaIncident: initialValues?.areaIncident['@id'],
                    sightings: sightings,
                    area: initialValues?.areaIncident?.area['@id'],
                }
            }
        }
    >
        <Row >
            <Col md={24} xl={{ span: 12, offset: 6 }}>

                <Row gutter={[8, 16]} wrap>
                    <Col lg={0} xl={6}>
                    </Col>
                    <Col lg={24} xl={12}>


                        {
                            (!initialValues?.areaIncident?.incident || initialValues?.areaIncident?.incident.finished === false) &&
                            <Form.Item
                                label={t("monitorings.fields.incident")}
                                name="incident"
                                rules={[
                                    {
                                        required: true,
                                    },
                                ]}
                            >
                                <Select
                                    showSearch
                                    filterOption={(input, option) =>
                                        (option!.children as unknown as string).toLowerCase().includes(input.toLowerCase())
                                    }
                                    onChange={(v) => {
                                        updateAreaList(v);
                                    }}
                                >
                                    {
                                        incidents?.data &&
                                        incidents?.data.data.map((el: any) => <Option key={el.id} value={el['@id']}>{el.name}</Option>)
                                    }
                                </Select>
                            </Form.Item>
                        }

                        {
                            (initialValues?.areaIncident?.incident && initialValues?.areaIncident?.incident?.finished === true) &&
                            <Form.Item
                                label={t("monitorings.fields.incident")}
                            >
                                <Text>{initialValues?.areaIncident?.incident.name}</Text>
                            </Form.Item>
                        }

                    </Col>
                </Row>

                <Divider orientation="left" style={{ borderTopColor: 'rgba(0, 0, 0, 0.20)' }}>{t('phrases.initialData')}</Divider>

                <Row gutter={[8, 16]} wrap>
                    <Col xs={24} lg={12}>
                        <Form.Item
                            label={t("projects.fields.startDate")}
                            name="startDate"
                            normalize={val => val === "" ? null : val}
                            rules={[
                                {
                                    required: isGpx ? false : true,
                                },
                            ]}
                        >
                            <Input type="datetime-local" disabled={isGpx} />
                        </Form.Item>
                    </Col>
                    <Col xs={24} lg={12}>
                        <Form.Item
                            label={t("projects.fields.endDate")}
                            name="endDate"
                            normalize={val => val === "" ? null : val}
                            rules={[
                                {
                                    required: isGpx ? false : true,
                                },
                            ]}
                        >
                            <Input type="datetime-local" disabled={isGpx} />
                        </Form.Item>
                    </Col>
                </Row>


                <Row gutter={[8, 16]} wrap>
                    <Col xs={24} lg={12}>
                        <Form.Item
                            label={t("monitorings.fields.responsible")}
                            name="responsible"
                            rules={[
                                {
                                    required: true,
                                },
                            ]}
                        >
                            <Select showSearch
                                filterOption={(input, option) =>
                                    (option!.children as unknown as string).toLowerCase().includes(input.toLowerCase())
                                }
                            >
                                {
                                    userList.length > 0 &&
                                    userList.map((el: any) => <Option key={el.id} value={el['@id']}>{el.name}</Option>)
                                }
                            </Select>
                        </Form.Item>
                    </Col>
                    <Col xs={24} lg={12}>


                        {
                            (!initialValues?.areaIncident?.incident || initialValues?.areaIncident?.incident.finished === false) &&

                            <Form.Item
                                label={t("monitorings.fields.area")}
                                name="areaIncident"
                                rules={[
                                    {
                                        required: true,
                                    },
                                ]}
                            >
                                <Select
                                    showSearch
                                    filterOption={(input, option) =>
                                        (option!.children as unknown as string).toLowerCase().includes(input.toLowerCase())
                                    }
                                >
                                    {
                                        areas &&
                                        areas.map((el: any) => <Option key={el.id} value={el['@id']}>{el.area.name}</Option>)
                                    }
                                </Select>
                            </Form.Item>
                        }

                        {
                            (initialValues?.areaIncident?.incident && initialValues?.areaIncident?.incident?.finished === true) && 
                            <Form.Item
                                label={t("monitorings.fields.area")}
                                style={{marginLeft:20}}
                            >
                                <Text>{initialValues?.areaIncident?.area.name}</Text>
                            </Form.Item>
                        }

                    </Col>
                </Row>

                <Divider orientation="left" style={{ borderTopColor: 'rgba(0, 0, 0, 0.20)' }}>{t('phrases.faunaMonitoring')}</Divider>

                <Row gutter={[8, 16]} wrap>
                    <Col xs={24} lg={12}>
                        <Form.Item
                            label={t("monitorings.fields.type")}
                            name="type"
                            rules={[
                                {
                                    required: true,
                                },
                            ]}
                        >
                            <Select showSearch
                                filterOption={(input, option) =>
                                    (option!.children as unknown as string).toLowerCase().includes(input.toLowerCase())
                                }
                            >
                                {
                                    MONITORING_TYPES.map(el => <Option key={el.value} value={el.value}>{t(el.label)}</Option>)
                                }
                            </Select>
                        </Form.Item>
                    </Col>
                    <Col xs={24} lg={12}>
                        <Form.Item
                            label={t("monitorings.fields.strategy")}
                            name="strategy"
                            rules={[
                                {
                                    required: true,
                                },
                            ]}
                        >
                            <Select showSearch
                                filterOption={(input, option) =>
                                    (option!.children as unknown as string).toLowerCase().includes(input.toLowerCase())
                                }
                                onChange={(v) => {

                                    let values = formProps.form.getFieldsValue();

                                    if (v !== "aerial") {
                                        values.aerialVehicleType = null;
                                        values.flightHeight = null;
                                        values.airport = null;
                                        values.airportDestiny = null;
                                    }

                                    if (v !== "boarded") {
                                        values.vesselName = null;
                                        values.harbor = null;
                                        values.harborDestiny = null;
                                    }

                                    if (v !== "terrestrial") {
                                        values.terrestrialVehicleType = null;
                                        values.monitoredLocation = null;
                                    }

                                    formProps.form.setFieldsValue(values);
                                    setStrategy(v);
                                }}
                            >
                                {
                                    MONITORING_STRATEGIES.map(el => <Option key={el.value} value={el.value}>{t(el.label)}</Option>)
                                }
                            </Select>
                        </Form.Item>
                    </Col>
                </Row>

                {
                    strategy === "aerial" &&
                    <Row gutter={[8, 16]} wrap>
                        <Col xs={24} lg={12}>
                            <Form.Item
                                label={t("monitorings.fields.aerialVehicleType")}
                                name="aerialVehicleType"
                                rules={[
                                    {
                                        required: true,
                                    },
                                ]}
                            >
                                <Select showSearch
                                    filterOption={(input, option) =>
                                        (option!.children as unknown as string).toLowerCase().includes(input.toLowerCase())
                                    }
                                >
                                    {
                                        AERIAL_VEHICLE_TYPES.map(el => <Option key={el.value} value={el.value}>{t(el.label)}</Option>)
                                    }
                                </Select>
                            </Form.Item>
                        </Col>

                        <Col xs={24} lg={12}>
                            <Form.Item
                                label={t("monitorings.fields.flightHeight")}
                                name="flightHeight"
                                rules={[
                                    {
                                        required: true,
                                    },
                                ]}
                            >
                                <InputNumber style={{ width: "100%" }} decimalSeparator="." width={"100%"} />
                            </Form.Item>
                        </Col>

                        <Col xs={24} lg={12}>
                            <Form.Item
                                label={t("monitorings.fields.airport")}
                                name="airport"
                                rules={[
                                    {
                                        required: true,
                                    },
                                ]}
                            >
                                <Input />
                            </Form.Item>
                        </Col>

                        <Col xs={24} lg={12}>
                            <Form.Item
                                label={t("monitorings.fields.airportDestiny")}
                                name="airportDestiny"
                                rules={[
                                    {
                                        required: true,
                                    },
                                ]}
                            >
                                <Input />
                            </Form.Item>
                        </Col>
                    </Row>
                }

                {
                    strategy === "boarded" &&

                    <Row gutter={[8, 16]} wrap>
                        <Col xs={24} lg={8}>
                            <Form.Item
                                label={t("monitorings.fields.vesselName")}
                                name="vesselName"
                                rules={[
                                    {
                                        required: true,
                                    },
                                ]}
                            >
                                <Input />
                            </Form.Item>
                        </Col>
                        <Col xs={24} lg={8}>
                            <Form.Item
                                label={t("monitorings.fields.harbor")}
                                name="harbor"
                                rules={[
                                    {
                                        required: true,
                                    },
                                ]}
                            >
                                <Input />
                            </Form.Item>
                        </Col>
                        <Col xs={24} lg={8}>
                            <Form.Item
                                label={t("monitorings.fields.harborDestiny")}
                                name="harborDestiny"
                                rules={[
                                    {
                                        required: true,
                                    },
                                ]}
                            >
                                <Input />
                            </Form.Item>
                        </Col>
                    </Row>
                }

                {
                    strategy === "terrestrial" &&

                    <Row gutter={[8, 16]} wrap>
                        <Col span={12}>
                            <Form.Item
                                label={t("monitorings.fields.terrestrialVehicleType")}
                                name="terrestrialVehicleType"
                                rules={[
                                    {
                                        required: true,
                                    },
                                ]}
                            >
                                <Select showSearch
                                    filterOption={(input, option) =>
                                        (option!.children as unknown as string).toLowerCase().includes(input.toLowerCase())
                                    }
                                >
                                    {
                                        TERRESTRIAL_VEHICLE_TYPES.map(el => <Option key={el.value} value={el.value}>{t(el.label)}</Option>)
                                    }
                                </Select>
                            </Form.Item>
                        </Col>

                        <Col span={12}>
                            <Form.Item
                                label={t("monitorings.fields.monitoredLocation")}
                                name="monitoredLocation"
                                rules={[
                                    {
                                        required: true,
                                    },
                                ]}
                            >
                                <Input />
                            </Form.Item>
                        </Col>
                    </Row>
                }

                <Divider orientation="left" style={{ borderTopColor: 'rgba(0, 0, 0, 0.20)' }}>{t('phrases.monitoringCharacteristics')}</Divider>

                <Row gutter={[8, 16]} wrap>
                    <Col xs={24} lg={12}>
                        <Form.Item
                            label={t("monitorings.fields.totalDistance")}
                            name="totalDistance"
                            rules={[
                                {
                                    required: isGpx ? false : true,
                                },
                            ]}
                        >
                            <InputNumber style={{ width: "100%" }} decimalSeparator="." disabled={isGpx} />
                        </Form.Item>
                    </Col>
                    <Col xs={24} lg={12}>
                        <Form.Item
                            label={t("monitorings.fields.tide")}
                            name="tide"
                            rules={[
                                {
                                    required: true,
                                },
                            ]}
                        >
                            <Select showSearch
                                filterOption={(input, option) =>
                                    (option!.children as unknown as string).toLowerCase().includes(input.toLowerCase())
                                }
                            >
                                {
                                    TIDE_CHOICES.map(el => <Option key={el.value} value={el.value}>{t(el.label)}</Option>)
                                }
                            </Select>
                        </Form.Item>
                    </Col>
                </Row>

                <Row gutter={[8, 16]} wrap>
                    <Col xs={24} lg={8}>
                        <Form.Item
                            label={t("monitorings.fields.wind")}
                            name="wind"
                            rules={[
                                {
                                    required: true,
                                },
                            ]}
                        >
                            <Select showSearch
                                filterOption={(input, option) =>
                                    (option!.children as unknown as string).toLowerCase().includes(input.toLowerCase())
                                }
                            >
                                {
                                    WIND_CHOICES.map(el => <Option key={el.value} value={el.value}>{t(el.label)}</Option>)
                                }
                            </Select>
                        </Form.Item>
                    </Col>
                    <Col xs={24} lg={8}>
                        <Form.Item
                            label={t("monitorings.fields.windSpeed")}
                            name="windSpeed"
                            rules={[
                                {
                                    required: true,
                                },
                            ]}
                        >
                            <InputNumber style={{ width: "100%" }} decimalSeparator="." />
                        </Form.Item>
                    </Col>
                    <Col xs={24} lg={8}>
                        <Form.Item
                            label={t("monitorings.fields.windDirection")}
                            name="windDirection"
                            rules={[
                                {
                                    required: true,
                                },
                            ]}
                        >
                            <InputNumber style={{ width: "100%" }} min={1} max={360} />
                        </Form.Item>
                    </Col>
                </Row>

                <Row gutter={[8, 16]} wrap>
                    <Col xs={24} lg={8}>
                        <Form.Item
                            label={t("monitorings.fields.visibility")}
                            name="visibility"
                            rules={[
                                {
                                    required: true,
                                },
                            ]}
                        >
                            <InputNumber style={{ width: "100%" }} decimalSeparator="." />
                        </Form.Item>
                    </Col>
                    <Col xs={24} lg={8}>
                        <Form.Item
                            label={t("monitorings.fields.temperature")}
                            name="temperature"
                            rules={[
                                {
                                    required: true,
                                },
                            ]}
                        >
                            <InputNumber style={{ width: "100%" }} decimalSeparator="." />
                        </Form.Item>
                    </Col>
                    <Col xs={24} lg={8}>
                        <Form.Item
                            label={t("monitorings.fields.climate")}
                            name="climate"
                            rules={[
                                {
                                    required: true,
                                },
                            ]}
                        >
                            <Select showSearch
                                filterOption={(input, option) =>
                                    (option!.children as unknown as string).toLowerCase().includes(input.toLowerCase())
                                }
                            >
                                {
                                    CLIMATE_CHOICES.map(el => <Option key={el.value} value={el.value}>{t(el.label)}</Option>)
                                }
                            </Select>
                        </Form.Item>
                    </Col>
                </Row>

                <Row gutter={[8, 16]} wrap>
                    <Col xs={24} lg={12}>
                        <Form.Item
                            label={t("monitorings.fields.startLatitude")}
                            name={["initialCoordinate", "latitude"]}
                            rules={[
                                {
                                    required: isGpx ? false : true,
                                },
                            ]}
                        >
                            <InputNumber style={{ width: "100%" }} decimalSeparator="." disabled={isGpx} />
                        </Form.Item>
                    </Col>
                    <Col xs={24} lg={12}>
                        <Form.Item
                            label={t("monitorings.fields.startLongitude")}
                            name={["initialCoordinate", "longitude"]}
                            rules={[
                                {
                                    required: isGpx ? false : true,
                                },
                            ]}
                        >
                            <InputNumber style={{ width: "100%" }} decimalSeparator="." disabled={isGpx} />
                        </Form.Item>
                    </Col>
                </Row>

                <Row gutter={[8, 16]} wrap>
                    <Col xs={24} lg={12}>
                        <Form.Item
                            label={t("monitorings.fields.endLatitude")}
                            name={["finalCoordinate", "latitude"]}
                            rules={[
                                {
                                    required: isGpx ? false : true,
                                },
                            ]}
                        >
                            <InputNumber style={{ width: "100%" }} decimalSeparator="." disabled={isGpx} />
                        </Form.Item>
                    </Col>
                    <Col xs={24} lg={12}>
                        <Form.Item
                            label={t("monitorings.fields.endLongitude")}
                            name={["finalCoordinate", "longitude"]}
                            rules={[
                                {
                                    required: isGpx ? false : true,
                                },
                            ]}
                        >
                            <InputNumber style={{ width: "100%" }} decimalSeparator="." disabled={isGpx} />
                        </Form.Item>
                    </Col>
                </Row>

                <Divider orientation="left" style={{ borderTopColor: 'rgba(0, 0, 0, 0.20)' }}>{t('words.finishing')}</Divider>

                <Row gutter={[8, 16]} wrap>
                    <Col xs={8} lg={8}>
                        <Form.Item
                            label={t("monitorings.fields.finished")}
                            name="finished"
                            rules={[
                                {
                                    required: true,
                                },
                            ]}
                        >
                            <Radio.Group onChange={(e: any) => setIsFinished(e.target.value)}>
                                <Radio value={true}>{t("words.yes")}</Radio>
                                <Radio value={false}>{t("words.no")}</Radio>
                            </Radio.Group>
                        </Form.Item>
                    </Col>

                    {
                        isFinished === false &&
                        <Col xs={8} lg={8}>
                            <Form.Item
                                label={t("monitorings.fields.notFinishedReason")}
                                name="notFinishedReason"
                                rules={[
                                    {
                                        required: true,
                                    },
                                ]}
                            >
                                <Select showSearch
                                    filterOption={(input, option) =>
                                        (option!.children as unknown as string).toLowerCase().includes(input.toLowerCase())
                                    }
                                >
                                    {
                                        MONITORING_NOT_FINISHED_CHOICES.map(el => <Option key={el.value} value={el.value}>{t(el.label)}</Option>)
                                    }
                                </Select>
                            </Form.Item>

                        </Col>
                    }

                    {
                        isFinished === false &&
                        <Col xs={8} lg={8}>
                            <Form.Item
                                label={t("monitorings.fields.notFinishedJustification")}
                                name="notFinishedJustification"
                                rules={[
                                    {
                                        required: true,
                                    },
                                ]}
                            >
                                <TextArea />
                            </Form.Item>
                        </Col>
                    }

                    <Col xs={24} lg={isFinished === false ? 24 : 16}>
                        <Form.Item
                            label={t("monitorings.fields.observation")}
                            name="observation"
                        >
                            <TextArea />
                        </Form.Item>
                    </Col>
                </Row>

                <Divider orientation="left" style={{ borderTopColor: 'rgba(0, 0, 0, 0.20)' }}>{t('monitorings.fields.sightings')}</Divider>

                <Row gutter={[8, 16]} wrap>
                    <Col xs={24} lg={24}>
                        <Form.List name="sightings">
                            {(fields, { add, remove, ...rest }) => (
                                <>
                                    {fields.map(({ key, name, ...rests }) => {

                                        let val = initialValues?.sightings?.[name];

                                        return (
                                            <Card
                                                key={key}
                                                title={t('phrases.faunaSightings')}
                                                style={{ marginTop: 10, marginBottom: 10 }}
                                                extra={<Icons.DeleteOutlined onClick={() => remove(name)} />}>

                                                <SightingsComponent form={formProps.form} initialValues={val} name={name} taxonomicList={taxonomics} />

                                            </Card>
                                        )
                                    })}
                                    <Form.Item>
                                        <Button type="dashed" onClick={() => add()} block icon={<Icons.PlusOutlined />}>
                                            {t('phrases.addSightings')}
                                        </Button>
                                    </Form.Item>
                                </>
                            )}
                        </Form.List>
                    </Col>
                </Row>

                <Divider orientation="left" style={{ borderTopColor: 'rgba(0, 0, 0, 0.20)' }} />

                <FilesForm form={formProps.form} name="images" accept=".jpg,.png" maxFiles={200} label="monitorings.fields.images" listType="picture" />
                <FilesForm form={formProps.form} name="gpxFile" accept=".gpx" maxFiles={1} label="monitorings.fields.gpxFile" onChange={(list: any) => setIsGpx(list && list.length)} />

            </Col>
        </Row>
    </Form>
}

export const MonitoringForm: React.FC<IResourceComponentsProps> = (props: any) => {

    const { formProps, form, saveButtonProps, queryResult, id } = useForm<IMonitoring>({
        onMutationError: (httpError: HttpError) => {
            form?.setFields(useFromErrorsProviver(httpError));
        }
    });

    saveButtonProps.size = 'large';

    if (id) {
        return <Edit
            isLoading={queryResult?.isFetching}
            saveButtonProps={saveButtonProps}
            recordItemId={id}
        >
            <FormComponent formProps={formProps} />
        </Edit>
    }

    return (
        <Create
            isLoading={queryResult?.isFetching}
            saveButtonProps={saveButtonProps}
        >
            <FormComponent formProps={formProps} />
        </Create>
    );
};