import { useShow, useTranslate } from "@pankod/refine-core";
import { Col, Row, Show, Typography } from "@pankod/refine-antd";
import { ITaxonomic } from "interfaces";

const { Title, Text } = Typography;

export const TaxonomicShow: React.FC = () => {
    const { queryResult } = useShow<ITaxonomic>();
    const { data, isLoading } = queryResult;
    const record = data?.data;
    const t = useTranslate();

    return (
        <Show canDelete={false} isLoading={isLoading}>

            <Row gutter={[16, 16]} wrap>
                <Col span={12}>
                    <Title level={5}>{t('taxonomics.fields.id')}</Title>
                    <Text>{record?.id}</Text>
                </Col>
                <Col span={12}>
                    <Title level={5}>{t('taxonomics.fields.name')}</Title>
                    <Text>{record?.name}</Text>
                </Col>
            
                <Col span={12}>
                    <Title level={5}>{t('taxonomics.fields.class')}</Title>
                    <Text>{record?.class}</Text>
                </Col>
                <Col span={12}>
                    <Title level={5}>{t('taxonomics.fields.order')}</Title>
                    <Text>{record?.order}</Text>
                </Col>

                <Col span={12}>
                    <Title level={5}>{t('taxonomics.fields.suborder')}</Title>
                    <Text>{record?.suborder}</Text>
                </Col>
                <Col span={12}>
                    <Title level={5}>{t('taxonomics.fields.family')}</Title>
                    <Text>{record?.family}</Text>
                </Col>
            
                <Col span={12}>
                    <Title level={5}>{t('taxonomics.fields.genus')}</Title>
                    <Text>{record?.genus}</Text>
                </Col>
                <Col span={12}>
                    <Title level={5}>{t('taxonomics.fields.specie')}</Title>
                    <Text style={{fontStyle:'italic'}}>{record?.specie}</Text>
                </Col>
            </Row>

        </Show>
    );
};