import { useApiUrl, useShow, useTranslate } from "@pankod/refine-core";
import { Show } from "@pankod/refine-antd";
import { ISightings } from "interfaces";
import { SightingShowItem } from "./showItem";

export const SightingShow: React.FC = () => {
    const { queryResult } = useShow<ISightings>();
    const { data, isLoading } = queryResult;
    const record = data?.data;
    const t = useTranslate();
    const apiUrl = useApiUrl();
    
    return (
        <Show isLoading={isLoading}>
            <SightingShowItem t={t} record={record} apiUrl={apiUrl}/>
        </Show>
    );
};