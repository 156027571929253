import { useApiUrl, useRouterContext } from "@pankod/refine-core";
import { Typography, Row, Col, DateField, Divider, Card, Image } from "@pankod/refine-antd";
import { ISightings } from "interfaces";
import { OILED_CHOICES, ZOOGROUP_CHOICES } from "helpers/constants";
import moment from 'moment';
import sighting from '../../Map/Layers/Sighting';
import areaincident from '../../Map/Layers/AreaIncident';
import MapScreen from '../../Map';

const { Title, Text } = Typography;

interface Props {
    record?: ISightings;
    t: any
    isMonitoring?: boolean;
    apiUrl: any;
}

export const SightingShowItem: React.FC<Props> = ({ record, isMonitoring = false, t, apiUrl }) => {
    const { Link } = useRouterContext();

    return (

        <Row gutter={[16, 16]} wrap>
            <Divider orientation="left" style={{ borderTopColor: 'rgba(0, 0, 0, 0.20)' }}>{t('phrases.sightingData')}</Divider>

            <Col span={12}>
                <Title level={5}>{t('sightings.fields.id')}</Title>
                <Text>{record?.id}</Text>
            </Col>
            <Col span={12}>
                <Title level={5}>{t('sightings.fields.monitoring')}</Title>
                <Text>
                    {
                        !isMonitoring &&
                        <Link to={`/monitorings/show/${record?.monitoring?.id}`}>
                            {`${t('monitorings.fields.id')}: ${record?.monitoring?.id} - Data: ${moment(record?.monitoring?.startDate).format('DD/MM/YYYY')}`}
                        </Link>
                    }

                    {
                        isMonitoring &&
                        `${t('monitorings.fields.id')}: ${record?.monitoring?.id} - Data: ${moment(record?.monitoring?.startDate).format('DD/MM/YYYY')}`
                    }
                </Text>
            </Col>

            <Col span={12}>
                <Title level={5}>{t('monitorings.fields.incident')}</Title>
                <Text>{record?.monitoring?.areaIncident.incident?.name}</Text>
            </Col>
            <Col span={12}>
                <Title level={5}>{t('monitorings.fields.area')}</Title>
                <Text>{record?.monitoring?.areaIncident?.areaName}</Text>
            </Col>
            <Col span={12}>
                <Title level={5}>{t('sightings.fields.date')}</Title>
                <Text><DateField value={record?.date} format="DD/MM/YYYY HH:mm"></DateField></Text>
            </Col>
            <Col span={12}>
                <Title level={5}>{t('monitorings.fields.responsible')}</Title>
                <Text>{record?.monitoring?.responsible?.name}</Text>
            </Col>

            {
                (record?.identifiedSpecies === true || record?.zooGroup !== 'other') &&
                <Col span={12}>
                    <Title level={5}>{t('sightings.fields.specie')}</Title>
                    <Text style={{ fontStyle: 'italic' }}>{record?.speciesIdentification || t(ZOOGROUP_CHOICES.find((e: any) => e.value === record?.zooGroup)?.label || '')}</Text>
                </Col>
            }

            {
                record?.zooGroup === 'other' &&
                <>
                    <Col span={12}>
                        <Title level={5}>{t('sightings.fields.zooGroup')}</Title>
                        <Text>{t(ZOOGROUP_CHOICES.find((e: any) => e.value === record?.zooGroup)?.label || '')}</Text>
                    </Col>
                    <Col span={12}>
                        <Title level={5}>{t('sightings.fields.speciesDescription')}</Title>
                        <Text>{record?.speciesDescription}</Text>
                    </Col>
                </>
            }

            <Col span={12}>
                <Title level={5}>{t('sightings.fields.quantity')}</Title>
                <Text>{record?.quantity}</Text>
            </Col>
            <Col span={12}>
                <Title level={5}>{t('sightings.fields.oiled')}</Title>
                <Text>{t(OILED_CHOICES.find((e: any) => e.value === record?.oiled)?.label || '')}</Text>
            </Col>
            <Col span={12}>
                <Title level={5}>{t('sightings.fields.condition')}</Title>
                <Text>{record?.condition === true ? t("words.alive") : t("words.dead")}</Text>
            </Col>
            <Col span={12}>
                <Title level={5}>{t('sightings.fields.coordinate')}</Title>
                <Text>{`${record?.coordinate?.latitude} / ${record?.coordinate?.longitude}`}</Text>
            </Col>
            {
                record?.condition === true &&
                <Col span={12}>
                    <Title level={5}>{t('sightings.fields.behavior')}</Title>
                    <Text>{record?.behavior?.map((e: any) => t(`phrases.${e}`) + "; ")}</Text>
                </Col>
            }

            {
                record?.images &&

                <Col span={24}>
                    <Card
                        title={t('sightings.fields.images')}
                        style={{ marginTop: 10, marginBottom: 10 }}>
                        <Image.PreviewGroup>
                            {record?.images?.map((img: any) => <Image style={{ padding: 5 }} key={img.id} width={200} src={apiUrl + img.url} />)}
                        </Image.PreviewGroup>
                    </Card>
                </Col>
            }

            {
                (!isMonitoring && record?.id) &&
                <Divider orientation="left" style={{ borderTopColor: 'rgba(0, 0, 0, 0.20)' }}>{t('words.map')}</Divider>
            }

            {
                (!isMonitoring && record?.id) &&

                <Col span={24} style={{ height: 600, width: '100%' }}>
                    <MapScreen
                        layersList={[
                            {
                                ...{ ...sighting }, ...{
                                    filterDefaultValues: [
                                        {
                                            field: "id",
                                            operator: "eq",
                                            value: record?.id,
                                        }
                                    ], loadOnInit: true, visible: true
                                }
                            },

                            {
                                ...{ ...areaincident }, ...{
                                    filterDefaultValues: [
                                        {
                                            field: "id",
                                            operator: "eq",
                                            value: record?.monitoring?.areaIncident.id,
                                        }
                                    ], loadOnInit: true, visible: true
                                }
                            },
                        ]}
                        showLayersMenu={false}
                        showClickLocation={false}
                        fitTo={{ lonLat: [record?.coordinate.longitude, record?.coordinate.latitude], zoom: 11 }}
                        initialZoom={undefined}
                        openFilterCallback={() => { }}
                        forceFilters={null}
                    />

                </Col>
            }

        </Row>
    );
};