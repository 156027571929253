import { useList, useTranslate } from "@pankod/refine-core";

import {
    Form,
    Row,
    Col,
    Select,
    Input,
    Radio,
    Checkbox,
    RadioChangeEvent,
    InputNumber,
} from "@pankod/refine-antd";

import {
    ITaxonomic,
    ISightings
} from "interfaces";

import { useEffect, useState } from "react";
const { Option } = Select;

import {
    OILED_CHOICES,
    ZOOGROUP_CHOICES
} from "helpers/constants";
import { FilesForm } from "components/form/files";

interface Props {
    name?: string | number;
    taxonomicList?: any;
    initialValues?: ISightings;
    form: any;
}

export const SightingsComponent: React.FC<Props> = ({ name = '', taxonomicList = [], initialValues = null, form }) => {
    const [isIdentified, setIsIdentified] = useState<boolean | null>(null);
    const [zooGroup, setZooGroup] = useState<string | null>(null);
    const [condition, setCondition] = useState<boolean | null>(null);
    const t = useTranslate();
    const taxonomics = taxonomicList || useList<ITaxonomic>({ resource: "taxonoamics" });

    useEffect(() => {
        if (initialValues && initialValues?.identifiedSpecies !== undefined) {
            setIsIdentified(initialValues?.identifiedSpecies);
        }

        if (initialValues && initialValues?.condition !== undefined) {
            setCondition(initialValues?.condition);
        }

        if (initialValues && initialValues?.zooGroup !== undefined) {
            setZooGroup(initialValues?.zooGroup);
        }
    }, [initialValues])

    return (
        <>
            <Row gutter={[8, 16]} wrap>
                <Col xs={24} lg={12}>
                    <Form.Item
                        label={t("sightings.fields.condition")}
                        name={[name, 'condition']}
                    >
                        <Radio.Group
                            onChange={(e: RadioChangeEvent) => setCondition(e.target.value)}
                        >
                            <Radio value={true}>{t("words.alive")}</Radio>
                            <Radio value={false}>{t("words.dead")}</Radio>
                        </Radio.Group>
                    </Form.Item>
                </Col>

                <Col xs={24} lg={12}>
                    <Form.Item
                        label={t("sightings.fields.date")}
                        name={[name, "date"]}
                        normalize={val => val === "" ? null : val}
                        rules={[
                            {
                                required: true,
                            },
                        ]}
                    >
                        <Input type="datetime-local" />
                    </Form.Item>
                </Col>
            </Row>

            <Row gutter={[8, 16]} wrap>

                <Col lg={24} xl={zooGroup === 'other' ? 8 : 12}>
                    <Form.Item
                        label={t("sightings.fields.identifiedSpecies")}
                        name={[name, "identifiedSpecies"]}
                        rules={[
                            {
                                required: true,
                            },
                        ]}
                    >
                        <Radio.Group onChange={(e: RadioChangeEvent) => setIsIdentified(e.target.value)}>
                            <Radio value={true}>{t("words.yes")}</Radio>
                            <Radio value={false}>{t("words.no")}</Radio>
                        </Radio.Group>
                    </Form.Item>
                </Col>

                {
                    isIdentified === true &&
                    <Col lg={24} xl={12}>
                        <Form.Item
                            label={t("sightings.fields.specie")}
                            name={[name, "specie"]}
                            rules={[
                                {
                                    required: true,
                                },
                            ]}
                        >
                            <Select
                                style={{fontStyle:'italic'}}
                                showSearch
                                filterOption={(input, option) =>
                                    (option!.children as unknown as string).toLowerCase().includes(input.toLowerCase())
                                }
                                onChange={(v) => {
                                    let taxon = taxonomics?.data?.data.find((el: any) => el['@id'] === v);
                                }}
                            >
                                {
                                    taxonomics?.data &&
                                    taxonomics.data.data.map((el: any) => <Option style={{fontStyle:'italic'}} key={el.id} value={el['@id']}>{el.specie}</Option>)
                                }
                            </Select>
                        </Form.Item>
                    </Col>
                }

                {
                    isIdentified === false &&
                    <Col xs={24} xl={zooGroup === 'other' ? 8 : 12}>
                        <Form.Item
                            name={[name, 'zooGroup']}
                            rules={[{ required: true }]}
                            label={t("sightings.fields.zooGroup")}
                        >
                            <Select showSearch
                                style={{fontStyle:(zooGroup !== 'other' ? 'italic' : 'normal')}}
                                onChange={v => setZooGroup(v)}
                                filterOption={(input, option) =>
                                    (option!.children as unknown as string).toLowerCase().includes(input.toLowerCase())
                                }
                            >
                                {
                                    ZOOGROUP_CHOICES.map(el => <Option style={{ fontStyle: (el.value !== 'other' ? 'italic' : 'normal') }} key={el.value} value={el.value}>{t(el.label)}</Option>)
                                }
                            </Select>
                        </Form.Item>
                    </Col>
                }

                {
                    zooGroup === 'other' &&
                    <Col lg={24} xl={8}>
                        <Form.Item
                            label={t("sightings.fields.speciesDescription")}
                            name={[name, "speciesDescription"]}
                            normalize={val => val === "" ? null : val}
                            rules={[
                                {
                                    required: true,
                                },
                            ]}
                        >
                            <Input />
                        </Form.Item>
                    </Col>
                }
            </Row>

            <Row gutter={[8, 16]} wrap>

                <Col xs={24} lg={12}>
                    <Form.Item
                        label={t("sightings.fields.latitude")}
                        name={[name, "coordinate", "latitude"]}
                        rules={[
                            {
                                required: true,
                            },
                        ]}
                    >
                        <Input type="number" />
                    </Form.Item>
                </Col>
                <Col xs={24} lg={12}>
                    <Form.Item
                        label={t("sightings.fields.longitude")}
                        name={[name, "coordinate", "longitude"]}
                        rules={[
                            {
                                required: true,
                            },
                        ]}
                    >
                        <Input type="number" />
                    </Form.Item>
                </Col>
            </Row>

            <Row gutter={[8, 16]} wrap>
                <Col xs={24} lg={12}>
                    <Form.Item
                        name={[name, 'quantity']}
                        rules={[{ required: true }]}
                        label={t("sightings.fields.quantity")}
                    >
                        <InputNumber style={{ width: "100%" }} />
                    </Form.Item>
                </Col>
                <Col xs={24} lg={12}>
                    <Form.Item
                        name={[name, 'oiled']}
                        rules={[{ required: true }]}
                        label={t("sightings.fields.oiled")}
                    >
                        <Select showSearch
                            filterOption={(input, option) =>
                                (option!.children as unknown as string).toLowerCase().includes(input.toLowerCase())
                            }
                        >
                            {
                                OILED_CHOICES.map(el => <Option key={el.value} value={el.value}>{t(el.label)}</Option>)
                            }
                        </Select>
                    </Form.Item>
                </Col>
            </Row>

            {
                condition === true &&
                <Row gutter={[8, 16]} wrap>
                    <Col xs={24} lg={24}>
                        <Form.Item
                            label={t("sightings.fields.behavior")}
                            name={[name, 'behavior']}
                        >

                            <Checkbox.Group style={{
                                display: "block",
                                marginRight: 0
                            }}>
                                <Row>
                                    <Col xs={24} lg={8} >
                                        <Row><Checkbox value={'ali'}>{t('phrases.ali')}</Checkbox></Row>
                                        <Row><Checkbox value={'voo'}>{t('phrases.voo')}</Checkbox></Row>
                                        <Row><Checkbox value={'pou'}>{t('phrases.pou')}</Checkbox></Row>
                                        <Row><Checkbox value={'des'}>{t('phrases.des')}</Checkbox></Row>
                                        <Row><Checkbox value={'rep'}>{t('phrases.rep')}</Checkbox></Row>
                                    </Col>

                                    <Col xs={24} lg={8}>
                                        <Row><Checkbox value={'bcc'}>{t('phrases.bcc')}</Checkbox></Row>
                                        <Row><Checkbox value={'bca'}>{t('phrases.bca')}</Checkbox></Row>
                                        <Row><Checkbox value={'der'}>{t('phrases.der')}</Checkbox></Row>
                                        <Row><Checkbox value={'del'}>{t('phrases.del')}</Checkbox></Row>
                                        <Row><Checkbox value={'bor'}>{t('phrases.bor')}</Checkbox></Row>
                                    </Col>

                                    <Col xs={24} lg={8}>
                                        <Row><Checkbox value={'sal'}>{t('phrases.sal')}</Checkbox></Row>
                                        <Row><Checkbox value={'cop'}>{t('phrases.cop')}</Checkbox></Row>
                                        <Row><Checkbox value={'ven'}>{t('phrases.ven')}</Checkbox></Row>
                                        <Row><Checkbox value={'inm'}>{t('phrases.inm')}</Checkbox></Row>
                                        <Row><Checkbox value={'inr'}>{t('phrases.inr')}</Checkbox></Row>
                                    </Col>
                                </Row>
                            </Checkbox.Group>
                        </Form.Item>
                    </Col>
                </Row>
            }

            <FilesForm form={form} name={[name, "images"]} accept=".jpg,.png" maxFiles={200} label="sightings.fields.images" listType="picture" />

        </>
    );
}