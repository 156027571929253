import { useList, useTranslate } from "@pankod/refine-core";

import {
    Form,
    FormProps,
    Row,
    Col,
    Button,
    Select,
    Input,
    Collapse
} from "@pankod/refine-antd";
import { MONITORING_STRATEGIES, AREA_TYPES, ZOOGROUP_CHOICES } from "../../../helpers/constants";
import { useEffect, useState } from "react";
import { getChartFilterConfiguration } from "helpers/functions";
import { IAreaIncident, IIncident } from "interfaces";

const { Option } = Select;
const { Panel } = Collapse;

export interface GlobalFilterFormProps extends FormProps {
    applyglobalfiltercallback?: (values?: any) => void;
}

export const GlobalFilter: React.FC<{ formProps: GlobalFilterFormProps }> = (props) => {
    const t = useTranslate();
    const [areas, setAreas] = useState([]);

    const incidents = useList<IIncident>({
        resource: "incidents", config: {}
    });

    useEffect(() => {
        props.formProps.form?.resetFields();
        const filters = getChartFilterConfiguration('global');
        const values: any = {};

        if (filters) {
            for (let i in filters) {
                values[filters[i].field] = filters[i].value;

                if (filters[i].field === 'incident') {
                    updateAreaList(filters[i].value);
                }
            }
        }

        props.formProps.form?.setFieldsValue(values);
    }, [props])

    const onSubmit = (e: any) => {
        let values = props.formProps.form?.getFieldsValue() || {};
        props.formProps?.applyglobalfiltercallback ? props.formProps.applyglobalfiltercallback(values) : null;
    }

    const updateAreaList = (v: any) => {
        let list: any = [];
        const incident = incidents?.data?.data.find((el: any) => el['@id'] === v);
        incident?.areas.map((el: any) => list.push(el));
        setAreas(list);
    }

    return (
        <Collapse style={{ marginBottom: 20 }} >
            <Panel header="Filtros" key="1">
                <Form onSubmitCapture={onSubmit} layout="vertical" form={props.formProps.form} style={{ padding: 15 }}>
                    <Row gutter={[10, 0]} align="bottom">
                        <Col xs={24} xl={24} md={12}>
                            <Row gutter={[8, 16]} wrap>
                                <Col xs={24} lg={12}>
                                    <Form.Item
                                        label={t("monitorings.fields.incident")}
                                        name="incident"
                                    >
                                        <Select
                                            showSearch
                                            filterOption={(input, option) =>
                                                (option!.children as unknown as string).toLowerCase().includes(input.toLowerCase())
                                            }
                                            onChange={(v) => {
                                                updateAreaList(v);
                                            }}
                                            allowClear
                                        >
                                            {
                                                incidents?.data &&
                                                incidents?.data.data.map((el: any) => <Option key={el.id} value={el['@id']}>{el.name}</Option>)
                                            }
                                        </Select>
                                    </Form.Item>
                                </Col>

                                <Col xs={24} lg={12}>
                                    <Form.Item
                                        label={t("monitorings.fields.area")}
                                        name="area"
                                    >
                                        <Select
                                            showSearch
                                            allowClear
                                            filterOption={(input, option) =>
                                                (option!.children as unknown as string).toLowerCase().includes(input.toLowerCase())
                                            }
                                        >
                                            {
                                                areas &&
                                                areas.map((el: any) => <Option key={el.id} value={el['@id']}>{el.area.name}</Option>)
                                            }
                                        </Select>
                                    </Form.Item>
                                </Col>
                            </Row>

                        </Col>


                        <Form.Item>
                            <Button
                                style={{ width: "100%" }}
                                htmlType="submit"
                                type="primary"
                            >
                                {t("words.filter")}
                            </Button>
                        </Form.Item>

                    </Row>
                </Form>
            </Panel>
        </Collapse>
    );
};