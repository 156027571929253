import { AuthPage as AntdAuthPage, FormProps } from "@pankod/refine-antd";
import { useRouterContext, useTranslate } from "@pankod/refine-core";
import { url } from "inspector";
import background from "../../styles/praia.jpg"
import mineral from "../../styles/logo-mineral-horiz.png"

const authWrapperProps = {
    style: {
        background: `radial-gradient(50% 50% at 50% 50%,rgba(255, 255, 255, 0) 0%,rgba(0, 0, 0, 0.5) 100%),url(${background})`,
        backgroundSize: "cover",
    },
};

const renderAuthContent = (content: React.ReactNode) => {
    const { Link } = useRouterContext();
    const t = useTranslate();

    return (
        <div
            style={{
                maxWidth: 408,
                margin: "auto",
            }}
        >
            <Link to="/">
                <h1 className="ant-typography" style={{ width: '100%', textAlign: 'center', color: 'rgb(156 195 203)' }}>{t('phrases.appName')}</h1>
            </Link>
            {content}
            <div style={{ width: "100%", textAlign: 'center', color:"#000000" }}>
                <img src={mineral} style={{ maxWidth: 250, marginTop: 50, marginBottom: 20 }}></img><br />
                {t('phrases.copyright')} {new Date().getFullYear()}
            </div>
        </div>
    );
};

export const AuthPage: React.FC<{
    type?: "login" | "register" | "forgotPassword" | "updatePassword";
    formProps?: FormProps;
}> = ({ type, formProps }) => {

    return (
        <AntdAuthPage
            type={type}
            wrapperProps={authWrapperProps}
            renderContent={renderAuthContent}
            formProps={formProps}
            registerLink={<></>}
            rememberMe={<></>}
        />
    );
};
