import React, { useCallback, useState, useEffect, createElement } from "react";
import { fromLonLat, toLonLat } from "ol/proj";
import { Point, Polygon } from "ol/geom";
import "ol/ol.css";
import PropTypes from 'prop-types';
import { RMap, ROSM, RLayerVector, RFeature, RPopup, RControl } from "rlayers";
import { RStyle, RIcon } from "rlayers/style";
import GeoJSON from 'ol/format/GeoJSON';

import { mapStyles } from "../helpers/styles";
import MapMenu from "./Menu";
import { LAYERS } from "./defaultLayers";
import { Constants } from "../helpers/constants";
import pinYellow from './svg/pinYellow.svg';
import pinGreen from './svg/pinGreen.svg';
import pinRed from './svg/pinRed.svg';
import BlockUi from 'react-block-ui';
import 'react-block-ui/style.css';
import "rlayers/control/layers.css";
import { makeStyles } from '@material-ui/styles';
import { Style, Stroke } from 'ol/style';
import { useApiUrl, useCustomMutation, useTranslate } from "@pankod/refine-core";
import { Card } from "@pankod/refine-antd";
import bird from "../styles/40261.svg";
import turtle from "../styles/turtle.svg";
import dolphin from "../styles/489372.svg";
import pin from "../styles/308802.svg";
import compass from "../styles/minimal-compass.svg";


const origin = [0, 0];
const initial = { center: fromLonLat(origin), zoom: 0 };

const FeaturePoint = ({ item, popup, layerIcon, loaderCallback }) => {
  return <RFeature
    geometry={new Point(fromLonLat(item.geometry.coordinates))}>
    <RStyle width={10}>
      <RIcon src={layerIcon} anchor={[0.5, 0.45]} />
    </RStyle>
    {
      popup &&

      <RPopup trigger={"click"}>
        <Card style={{ minWidth: 350 }}>
          {createElement(popup, { record: item.properties.data, loaderCallback: loaderCallback })}
        </Card>
      </RPopup>
    }

  </RFeature>
}

const FeaturePointSighting = ({ item, popup, loaderCallback }) => {
  const class_txt = item?.properties?.data?.text_class || item?.properties?.data?.zoo_group || "other";
  let icon = "";

  switch (class_txt) {
    case "Aves":
    case "aves":
      icon = bird;
      break;
    case "Mammalia":
    case "mammalia":
      icon = dolphin;
      break;
    case "Reptilia":
    case "reptilia":
      icon = turtle;
      break;
    default:
      icon = pin;
      break;
  }

  return <RFeature
    geometry={new Point(fromLonLat(item.geometry.coordinates))}>
    <RStyle width={10}>
      <RIcon src={icon} anchor={[0.5, 0.45]} />
    </RStyle>
    {
      popup &&

      <RPopup trigger={"click"}>
        <Card style={{ minWidth: 350 }}>
          {createElement(popup, { record: item.properties.data, loaderCallback: loaderCallback })}
        </Card>
      </RPopup>
    }

  </RFeature>
}

const FeaturePointMonitoring = ({ item, popup, loaderCallback }) => {
  let layerIcon = pinYellow;
  const data = item.properties.data;
  const status = data.status;
  const t = useTranslate();


  if (status === 'initiated') {
    layerIcon = pinGreen;
  } else if (status === 'finished') {
    layerIcon = pinRed;
  }

  /*
  <ROverlay className="example-overlay">
        <Card style={{ padding: 5 }}>
          {t(`words.${status}`)}
        </Card>
      </ROverlay>
  
  */


  return <RFeature
    geometry={new Point(fromLonLat(item.geometry.coordinates))}>
    <RStyle width={10}>
      <RIcon src={layerIcon} anchor={[0.5, 0.45]} />
    </RStyle>
    {
      ((status === 'finished' || status === 'initiated') && popup) &&
      <RPopup trigger={"click"}>
        <Card sx={{ minWidth: 500, marginTop: 2 }}>
          {createElement(popup, { record: data, loaderCallback: loaderCallback })}
        </Card>
      </RPopup>
    }
  </RFeature>
}

const FeatureMultiLineString = ({ item }) => {
  return <></>
  return <RLayerVector
    style={mapStyles.line}
    zIndex={10}
    features={new GeoJSON({ featureProjection: 'EPSG:3857' }).readFeatures(
      item
    )}
  >
  </RLayerVector>
}

const FeatureLine = ({ item }) => {

  let style = {
    color: '#2992C4',
    width: 3,
  }

  if (item.properties && item.properties.style) {
    style = item.properties.style;
  }

  return <RLayerVector
    style={new Style({
      stroke: new Stroke(style)
    })}
    zIndex={10}
    features={new GeoJSON({ featureProjection: 'EPSG:3857' }).readFeatures(
      item
    )}
  >
  </RLayerVector>
}

const FeaturePolygon = ({ item, popup }) => {

  let coordinates = [];
  const data = item.properties.data;

  item.geometry.coordinates.map((items, i) => {
    items.map(item => { coordinates.push(fromLonLat(item)) })
  })

  return <RLayerVector
    style={mapStyles.green}
    zIndex={10}
  >

    <RFeature geometry={new Polygon([coordinates])}>

      <RPopup trigger={"click"}>
        <Card sx={{ minWidth: 500, marginTop: 2 }}>
          {createElement(popup, { record: data })}
        </Card>
      </RPopup>
    </RFeature>
  </RLayerVector>
}

const useStyles = makeStyles(theme => ({
  layersControl: { right: 0 },
  compassControl: {
    left: 50,
    top: 5,
    backgroundColor: 'transparent'
  },
  rotateControl: {
    top:275,
    left: 7
  },
}));



const MapScreen = ({
  layersList,
  showLayersMenu,
  fitTo,
  initialZoom,
  showClickLocation,
  openFilterCallback,
  forceFilters,
  ...props
}) => {

  const [loc, setLoc] = useState(origin);
  const [view, setView] = useState(initial);
  const [layers, setLayers] = useState([]);
  const [blocking, setBlocking] = useState(false);
  const [rotation, setRotation] = useState(0);
  const t = useTranslate();
  const classes = useStyles();
  const apiUrl = useApiUrl();
  const { mutate } = useCustomMutation();

  useEffect(() => {
    if (layers && layers.length > 0) {

      for (let i in layers) {
        const layer = layers[i];

        if (layer.loadOnInit === true && !layer.data) {
          requestData(layer.id, layer.filterDefaultValues);
        }
      }

    }
  }, [layers])

  useEffect(() => {
    if (forceFilters && forceFilters?.module && forceFilters?.filters) {
      if (layers && layers.length > 0) {

        for (let i in layers) {
          if (layers[i].id === forceFilters.module) {
            layers[i].filterDefaultValues = forceFilters?.filters;
            requestData(layers[i].id, layers[i].filterDefaultValues);
          }
        }

        setLayers([...layers]);
      }
    }
  }, [forceFilters])

  useEffect(() => {
    if (fitTo) {
      setView({ center: fromLonLat(fitTo.lonLat), zoom: fitTo.zoom })
    }
  }, [fitTo])


  const applyFilter = async (module, values) => {
    sessionStorage.setItem(Constants.FILTER_MAP_KEY + module, JSON.stringify(values));
    requestData(module, values);
  }

  const changeLayerVisibility = (module, isVisible) => {
    let defaultFilter = null;

    for (let i in layers) {
      if (layers[i].id === module) {
        layers[i].visible = isVisible;
        defaultFilter = layers[i].filterDefaultValues;
      }
    }

    setLayers([...layers]);

    if (isVisible) {
      const storage = JSON.parse(sessionStorage.getItem(Constants.FILTER_MAP_KEY + module));
      const filter = storage ? storage : defaultFilter;
      requestData(module, filter)
    }
  }

  const changeLayerPosition = (layersList) => {

  }

  const requestData = async (module, filter = null) => {
    setBlocking(true);

    mutate({
      url: `${apiUrl}/api/${module}/map/layer/data`,
      method: "get",
      values: {
        filters: filter
      }
    },
      {
        onSuccess: (data, variables, context) => {

          for (let i in layers) {
            if (layers[i].id === module) {
              layers[i] = { ...layers[i], data: data.data, ...{ filterDefaultValues: filter } };
            }
          }

          setLayers([...layers]);
          setBlocking(false);
        },

        onError: (error, variables) => {
          setBlocking(false);
        }
      });
  }

  useEffect(() => {
    if (layersList && layersList.length === 0) {
      setLayers(LAYERS);
    } else {
      setLayers(layersList);
    }
  }, [layersList])

  const loaderAction = (isLoading) => {
    setBlocking(isLoading);
  }

  return (
    <React.Fragment>
      <BlockUi tag="div" blocking={blocking} style={{ height: '96%', width: '100%' }} message={t('phrases.mapLoadingMessage')}>
        <RMap
          className="example-map"
          initial={{ center: fitTo ? fromLonLat(fitTo) : fromLonLat(origin), zoom: initialZoom }}
          width={"100%"} height={"100%"}
          view={[view, setView]}
          noDefaultControls={true}
          ref={props.mapRef}
          onClick={useCallback((e) => {
            const coords = e.map.getCoordinateFromPixel(e.pixel);
            const lonlat = toLonLat(coords);
            setLoc(lonlat);
          }, [])}
          onMoveEnd={(e) => setRotation(e.map.getView().getRotation())}
          
        >
          <ROSM />

          <RControl.RScaleLine />
          <RControl.RAttribution />
          <RControl.RZoom />
          <RControl.RZoomSlider />
          <RControl.RRotate autoHide={false} className={classes.rotateControl} />

          {
            showLayersMenu &&

            <RControl.RCustom className={classes.layersControl}>
              <MapMenu layers={layers} applyFilterCallback={applyFilter} handleLayerCallback={changeLayerVisibility} dragLayerCallback={changeLayerPosition} handleFilterCallBack={openFilterCallback} ></MapMenu>
            </RControl.RCustom>
          }

          <RControl.RCustom className={classes.compassControl}>
            <img src={compass} style={{rotate:`${rotation}rad`}}></img>
          </RControl.RCustom>

          {
            layers && layers.map((el, i) => {

              if (!el.data || !el.visible)
                return null;

              return <RLayerVector onClick={(el) => {
              }} key={i} zIndex={10} style={mapStyles.iconGreen}>



                {
                  el.data.features.map((item, j) => {
                    if (el.id === 'monitoring') {
                      if (item.geometry.type === 'Point') {
                        if (item?.properties?.data?.status) {
                          return <FeaturePointMonitoring key={j} item={item} popup={el.popup} loaderCallback={loaderAction} />
                        } else if (el.showSighting === true) {
                          return <FeaturePointSighting key={j} item={item} popup={el.sightingPopup} loaderCallback={loaderAction} />
                        }
                      } else if (item.geometry.type === 'MultiLineString' || item.geometry.type === "LineString") {
                        return <FeatureLine key={j} item={item} popup={el.popup} />
                      } else {
                        return null;
                      }
                    } else {

                      if (item.geometry.type === 'Polygon') {
                        return <FeaturePolygon key={j} item={item} popup={el.popup} />
                      } else if (item.geometry.type === 'LineString') {
                        return <FeatureLine key={j} item={item} popup={el.popup} />
                      } else {
                        if (el.id === 'sighting') {
                          return <FeaturePointSighting key={j} item={item} popup={el.sightingPopup} loaderCallback={loaderAction} />
                        } else {
                          return <FeaturePoint layerIcon={el.layerIcon} key={j} item={item} popup={el.popup} loaderCallback={loaderAction} />
                        }
                      }

                    }

                  })

                }

              </RLayerVector>
            })
          }


          {/*
          layers && layers.map((el, i) => {

            if (!el.data)
              return null


            return <RLayerVector
              key={i}
              zIndex={10}
              features={new GeoJSON({ featureProjection: 'EPSG:3857' }).readFeatures(
                el.data
              )}
            >

            </RLayerVector>
          })
        */}

        </RMap>

        {
          showClickLocation &&
          <div className="mx-0 mt-0 mb-3 p-1 w-100 jumbotron shadow">
            <p>
              {t('app.text.last_click_location')}{" "}
              <strong>{`${loc[1].toFixed(3)} : ${loc[0].toFixed(3)}`}</strong>
            </p>
          </div>
        }

      </BlockUi>

    </React.Fragment>
  );
}

MapScreen.propTypes = {
  layersList: PropTypes.array,
  showLayersMenu: PropTypes.bool,
  fitTo: PropTypes.object,
  initialZoom: PropTypes.number,
  showClickLocation: PropTypes.bool,
  openFilterCallback: PropTypes.func,
  forceFilters: PropTypes.any,
}

MapScreen.defaultProps = {
  layersList: [],
  showLayersMenu: true,
  fitTo: null,
  initalZoom: 13,
  showClickLocation: true,
  openFilterCallback: () => { },
  forceFilters: null,
}

export default MapScreen;