import PropTypes from 'prop-types';
import { Divider, Typography, Row, Col } from "@pankod/refine-antd";
import { useTranslate } from '@pankod/refine-core';
import { MONITORING_STRATEGIES, MONITORING_TYPES } from 'helpers/constants';
const { Title, Text } = Typography;

const Popup = ({ record, showDataCallback, ...props }) => {

    const t = useTranslate();

    if (!record)
        return <></>

    return (
        <Row style={{ maxWidth: 350 }}>
            <Divider orientation="left" style={{ borderTopColor: 'rgba(0, 0, 0, 0.20)' }}>{t('phrases.areaData')}</Divider>

            <Row gutter={[16, 16]} wrap>
                <Col span={12}>
                    <Title level={5}>{t('area_incidents.fields.id')}</Title>
                    <Text>{record?.id}</Text>
                </Col>

                <Col span={12}>
                    <Title level={5}>{t('area_incidents.fields.project')}</Title>
                    <Text>{record?.project}</Text>
                </Col>

                <Col span={12}>
                    <Title level={5}>{t('area_incidents.fields.incident')}</Title>
                    <Text>{record?.incident}</Text>
                </Col>

                <Col span={12}>
                    <Title level={5}>{t('area_incidents.fields.area')}</Title>
                    <Text>{record?.area}</Text>
                </Col>

                <Col span={12}>
                    <Title level={5}>{t('area_incidents.fields.type')}</Title>
                    <Text>{t(`words.${record?.type}`)}</Text>
                </Col>

                {
                    record?.type !== 'offshore' &&
                    <>
                        <Col span={12}>
                            <Title level={5}>{t('area_incidents.fields.state')}</Title>
                            <Text>{record?.state}</Text>
                        </Col>
                        <Col span={12}>
                            <Title level={5}>{t('area_incidents.fields.city')}</Title>
                            <Text>{record?.city}</Text>
                        </Col>
                    </>
                }

                {
                    record?.area?.type === 'offshore' &&

                    <Col span={12}>
                        <Title level={5}>{t('area_incidents.fields.country')}</Title>
                        <Text>{record?.area?.country?.name}</Text>
                    </Col>
                }

            </Row>
        </Row>
    )

}

Popup.propTypes = {
    record: PropTypes.object,
    showDataCallback: PropTypes.func,
}

Popup.defaultProps = {
    record: null,
    showDataCallback: () => { }
}

export default Popup;