import {
    useTranslate,
    IResourceComponentsProps,
    useNavigation,
} from "@pankod/refine-core";

import {
    List,
    Table,
    useTable,
    Dropdown,
    Menu,
    Icons,
} from "@pankod/refine-antd";

import { IArea } from "interfaces";
import { getFooterInfo } from "helpers/functions";

const { FormOutlined, EyeFilled } = Icons;

export const AreaList: React.FC<IResourceComponentsProps> = () => {
    const { tableProps } = useTable<IArea>();
    const { edit, show } = useNavigation();
    const t = useTranslate();

    const moreMenu = (id: number) => (
        <Menu mode="vertical">
            <Menu.Item
                key="1"
                style={{
                    fontSize: 15,
                    fontWeight: 500,
                }}
                icon={
                    <FormOutlined
                        style={{ color: "green", fontSize: "15px" }}
                    />
                }
                onClick={() => edit("areas", id)}
            >
                {t("buttons.edit")}
            </Menu.Item>

            <Menu.Item
                key="1"
                style={{
                    fontSize: 15,
                    fontWeight: 500,
                }}
                icon={
                    <EyeFilled
                        style={{ color: "green", fontSize: "15px" }}
                    />
                }
                onClick={() => show("areas", id)}
            >
                {t("buttons.show")}
            </Menu.Item>
        </Menu>
    );

    return (
        <>
            <List>
                <Table {...tableProps} rowKey="id" footer={() => getFooterInfo(tableProps.pagination)}>
                    <Table.Column
                        dataIndex="id"
                        align="center"
                        title={t("areas.fields.id")}
                    />

                    <Table.Column
                        dataIndex="name"                        
                        title={t("areas.fields.name")}
                    />

                    <Table.Column
                        dataIndex={['state', 'name']}
                        title={t("areas.fields.state")}
                    />

                    <Table.Column
                        dataIndex={['city', 'name']}
                        title={t("areas.fields.city")}
                    />

                    <Table.Column
                        dataIndex="enabled"
                        title={t("areas.fields.enabled")}
                        render={((value: boolean) => value === true ? t('words.yes') : t('words.no'))}
                    />                    

                    <Table.Column<IArea>
                        fixed="right"
                        title={t("table.actions")}
                        dataIndex="actions"
                        key="actions"
                        align="center"
                        render={(_, record) => (
                            <Dropdown
                                overlay={moreMenu(record.id)}
                                trigger={["click"]}
                            >
                                <Icons.MoreOutlined
                                    style={{
                                        fontSize: 24,
                                    }}
                                />
                            </Dropdown>
                        )}
                    />
                </Table>
            </List>

        </>
    );
};
