import { HttpError, IResourceComponentsProps, useList, useTranslate } from "@pankod/refine-core";

import {
    Create,
    Form,
    useForm,
    Row,
    Col,
    Edit,
    Select,
    Input,
    Radio,
} from "@pankod/refine-antd";

import { IState, ICity, IArea } from "interfaces";
import { useFromErrorsProviver } from '../../helpers/formErrorsProvider'
import { useEffect, useState } from "react";
const { Option } = Select;
import { FilesForm } from "components/form/files";

const FormComponent = (props: any) => {
    const t = useTranslate();
    const { formProps } = props;
    const { initialValues } = formProps;
    const [stateFilter, setStateFilter] = useState<any>(0);

    const stateListQueryResult = useList<IState>({ resource: "states" });
    const cityListQueryResult = useList<ICity>({ resource: "cities", config: { filters: [{
        field: "state",
        value: stateFilter,
        operator: "eq"
    }] }});

    useEffect(() => {
        if (initialValues?.state && stateFilter === 0) {
            setStateFilter(initialValues.state);
        }
    }, [initialValues])

    const getCities = (value: any) => {
        if (!value)
            return;

        setStateFilter(value);
    }

    return <Form
        {...formProps}
        layout="vertical"
        initialValues={{
            name: initialValues?.name,
            state: initialValues?.state ? initialValues.state['@id'] : null,
            city: initialValues?.city ? initialValues?.city['@id'] : null,
            files: initialValues?.files,
            shpFile: initialValues?.shpFile,
            shxFile: initialValues?.shxFile,
            dbfFile: initialValues?.dbfFile
        }}
    >
        <Row gutter={[64, 0]} wrap>
            <Col xs={24} lg={{span:8, offset:8}}>
                <Form.Item
                    label={t("areas.fields.name")}
                    name="name"
                    rules={[
                        {
                            required: true,
                        },
                    ]}
                >
                    <Input />
                </Form.Item>

                <Form.Item
                    label={t("areas.fields.state")}
                    name="state"
                    rules={[
                        {
                            required: true,
                        },
                    ]}
                >
                    <Select onSelect={getCities}
                        showSearch
                        filterOption={(input, option) =>
                            (option!.children as unknown as string).toLowerCase().includes(input.toLowerCase())
                        }
                    >
                        {
                            stateListQueryResult?.data &&
                            stateListQueryResult.data.data.map((el: any) => <Option key={el.id} value={el['@id']}>{el.name}</Option>)
                        }
                    </Select>
                </Form.Item>

                <Form.Item
                    label={t("areas.fields.city")}
                    name="city"
                    rules={[
                        {
                            required: true,
                        },
                    ]}
                >
                    <Select
                        showSearch
                        filterOption={(input, option) =>
                            (option!.children as unknown as string).toLowerCase().includes(input.toLowerCase())
                        }
                    >
                        {
                            cityListQueryResult?.data &&
                            cityListQueryResult.data.data.map((el: any) => <Option key={el.id} value={el['@id']}>{el.name}</Option>)
                        }
                    </Select>
                </Form.Item>

                <FilesForm form={formProps.form} name="files" accept=".jpg,.png" maxFiles={10} label="products.fields.images.label" listType="picture"/>
                <FilesForm form={formProps.form} name="shpFile" accept=".shp" maxFiles={1} label="areas.fields.shpFile"/>
                <FilesForm form={formProps.form} name="shxFile" accept=".shx" maxFiles={1} label="areas.fields.shxFile"/>
                <FilesForm form={formProps.form} name="dbfFile" accept=".dbf" maxFiles={1} label="areas.fields.dbfFile"/>

            </Col>
        </Row>
    </Form>


}

export const AreaForm: React.FC<IResourceComponentsProps> = (props: any) => {

    const { formProps, form, saveButtonProps, queryResult, id } = useForm<IArea>({
        onMutationError: (httpError: HttpError) => {
            form?.setFields(useFromErrorsProviver(httpError));
        }
    });

    saveButtonProps.size = 'large';

    if (id) {
        return <Edit
            isLoading={queryResult?.isFetching}
            saveButtonProps={saveButtonProps}
            recordItemId={id}
        >
            <FormComponent formProps={formProps} />
        </Edit>
    }

    return (
        <Create
            isLoading={queryResult?.isFetching}
            saveButtonProps={saveButtonProps}
        >
            <FormComponent formProps={formProps} />
        </Create>
    );
};