import { useList, useTranslate } from "@pankod/refine-core";

import {
    Form,
    FormProps,
    Input,
    Row,
    Col,
    Button,    
} from "@pankod/refine-antd";


export interface FilterFormProps extends FormProps {
    applyFilterCallback?: (values?:any) => void;    
}

export const Filter: React.FC<{ formProps: FilterFormProps }> = (props) => {
    const t = useTranslate();
    
    const onSubmit = (e: any) => {
        props.formProps.applyFilterCallback ? props.formProps.applyFilterCallback(props.formProps.form?.getFieldsValue()) : null;
    }

    return (

        <Form onSubmitCapture={onSubmit} layout="vertical" {...props.formProps} style={{ padding: 15 }}>
            <Row gutter={[10, 0]} align="bottom">
       
                <Col xs={24} xl={24} md={12}>
                    <Form.Item label={t("project_collaborators.fields.name")} name={["user", "name"]}>
                        <Input
                            placeholder={t("project_collaborators.fields.name")}
                        />
                    </Form.Item>
                </Col>

                <Col xs={24} xl={24} md={12}>
                    <Form.Item label={t("project_collaborators.fields.email")} name={["user", "email"]}>
                        <Input
                            placeholder={t("project_collaborators.fields.email")}
                        />
                    </Form.Item>
                </Col>

                <Col xs={24} xl={24} md={8}>
                    <Form.Item>
                        <Button
                            style={{ width: "100%" }}
                            htmlType="submit"
                            type="primary"
                        >
                            {t("words.filter")}
                        </Button>
                    </Form.Item>
                </Col>
            </Row>
        </Form>
    );
};