import { useShow, useTranslate } from "@pankod/refine-core";
import { Show, Typography, TagField, Row, Col } from "@pankod/refine-antd";
import { IAreaIncident } from "interfaces";
const { Title, Text } = Typography;

export const AreaIncidentShow: React.FC = () => {
    const { queryResult } = useShow<IAreaIncident>();
    const { data, isLoading } = queryResult;
    const record = data?.data;
    const t = useTranslate();

    return (
        <Show isLoading={isLoading}>

            <Row gutter={[16, 16]} wrap>
                <Col span={12}>
                    <Title level={5} style={{ marginTop: 10 }}>{t('area_incidents.fields.id')}</Title>
                    <Text>{record?.id}</Text>
                </Col>
                <Col span={12}>
                    <Title level={5} style={{ marginTop: 10 }}>{t('area_incidents.fields.project')}</Title>
                    <Text>{record?.incident?.project?.name}</Text>
                </Col>
                <Col span={12}>
                    <Title level={5} style={{ marginTop: 10 }}>{t('area_incidents.fields.incident')}</Title>
                    <Text>{record?.incident?.name}</Text>
                </Col>
            
                <Col span={12}>
                    <Title level={5} style={{ marginTop: 10 }}>{t('area_incidents.fields.area')}</Title>
                    <Text>{record?.area?.name}</Text>
                </Col>
                <Col span={12}>
                    <Title level={5} style={{ marginTop: 10 }}>{t('area_incidents.fields.type')}</Title>
                    <Text>{t(`words.${record?.area?.type}`)}</Text>
                </Col>

            </Row>

            {
                record?.area?.type !== 'offshore' &&

                <Row gutter={[16, 16]} wrap>
                    <Col span={12}>
                        <Title level={5} style={{ marginTop: 10 }}>{t('area_incidents.fields.state')}</Title>
                        <Text>{record?.area?.state?.name}</Text>
                    </Col>
                    <Col span={12}>
                        <Title level={5} style={{ marginTop: 10 }}>{t('area_incidents.fields.city')}</Title>
                        <Text>{record?.area?.city?.name}</Text>
                    </Col>
                </Row>
            }

            {
                record?.area?.type === 'offshore' &&

                <Row gutter={[16, 16]} wrap>
                    <Col span={12}>
                        <Title level={5} style={{ marginTop: 10 }}>{t('area_incidents.fields.country')}</Title>
                        <Text>{record?.area?.country?.name}</Text>
                    </Col>
                </Row>
            }

            {/*<Title level={5} style={{marginTop:10}}>{t('area_incidents.fields.enabled')}</Title>
            <Text>{record?.area?.enabled === true ? t('words.yes') : t('words.no')}</Text>*/}
        </Show>
    );
};