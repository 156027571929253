import {
    useTranslate,
    IResourceComponentsProps,
} from "@pankod/refine-core";

import {
    List,
    Table,
    useTable,
    Dropdown,
    Icons,
    Typography
} from "@pankod/refine-antd";

import { ITaxonomic } from "interfaces";
import {MoreMenu} from "../../components/list/MoreMenu"
import { getFooterInfo } from "helpers/functions";

const {Text} = Typography;

export const TaxonomicList: React.FC<IResourceComponentsProps> = () => {
    const { tableProps } = useTable<ITaxonomic>();
    const t = useTranslate();   

    return (
        <List>
            <Table {...tableProps} rowKey="id" footer={() => getFooterInfo(tableProps.pagination)}>
                <Table.Column
                    dataIndex="id"
                    align="center"
                    title={t("taxonomics.fields.id")}
                />
                <Table.Column
                    dataIndex="name"
                    title={t("taxonomics.fields.name")}
                />
                <Table.Column
                    dataIndex="class"
                    title={t("taxonomics.fields.class")}
                />
                <Table.Column
                    dataIndex="order"
                    title={t("taxonomics.fields.order")}
                />
                <Table.Column
                    dataIndex="suborder"
                    title={t("taxonomics.fields.suborder")}
                />
                <Table.Column
                    dataIndex="family"
                    title={t("taxonomics.fields.family")}
                />
                <Table.Column
                    dataIndex="genus"
                    title={t("taxonomics.fields.genus")}
                />
                <Table.Column
                    dataIndex="specie"
                    title={t("taxonomics.fields.specie")}
                    render={value => <Text style={{fontStyle:'italic'}}>{value}</Text>}
                />

                <Table.Column<ITaxonomic>
                    fixed="right"
                    title={t("table.actions")}
                    dataIndex="actions"
                    key="actions"
                    align="center"
                    render={(_, record) => (
                        <Dropdown
                            overlay={<MoreMenu canDelete={true} id={record.id} resource={'taxonomics'} />}
                            trigger={["click"]}
                        >
                            <Icons.MoreOutlined
                                style={{
                                    fontSize: 24,
                                }}
                            />
                        </Dropdown>
                    )}
                />
            </Table>
        </List>
    );
};