import moment from 'moment';
import { Constants } from './constants';
var set = require('lodash.set');

export const setObjectValue = (object, key, value) => {
    let k = Array.isArray(key) ? k = key.join(".") : key;
    set(object, k, value);
}

export const createQueryFilters = (queryFilters) => {
    let and = '';
    let queryFilter = '';

    for (const [key, value] of Object.entries(queryFilters)) {
        if (value === "" || value === undefined || value === null) {
            continue;
        }

        const keys = key.split('_');

        if (keys.length === 2) {

            if (moment(value).isValid() && keys[1] === 'gte') {
                queryFilter += `${and}${keys[0]}[after]=${moment(value).format('YYYY-MM-DD')} 00:00:00`;
            } else if (moment(value).isValid() && keys[1] === 'lte') {
                queryFilter += `${and}${keys[0]}[before]=${moment(value).format('YYYY-MM-DD')} 23:59:59`;
            } else if (keys[1] === 'like') {
                queryFilter += `${and}${keys[0]}=${value}`;
            } else {
                queryFilter += `${and}${keys[0]}[${keys[1]}]=${value}`;
            }

        } else {
            queryFilter += and + key + "=" + value;
        }

        and = "&";
    }

    return queryFilter;
}

export const getFooterInfo = (pagination) => {

    const total = pagination.total;
    const current = (pagination.current * pagination.pageSize) - pagination.pageSize + 1 > total ? total : (pagination.current * pagination.pageSize) - pagination.pageSize + 1;
    const max = (pagination.current * pagination.pageSize) > total ? total : (pagination.current * pagination.pageSize);

    return total ? `${current} - ${max} de ${total}` : '';
}

export const getCurrentProject = () => {
    try {
        const json = JSON.parse(localStorage.getItem(Constants.CURRENT_PROJECT_KEY) || '');
        return json?.id || 0;
    } catch (error) { }

    return 0;
}

export const getChartFilterConfiguration = (filter) => {
    try {
        const json = JSON.parse(localStorage.getItem(Constants.AUTH_KEY) || '');
        
        if (json?.configurations) {

            if (filter) {
                return json?.configurations?.chartFilters[filter];
            } else {
                return json?.configurations?.chartFilters;
            }
        }
    } catch (error) { }

    return null;
}

export const setChartFilterConfiguration = (filter, values) => {
    try {
        const json = JSON.parse(localStorage.getItem(Constants.AUTH_KEY) || '');

        if (!json) {
            return false;
        }

        if (!json?.configurations) {
            json.configurations = { chartFilters: {} };
        }

        json.configurations.chartFilters[filter] = values;
        localStorage.setItem(Constants.AUTH_KEY, JSON.stringify(json));

        return true;
    } catch (error) { }

    return false;
}
