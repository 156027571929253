import React, { useEffect, useState } from 'react';
import { Page, Text, View, Document, StyleSheet, Font, Image } from '@react-pdf/renderer';
import { useTranslate } from '@pankod/refine-core';
import dayjs, { ConfigType } from "dayjs";
import { AERIAL_VEHICLE_TYPES, CLIMATE_CHOICES, MONITORING_NOT_FINISHED_CHOICES, MONITORING_STRATEGIES, MONITORING_TYPES, OILED_CHOICES, TERRESTRIAL_VEHICLE_TYPES, TIDE_CHOICES, WIND_CHOICES, ZOOGROUP_CHOICES } from 'helpers/constants';
import logo from '../../styles/logo-mineral-horiz.png'

// Create styles
Font.register({
    family: 'Arial',
    src: 'https://fonts.cdnfonts.com/s/29105/ARIAL.woff'
});
Font.register({
    family: 'Arial Italic',
    src: 'https://fonts.cdnfonts.com/s/29105/ARIALI 1.woff'
});

const styles = StyleSheet.create({
    body: {
        paddingTop: 35,
        paddingBottom: 65,
        paddingHorizontal: 35,
    },
    title: {
        fontSize: 16,
        textAlign: 'left',
        fontFamily: 'Arial',
        marginLeft: 40,
        color: "rgba(0,0,0,.80)",
    },
    titleContainer: {
        borderBottom: '1px solid #008B7E',
        marginBottom: 15
    },
    image: {
        marginVertical: 15,
        marginHorizontal: 45,
    },
    pageNumber: {
        position: 'absolute',
        fontSize: 12,
        bottom: 30,
        left: 0,
        right: 0,
        textAlign: 'center',
        color: 'grey',
    },
    label: {
        color: "rgba(0,0,0,.80)",
        fontSize: "12px",
        fontWeight: 600,
        lineHeight: 1.5
    },
    text: {
        color: "#008b7e",
        fontSize: "11px",
        fontWeight: 600,
        lineHeight: 1.5,
        marginBottom: 5,
        textAlign: 'justify'
    },
    viewContainer: {
        flexDirection: 'row',
        alignContent: 'space-between'
    },
    viewItem: {
        flexDirection: 'column',
        flex: 1
    },
    headerTitle: {
        fontSize: 20,
        textAlign: 'center',
        fontFamily: 'Arial',
        color: "rgba(0,0,0,.85)",
        marginTop: 15
    },
    headerContainer: {
        flexDirection: 'row',
        alignItems: 'center',
        marginBottom: 30
    },
    headerItem: {
        flex: 1,
        flexDirection: 'column',
        alignItems: 'center'
    },
    headerImage: {
        width: 150,
        textAlign: 'center'
    },
    textColumn: {
        color: "#008b7e",
        fontSize: "11px",
        fontWeight: 600,
        lineHeight: 1.5,
        marginBottom: 5,
        textAlign: 'justify',
        flex: 1,
        border: 'solid',
        borderWidth: 1

    },
    sightingsContainer: {
        flex: 1,
        marginVertical: 10
    }
});

// Create Document Component
export const MyDocument: React.FC<any> = ({ record, t, apiUrl, mapImage }) => {
    const dateFormat = "DD/MM/YYYY HH:mm";

    return (

        <Document>
            <Page style={styles.body}>

                <View style={styles.headerContainer}>
                    <View style={styles.headerItem}>
                        <Image style={styles.headerImage} src={logo}></Image>
                        <Text style={styles.headerTitle}>{t('phrases.monitoringReport')}</Text>
                    </View>
                </View>


                <View style={styles.titleContainer}>
                    <Text style={styles.title}>{t('phrases.initialData')}</Text>
                </View>

                <View style={styles.viewContainer}>
                    <View style={styles.viewItem}>
                        <Text style={styles.label}>{t('monitorings.fields.id')}</Text>
                        <Text style={styles.text}>{record?.id}</Text>
                    </View>

                    <View style={styles.viewItem}>
                        <Text style={styles.label}>{t('monitorings.fields.incident')}</Text>
                        <Text style={styles.text}>{record?.areaIncident.incident?.name}</Text>
                    </View>
                </View>

                <View style={styles.viewContainer}>
                    <View style={styles.viewItem}>
                        <Text style={styles.label}>{t('monitorings.fields.startDate')}</Text>
                        <Text style={styles.text}>{dayjs(record?.startDate).format(dateFormat)}</Text>
                    </View>

                    <View style={styles.viewItem}>
                        <Text style={styles.label}>{t('monitorings.fields.endDate')}</Text>
                        <Text style={styles.text}>{dayjs(record?.endDate).format(dateFormat)}</Text>
                    </View>
                </View>


                <View style={styles.viewContainer}>
                    <View style={styles.viewItem}>
                        <Text style={styles.label}>{t('monitorings.fields.responsible')}</Text>
                        <Text style={styles.text} >{record?.responsible?.name}</Text>
                    </View>
                    <View style={styles.viewItem}>
                        <Text style={styles.label}>{t('monitorings.fields.area')}</Text>
                        <Text style={styles.text}>{record?.areaIncident?.areaName}</Text>
                    </View>
                </View>

                <View style={styles.viewContainer}>
                    <View style={styles.viewItem}>
                        <Text style={styles.label}>{t('monitorings.fields.monitoringDuration')}</Text>
                        <Text style={styles.text}>{record?.monitoringDuration}</Text>
                    </View>
                </View>

                <View style={styles.titleContainer}>
                    <Text style={styles.title}>{t('phrases.faunaMonitoring')}</Text>
                </View>

                <View style={styles.viewContainer}>
                    <View style={styles.viewItem}>
                        <Text style={styles.label}>{t('monitorings.fields.type')}</Text>
                        <Text style={styles.text}>{t(MONITORING_TYPES.find((e: any) => e.value === record?.type)?.label || '')}</Text>
                    </View>
                    <View style={styles.viewItem}>
                        <Text style={styles.label}>{t('monitorings.fields.strategy')}</Text>
                        <Text style={styles.text}>{t(MONITORING_STRATEGIES.find((e: any) => e.value === record?.strategy)?.label || '')}</Text>
                    </View>
                </View>


                {
                    record?.strategy === "aerial" &&
                    <>
                        <View style={styles.viewContainer}>
                            <View style={styles.viewItem}>
                                <Text style={styles.label}>{t('monitorings.fields.aerialVehicleType')}</Text>
                                <Text style={styles.text}>{t(AERIAL_VEHICLE_TYPES.find((e: any) => e.value === record?.aerialVehicleType)?.label || '')}</Text>
                            </View>
                            <View style={styles.viewItem}>
                                <Text style={styles.label}>{t('monitorings.fields.flightHeight')}</Text>
                                <Text style={styles.text}>{record.flightHeight}</Text>
                            </View>
                        </View>

                        <View style={styles.viewContainer}>
                            <View style={styles.viewItem}>
                                <Text style={styles.label}>{t('monitorings.fields.airport')}</Text>
                                <Text style={styles.text}>{record.airport}</Text>
                            </View>
                            <View style={styles.viewItem}>
                                <Text style={styles.label}>{t('monitorings.fields.airportDestiny')}</Text>
                                <Text style={styles.text}>{record.airportDestiny}</Text>
                            </View>
                        </View>
                    </>
                }

                {
                    record?.strategy === "boarded" &&

                    <View style={styles.viewContainer}>
                        <View style={styles.viewItem}>
                            <Text style={styles.label}>{t('monitorings.fields.vesselName')}</Text>
                            <Text style={styles.text}>{record.vesselName}</Text>
                        </View>
                        <View style={styles.viewItem}>
                            <Text style={styles.label}>{t('monitorings.fields.harbor')}</Text>
                            <Text style={styles.text}>{record.harbor}</Text>
                        </View>
                        <View style={styles.viewItem}>
                            <Text style={styles.label}>{t('monitorings.fields.harborDestiny')}</Text>
                            <Text style={styles.text}>{record.harborDestiny}</Text>
                        </View>
                    </View>

                }

                {
                    record?.strategy === "terrestrial" &&
                    <View style={styles.viewContainer}>
                        <View style={styles.viewItem}>
                            <Text style={styles.label}>{t('monitorings.fields.terrestrialVehicleType')}</Text>
                            <Text style={styles.text}>{t(TERRESTRIAL_VEHICLE_TYPES.find((e: any) => e.value === record?.terrestrialVehicleType)?.label || '')}</Text>
                        </View>
                        <View style={styles.viewItem}>
                            <Text style={styles.label}>{t('monitorings.fields.monitoredLocation')}</Text>
                            <Text style={styles.text}>{record.monitoredLocation}</Text>
                        </View>
                    </View>
                }

                <View style={styles.titleContainer}>
                    <Text style={styles.title}>{t('phrases.monitoringCharacteristics')}</Text>
                </View>

                <View style={styles.viewContainer}>
                    <View style={styles.viewItem}>
                        <Text style={styles.label}>{t('monitorings.fields.totalDistance')}</Text>
                        <Text style={styles.text}>{record?.totalDistance}</Text>
                    </View>
                    <View style={styles.viewItem}>
                        <Text style={styles.label}>{t('monitorings.fields.tide')}</Text>
                        <Text style={styles.text}>{t(TIDE_CHOICES.find((e: any) => e.value === record?.tide)?.label || '')}</Text>
                    </View>
                </View>

                <View style={styles.viewContainer}>
                    <View style={styles.viewItem}>
                        <Text style={styles.label}>{t('monitorings.fields.wind')}</Text>
                        <Text style={styles.text}>{t(WIND_CHOICES.find((e: any) => e.value === record?.wind)?.label || '')}</Text>
                    </View>
                    <View style={styles.viewItem}>
                        <Text style={styles.label}>{t('monitorings.fields.windSpeed')}</Text>
                        <Text style={styles.text}>{record?.windSpeed}</Text>
                    </View>
                </View>

                <View style={styles.viewContainer}>
                    <View style={styles.viewItem}>
                        <Text style={styles.label}>{t('monitorings.fields.windDirection')}</Text>
                        <Text style={styles.text}>{record?.windDirection}</Text>
                    </View>
                    <View style={styles.viewItem}>
                        <Text style={styles.label}>{t('monitorings.fields.visibility')}</Text>
                        <Text style={styles.text}>{record?.visibility}</Text>
                    </View>
                </View>

                <View style={styles.viewContainer}>
                    <View style={styles.viewItem}>
                        <Text style={styles.label}>{t('monitorings.fields.temperature')}</Text>
                        <Text style={styles.text}>{record?.temperature}</Text>
                    </View>
                    <View style={styles.viewItem}>
                        <Text style={styles.label}>{t('monitorings.fields.climate')}</Text>
                        <Text style={styles.text}>{t(CLIMATE_CHOICES.find((e: any) => e.value === record?.climate)?.label || '')}</Text>
                    </View>
                </View>

                <View style={styles.viewContainer}>
                    <View style={styles.viewItem}>
                        <Text style={styles.label}>{t('monitorings.fields.startLatitude')}</Text>
                        <Text style={styles.text}>{record?.initialCoordinate.latitude}</Text>
                    </View>
                    <View style={styles.viewItem}>
                        <Text style={styles.label}>{t('monitorings.fields.startLongitude')}</Text>
                        <Text style={styles.text}>{record?.initialCoordinate.longitude}</Text>
                    </View>
                </View>

                <View style={styles.viewContainer}>
                    <View style={styles.viewItem}>
                        <Text style={styles.label}>{t('monitorings.fields.endLatitude')}</Text>
                        <Text style={styles.text}>{record?.finalCoordinate.latitude}</Text>
                    </View>

                    <View style={styles.viewItem}>
                        <Text style={styles.label}>{t('monitorings.fields.endLatitude')}</Text>
                        <Text style={styles.text}>{record?.finalCoordinate.longitude}</Text>
                    </View>
                </View>

                <Text style={styles.pageNumber} render={({ pageNumber, totalPages }) => (
                    `${pageNumber} / ${totalPages}`
                )} fixed />
            </Page>

            <Page style={styles.body}>

                <View style={styles.titleContainer}>
                    <Text style={styles.title}>{t('words.finishing')}</Text>
                </View>
                <View style={styles.viewContainer}>
                    <View style={styles.viewItem}>
                        <Text style={styles.label}>{t('monitorings.fields.finished')}</Text>
                        <Text style={styles.text}>{record?.finished === true ? t('words.yes') : t('words.no')}</Text>
                    </View>
                    <View style={styles.viewItem}>
                        <Text style={styles.label}>{t('monitorings.fields.observation')}</Text>
                        <Text style={styles.text}>{record?.observation}</Text>
                    </View>
                </View>

                {
                    record?.finished === false &&
                    <View style={styles.viewContainer}>
                        <View style={styles.viewItem}>
                            <Text style={styles.label}>{t('monitorings.fields.notFinishedReason')}</Text>
                            <Text style={styles.text}>{t(MONITORING_NOT_FINISHED_CHOICES.find((e: any) => e.value === record?.notFinishedReason)?.label || '')}</Text>
                        </View>
                        <View style={styles.viewItem}>
                            <Text style={styles.label}>{t('monitorings.fields.notFinishedJustification')}</Text>
                            <Text style={styles.text}>{record?.notFinishedJustification}</Text>
                        </View>
                    </View>
                }

                {
                    (record?.sightings && record?.sightings.length > 0) &&
                    <View style={styles.titleContainer}>
                        <Text style={styles.title}>{t('monitorings.fields.sightings')}</Text>
                    </View>
                }

                {
                    (record?.sightings && record?.sightings.length > 0) &&
                    <View style={styles.sightingsContainer}>

                        {
                            record?.sightings.map((item: any, i: any) =>
                                <View key={i}>

                                    <View style={styles.viewContainer}>
                                        <View style={styles.viewItem}>
                                            <Text style={styles.label}>{t('sightings.fields.id')}</Text>
                                            <Text style={styles.text}>{item?.id}</Text>
                                        </View>
                                        <View style={styles.viewItem}>
                                            <Text style={styles.label}>{t('sightings.fields.date')}</Text>
                                            <Text style={styles.text}>{dayjs(item?.date).format(dateFormat)}</Text>
                                        </View>
                                    </View>

                                    <View style={styles.viewContainer}>
                                        <View style={styles.viewItem}>
                                            <Text style={styles.label}>{t('sightings.fields.zooGroup')}</Text>
                                            <Text style={styles.text}>{t(ZOOGROUP_CHOICES.find((e: any) => e.value === item?.zooGroup)?.label) || item?.specie?.class}</Text>
                                        </View>
                                        <View style={styles.viewItem}>
                                            <Text style={styles.label}>{t('sightings.fields.specie')}</Text>
                                            <Text style={{ ...styles.text, ...{ fontFamily: (item?.identifiedSpecies ? 'Arial Italic' : 'Arial') } }}>{item?.identifiedSpecies ? item.specie.fullName : item.speciesIdentification}</Text>
                                        </View>
                                    </View>

                                    <View style={styles.viewContainer}>
                                        <View style={styles.viewItem}>
                                            <Text style={styles.label}>{t('sightings.fields.condition')}</Text>
                                            <Text style={styles.text}>{item?.alive ? t("words.alive") : t("words.dead")}</Text>
                                        </View>
                                        <View style={styles.viewItem}>
                                            <Text style={styles.label}>{t('sightings.fields.oiled')}</Text>
                                            <Text style={styles.text}>{t(OILED_CHOICES.find((e: any) => e.value === item.oiled)?.label) || ''}</Text>
                                        </View>
                                    </View>

                                    <View style={styles.viewContainer}>
                                        <View style={styles.viewItem}>
                                            <Text style={styles.label}>{t('sightings.fields.quantity')}</Text>
                                            <Text style={styles.text}>{item?.quantity}</Text>
                                        </View>
                                        <View style={styles.viewItem}>
                                            <Text style={styles.label}>{t('sightings.fields.coordinate')}</Text>
                                            <Text style={styles.text}>{`${item?.coordinate?.latitude} / ${item?.coordinate?.longitude}`}</Text>
                                        </View>
                                    </View>

                                    <View style={styles.viewContainer}>
                                        <View style={styles.viewItem}>
                                            <Text style={styles.label}>{t('sightings.fields.behavior')}</Text>
                                            <Text style={styles.text}>{item?.behavior?.map((e: any) => t(`phrases.${e}`) + "; ")}</Text>
                                        </View>
                                    </View>

                                    <View style={{ borderBottom: '1px solid #008B7E', marginBottom: 20, marginHorizontal: 40 }} />
                                </View>
                            )
                        }
                    </View>

                }

                <Text style={styles.pageNumber} render={({ pageNumber, totalPages }) => (
                    `${pageNumber} / ${totalPages}`
                )} fixed />
            </Page>

            {
                mapImage &&

                <Page style={styles.body} orientation='landscape'>
                    <View style={styles.titleContainer}>
                        <Text style={styles.title}>{t('words.map')}</Text>
                    </View>

                    <Image style={styles.image} src={mapImage} />

                    <Text style={styles.pageNumber} render={({ pageNumber, totalPages }) => (
                        `${pageNumber} / ${totalPages}`
                    )} fixed />
                </Page>
            }

            {
                (record?.images && record?.images.length > 0) &&

                <Page style={styles.body}>
                    <View style={styles.titleContainer}>
                        <Text style={styles.title}>{t('monitorings.fields.images')}</Text>
                    </View>

                    {record?.images.map((img: any) => { return <Image style={styles.image} key={img.id} src={apiUrl + img.url} /> })}

                    <Text style={styles.pageNumber} render={({ pageNumber, totalPages }) => (
                        `${pageNumber} / ${totalPages}`
                    )} fixed />
                </Page>
                    }


            {
                (record.sightings && record.sightings.length > 0) &&

                <Page style={styles.body}>
                    <View style={styles.titleContainer}>
                        <Text style={styles.title}>{t('sightings.fields.pdfImages')}</Text>
                    </View>

                    {
                        record.sightings.map((item: any, i: any) =>
                            <View key={i}>
                                <View style={styles.viewContainer}>
                                    <View style={styles.viewItem}>
                                        <Text style={styles.label}>{`${t('words.code')}: ${item?.id}`}</Text>
                                    </View>
                                </View>

                                {item?.images.map((img: any) => { console.log(img); return <Image style={styles.image} key={img.id} src={apiUrl + img.url} /> })}
                            </View>
                        )
                    }

                </Page>
            }

        </Document>
    )
};