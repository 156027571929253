import Popup from "./Popup";
import layer from "../../../styles/layer.png";


const resource = {
    id: 'areaincident',
    popup: Popup,
    label: 'Áreas',
    hasFilter: true,
    filterDefaultValues: [],
    icon: layer,
    visible: false,
    loadOnInit: false
};

export default resource;