import axios from "axios";
import { Constants } from "./constants";

export const downloadHelper = (url, fileName) => {
  axios({
    url: url,
    method: 'GET',
    responseType: 'blob', // important
    headers: {
        'Authorization': `Bearer ${localStorage.getItem(Constants.TOKEN_KEY)}`
    }
}).then((response) => {
    const url = window.URL.createObjectURL(new Blob([response.data]));
    const link = document.createElement('a');
    link.href = url;
    link.setAttribute('download', fileName); //or any other extension
    document.body.appendChild(link);
    link.click();
});
}



