import {
    useTranslate,
    useNavigation,
    useDelete,
    useUpdate
} from "@pankod/refine-core";

import {
    Menu,
    Icons,
    Popconfirm,
} from "@pankod/refine-antd";

const { FormOutlined, EyeFilled, DeleteFilled } = Icons;

interface Props {
    id: number;
    record?: any;
    resource: string;
    canEdit?: boolean;
    canDelete?: boolean;
    canShow?: boolean;
    canValidate?: boolean;
    canChangeStatus?: boolean;
    statusAttribute?: string;
}


export const MoreMenu: React.FC<Props> = ({ id, record, resource, canEdit = true, canDelete = false, canShow = true, canValidate = false, canChangeStatus = false, statusAttribute }) => {

    const { edit, show } = useNavigation();
    const t = useTranslate();
    const { mutate: mutateDelete } = useDelete();
    const { mutate: mutateValidate } = useUpdate();
    const { mutate: mutateChangeStatus } = useUpdate();

    return <Menu mode="vertical">
        {
            canEdit &&

            <Menu.Item
                key="1"
                style={{
                    fontSize: 15,
                    fontWeight: 500,
                }}
                icon={
                    <FormOutlined
                        style={{ color: "green", fontSize: "15px" }}
                    />
                }
                onClick={() => edit(resource, id)}
            >
                {t("buttons.edit")}
            </Menu.Item>
        }


        {
            canShow &&

            <Menu.Item
                key="2"
                style={{
                    fontSize: 15,
                    fontWeight: 500,
                }}
                icon={
                    <EyeFilled
                        style={{ color: "green", fontSize: "15px" }}
                    />
                }
                onClick={() => show(resource, id)}
            >
                {t("buttons.show")}
            </Menu.Item>
        }

        {
            canValidate &&

            <Menu.Item
                key="accept"
                style={{
                    fontSize: 15,
                    display: "flex",
                    alignItems: "center",
                    fontWeight: 500,
                }}
                disabled={record?.validated === true}
                icon={
                    <Icons.CheckCircleOutlined
                        style={{
                            color: "#52c41a",
                            fontSize: 17,
                            fontWeight: 500,
                        }}
                    />
                }
                onClick={() => {
                    mutateValidate({
                        resource: resource,
                        id: id,
                        values: {
                            validate: record?.validated !== true,
                        },
                    });
                }}
            >
                {t("buttons.validate")}
            </Menu.Item>
        }

        {
            (canChangeStatus && statusAttribute) &&

            <Menu.Item
                key="4"
                style={{
                    fontSize: 15,
                    display: "flex",
                    alignItems: "center",
                    fontWeight: 500,
                }}
                icon={

                    record?.[statusAttribute] === true ?
                        <Icons.CloseCircleOutlined
                            style={{
                                color: "red",
                                fontSize: 17,
                                fontWeight: 500,
                            }}
                        />
                        :
                        <Icons.CheckCircleOutlined
                            style={{
                                color: "#52c41a",
                                fontSize: 17,
                                fontWeight: 500,
                            }}
                        />
                }
                onClick={() => {
                    mutateChangeStatus({
                        resource: resource,
                        id: id,
                        values: {
                            [statusAttribute]: !record?.[statusAttribute],
                        },
                    });
                }}
            >
                {record?.[statusAttribute] === true ? t("buttons.disable") : t("buttons.enable")}
            </Menu.Item>
        }

        {
            canDelete &&

            <Popconfirm
                title={t('phrases.deleteConfirmation')}
                onConfirm={() => {
                    mutateDelete({
                        resource: resource,
                        id,
                        mutationMode: "undoable",
                    });
                }}
                onCancel={() => { }}
                okText={t('words.yes')}
                cancelText={t('words.no')}
            >

                <Menu.Item
                    key="5"
                    style={{
                        fontSize: 15,
                        fontWeight: 500,
                    }}
                    icon={
                        <DeleteFilled
                            style={{ color: "red", fontSize: "15px" }}
                        />
                    }
                >
                    {t("buttons.delete")}
                </Menu.Item>
            </Popconfirm>
        }

    </Menu>
}
