import { AccessControlProvider } from "@pankod/refine-core";
import { Constants } from './helpers/constants';


const hasPermission = (role: any, project = null) => {
    const data = localStorage.getItem(Constants.AUTH_KEY);
    let currentProject:any = null;

    try {
        currentProject = JSON.parse(localStorage.getItem(Constants.CURRENT_PROJECT_KEY) || '');
    } catch (error) { }

    if (data && currentProject) {
        const user = JSON.parse(data);

        if (!project && user.roles.indexOf(role) !== -1) {
            return true;
        }

        for (let i in user.projects) {
            if (user.projects[i].id === currentProject?.id) {
                if (user.projects[i].role === role && user.projects[i].enabled === true) {
                    return true;
                }
            }            
        }
    }

    return false;
}

export const accessControlProvider: AccessControlProvider = {


    can: async ({ resource, action, params }) => {

        let can = false;
        let reason = "Unauthorized";

        switch (resource) {
            case 'basilares':
                can =
                    hasPermission('ROLE_PROJECT_MANAGER') ||
                    hasPermission('ROLE_ADMIN') ||
                    hasPermission('ROLE_MONITORING_COORDINATOR');
                break;

            case 'users':
            case 'projects':
                can = hasPermission('ROLE_ADMIN');
                break;

            case 'incidents':
            case 'area_incidents':
                can =
                    hasPermission('ROLE_PROJECT_MANAGER')
                break;

            case 'taxonomics':
                can = 
                    hasPermission('ROLE_PROJECT_MANAGER') ||
                    hasPermission('ROLE_ADMIN');
                break;

            case 'project_collaborators':
                can = 
                    hasPermission('ROLE_ADMIN') ||
                    hasPermission('ROLE_PROJECT_MANAGER') ||
                    hasPermission('ROLE_MONITORING_COORDINATOR');
                break;

            case 'sightings':
            case 'monitorings':
            case 'map':            
                can =
                    hasPermission('ROLE_PROJECT_MANAGER') ||
                    hasPermission('ROLE_MONITORING_COORDINATOR') ||
                    hasPermission('ROLE_TASK_FORCE_LEADER') ||
                    hasPermission('ROLE_MONITOR') ||
                    hasPermission('ROLE_VISITOR');
                break;
            case 'dashboard':
                can = 
                    hasPermission('ROLE_ADMIN') || 
                    hasPermission('ROLE_PROJECT_MANAGER') ||
                    hasPermission('ROLE_MONITORING_COORDINATOR') ||
                    hasPermission('ROLE_TASK_FORCE_LEADER') ||
                    hasPermission('ROLE_MONITOR') ||
                    hasPermission('ROLE_VISITOR');
                break;
            case 'is_admin':
                can = hasPermission('ROLE_ADMIN')
                break
            default:
                break;
        }

        return Promise.resolve({ can: can, reason: reason });
    },
};
