import { Row, Col, Card, Typography, Drawer, Grid, Form } from "@pankod/refine-antd";
import { useTranslation } from "react-i18next";
import { useEffect, useState } from "react";
import { accessControlProvider } from "accessProvider";
import { useApiUrl, useCustomMutation, useList } from "@pankod/refine-core";
import { MONITORING_STRATEGIES, ZOOGROUP_CHOICES } from "helpers/constants";
import { SightingFilter, MonitoringFilter, GlobalFilter } from "./filter";
import Chart from "react-apexcharts";
import { setChartFilterConfiguration, getChartFilterConfiguration } from "helpers/functions";

const { Text } = Typography;
const colors = ['#2E93fA', '#66DA26', '#546E7A', '#E91E63', '#FF9800'];

export const DashboardPage: React.FC = () => {
    const { t } = useTranslation();
    const { mutate } = useCustomMutation();
    const apiUrl = useApiUrl();

    const pieChartOptions: any = {
        chart: {
            type: 'pie',
            width: "100%",
            height: 300,
            toolbar: {
                show: true,
                offsetX: 0,
                offsetY: 0,
                tools: {
                    download: true,
                    customIcons: [{
                        icon: '<span class="anticon anticon-search" style="margin-left:10px;margin-top:5px;"><svg viewBox="64 64 896 896" focusable="false" data-icon="search" width="1em" height="1em" fill="currentColor" aria-hidden="true"><path d="M909.6 854.5L649.9 594.8C690.2 542.7 712 479 712 412c0-80.2-31.3-155.4-87.9-212.1-56.6-56.7-132-87.9-212.1-87.9s-155.5 31.3-212.1 87.9C143.2 256.5 112 331.8 112 412c0 80.1 31.3 155.5 87.9 212.1C256.5 680.8 331.8 712 412 712c67 0 130.6-21.8 182.7-62l259.7 259.6a8.2 8.2 0 0011.6 0l43.6-43.5a8.2 8.2 0 000-11.6zM570.4 570.4C528 612.7 471.8 636 412 636s-116-23.3-158.4-65.6C211.3 528 188 471.8 188 412s23.3-116.1 65.6-158.4C296 211.3 352.2 188 412 188s116.1 23.2 158.4 65.6S636 352.2 636 412s-23.3 116.1-65.6 158.4z"></path></svg></span>',
                        title: t('words.filter'),
                        click: function (chart: any, options: any, e: any) {
                            showDrawer(chart.opts.chart.id);
                        }
                    }]
                },
            }
        },
        labels: [],
        title: {
            text: "title"
        },
        subtitle: {
            text: "subtitle"
        },
        responsive: [{
            breakpoint: 480,
            options: {
                chart: {
                    width: '100%'
                },
                legend: {
                    position: 'bottom'
                }
            }
        }],
        noData: {
            text: t('phrases.missingData'),
            align: 'center',
            verticalAlign: 'middle',
            offsetX: 0,
            offsetY: 0,
            style: {
                color: '#008B7E',
                fontSize: '16px',
                fontFamily: 'Helvetica, Arial, sans-serif',
            }
        }
    };

    const barChartOptions = {
        chart: {
            type: 'bar',
            width: "100%",
            height: 300,
            toolbar: {
                show: true,
                offsetX: 0,
                offsetY: 0,
                tools: {
                    download: true,
                    customIcons: [
                        {
                            icon: '<span class="anticon anticon-search" style="margin-left:10px;margin-top:5px;"><svg viewBox="64 64 896 896" focusable="false" data-icon="search" width="1em" height="1em" fill="currentColor" aria-hidden="true"><path d="M909.6 854.5L649.9 594.8C690.2 542.7 712 479 712 412c0-80.2-31.3-155.4-87.9-212.1-56.6-56.7-132-87.9-212.1-87.9s-155.5 31.3-212.1 87.9C143.2 256.5 112 331.8 112 412c0 80.1 31.3 155.5 87.9 212.1C256.5 680.8 331.8 712 412 712c67 0 130.6-21.8 182.7-62l259.7 259.6a8.2 8.2 0 0011.6 0l43.6-43.5a8.2 8.2 0 000-11.6zM570.4 570.4C528 612.7 471.8 636 412 636s-116-23.3-158.4-65.6C211.3 528 188 471.8 188 412s23.3-116.1 65.6-158.4C296 211.3 352.2 188 412 188s116.1 23.2 158.4 65.6S636 352.2 636 412s-23.3 116.1-65.6 158.4z"></path></svg></span>',
                            title: t('words.filter'),
                            click: function (chart: any, options: any, e: any) {
                                showDrawer(chart.opts.chart.id);
                            }
                        }
                    ]
                },
            }
        },
        colors: colors,
        plotOptions: {
            bar: {
                horizontal: false,
                columnWidth: '55%',
                endingShape: 'rounded'
            },
        },
        dataLabels: {
            enabled: true
        },

        title: {
            text: 'Title'
        },
        subtitle: {
            text: 'Subtitle'
        },
        stroke: {
            show: true,
            width: 2,
            colors: ['transparent']
        },
        xaxis: {
            categories: [],
        },
        yaxis: {
            title: {
                text: 'Total'
            }
        },
        fill: {
            opacity: 1
        },
        noData: {
            text: t('phrases.missingData'),
            align: 'center',
            verticalAlign: 'middle',
            offsetX: 0,
            offsetY: 0,
            style: {
                color: '#008B7E',
                fontSize: '16px',
                fontFamily: 'Helvetica, Arial, sans-serif',
            }
        }
    };

    const SIGHTINGS_TOTAL = 'sightings-total';
    const SIGHTINGS_OILED = 'sightings-oiled';
    const SIGHTINGS_CONDITION = 'sightings-condition';

    const MONITORINGS_TOTAL = 'monitorings-total';
    const MONITORINGS_DISTANCE = 'monitorings-distance';
    const MONITORINGS_TIME = 'monitorings-time';
    const GLOBAL_FILTER_KEY = 'global';

    const sightingsTotalFilterDefault = [{
        field: "chartType",
        operator: "eq",
        value: "total",
    }];

    const sightingsOiledFilterDefault = [{
        field: "chartType",
        operator: "eq",
        value: "oiled",
    }];

    const sightingsConditionFilterDefault = [{
        field: "chartType",
        operator: "eq",
        value: "condition",
    }];

    const monitoringsTotalFilterDefault = [{
        field: "chartType",
        operator: "eq",
        value: "total",
    }];

    const monitoringsDistanceFilterDefault = [{
        field: "chartType",
        operator: "eq",
        value: "distance",
    }];

    const monitoringsTimeFilterDefault = [{
        field: "chartType",
        operator: "eq",
        value: "time",
    }];

    const breakpoint = Grid.useBreakpoint();
    const [formS] = Form.useForm();

    const [open, setOpen] = useState(false);
    const [filterType, setFilterType] = useState<string | undefined>();
    const [sightingsTotalFilter, setSightingsTotalFilter] = useState<any>([...sightingsTotalFilterDefault, ...(getChartFilterConfiguration(SIGHTINGS_TOTAL) || [])]);
    const [sightingsOiledFilter, setSightingsOiledFilter] = useState<any>([...sightingsOiledFilterDefault, ...(getChartFilterConfiguration(SIGHTINGS_OILED) || [])]);
    const [sightingsConditionFilter, setSightingsConditionFilter] = useState<any>([...sightingsConditionFilterDefault, ...(getChartFilterConfiguration(SIGHTINGS_CONDITION) || [])]);
    const [sightingsTotalOptions, setSightingsTotalOptions] = useState<any>();
    const [sightingsTotalSeries, setSightingsTotalSeries] = useState<any>();
    const [sightingsOiledOptions, setSightingsOiledOptions] = useState<any>();
    const [sightingsOiledSeries, setSightingsOiledSeries] = useState<any>();
    const [sightingsConditionOptions, setSightingsConditionOptions] = useState<any>();
    const [sightingsConditionSeries, setSightingsConditionSeries] = useState<any>();
    const [monitoringsTotalFilter, setMonitoringsTotalFilter] = useState<any>([...monitoringsTotalFilterDefault, ...(getChartFilterConfiguration(MONITORINGS_TOTAL) || [])]);
    const [monitoringsDistanceFilter, setMonitoringsDistanceFilter] = useState<any>([...monitoringsDistanceFilterDefault, ...(getChartFilterConfiguration(MONITORINGS_DISTANCE) || [])]);
    const [monitoringsTimeFilter, setMonitoringsTimeFilter] = useState<any>([...monitoringsTimeFilterDefault, ...(getChartFilterConfiguration(MONITORINGS_TIME) || [])]);
    const [monitoringsTotalOptions, setMonitoringsTotalOptions] = useState<any>();
    const [monitoringsTotalSeries, setMonitoringsTotalSeries] = useState<any>();
    const [monitoringsDistanceOptions, setMonitoringsDistanceOptions] = useState<any>();
    const [monitoringsDistanceSeries, setMonitoringsDistanceSeries] = useState<any>();
    const [monitoringsTimeOptions, setMonitoringsTimeOptions] = useState<any>();
    const [monitoringsTimeSeries, setMonitoringsTimeSeries] = useState<any>();
    const [isAdmin, setIsAdmin] = useState(true);

    const { data: sightingsTotalData, isFetching: sightingsTotalIsFetching } = useList<any>({
        resource: "sightings/charts",
        config: {
            filters: sightingsTotalFilter,
        },
    });

    const { data: sightingsOiledData, isFetching: sightingsOiledIsFetching } = useList<any>({
        resource: "sightings/charts",
        config: {
            filters: sightingsOiledFilter,
        },
    });

    const { data: sightingsConditionData, isFetching: sightingsConditionIsFetching } = useList<any>({
        resource: "sightings/charts",
        config: {
            filters: sightingsConditionFilter,
        },
    });

    const { data: monitoringsTotalData, isFetching: monitoringsTotalIsFetching } = useList<any>({
        resource: "monitorings/charts",
        config: {
            filters: monitoringsTotalFilter,
        },
    });

    const { data: monitoringsDistanceData, isFetching: monitoringsDistanceIsFetching } = useList<any>({
        resource: "monitorings/charts",
        config: {
            filters: monitoringsDistanceFilter,
        },
    });

    const { data: monitoringsTimeData, isFetching: monitoringsTimeIsFetching } = useList<any>({
        resource: "monitorings/charts",
        config: {
            filters: monitoringsTimeFilter,
        },
    });

    useEffect(() => {
        accessDashboard();
    }, [])

    useEffect(() => {
        if (sightingsTotalData?.data) {
            renderSightingsTotalChart(sightingsTotalData?.data);
        }
    }, [sightingsTotalData])

    useEffect(() => {
        if (sightingsOiledData?.data) {
            renderSightingsOiledChart(sightingsOiledData?.data);
        }
    }, [sightingsOiledData])

    useEffect(() => {
        if (sightingsConditionData?.data) {
            renderSightingsConditionChart(sightingsConditionData?.data);
        }
    }, [sightingsConditionData])

    useEffect(() => {
        if (monitoringsTotalData?.data) {
            renderMonitoringsTotalChart(monitoringsTotalData?.data);
        }
    }, [monitoringsTotalData])

    useEffect(() => {
        if (monitoringsDistanceData?.data) {
            renderMonitoringsDistanceChart(monitoringsDistanceData?.data);
        }
    }, [monitoringsDistanceData])

    useEffect(() => {
        if (monitoringsTimeData?.data) {
            renderMonitoringsTimeChart(monitoringsTimeData?.data);
        }
    }, [monitoringsTimeData])


    const renderSightingsTotalChart = (data: any) => {
        let labels: string[] = [];
        let series = [];

        for (let i in data) {
            labels.push(`${t(ZOOGROUP_CHOICES.find((e: any) => e.value === data[i].zooGroup)?.label || '')} (${data[i].total})`);
            series.push(parseInt(data[i].total));
        }

        let opts = { ...pieChartOptions };
        opts.labels = labels;
        opts.chart.id = SIGHTINGS_TOTAL;
        opts.title.text = t('phrases.chartTitle1');
        opts.subtitle.text = t('phrases.chartSubtitle1');

        setSightingsTotalSeries(series);
        setSightingsTotalOptions(opts);
    }

    const renderSightingsOiledChart = (data: any) => {
        let oiledLabels: string[] = [];
        let series: any = [];

        let values: any = {
            yes: {
                name: t('words.yes'),
                data: []
            },
            no: {
                name: t('words.no'),
                data: []
            },
            uninformed: {
                name: t('words.uninformed'),
                data: []
            },
        };

        for (let i in data) {
            let total = 0;

            for (let j in data[i].values) {
                values[j].data.push(data[i].values[j]);
                total += data[i].values[j];
            }

            oiledLabels.push(`${t(ZOOGROUP_CHOICES.find((e: any) => e.value === data[i].zooGroup)?.label || '')} (${total})`);
        }

        for (let i in values) {
            series.push(values[i]);
        }

        let opts: any = { ...barChartOptions };
        opts.xaxis = { categories: oiledLabels };
        opts.chart = { ...opts.chart, ...{ id: SIGHTINGS_OILED } };
        opts.title = { text: t('phrases.chartTitle2') };
        opts.subtitle = { text: t('phrases.chartSubtitle2') };

        setSightingsOiledSeries(series);
        setSightingsOiledOptions(opts);
    }

    const renderSightingsConditionChart = (data: any) => {
        let labels: string[] = [];
        let series = [];

        let values: any = {
            dead: {
                name: t('words.dead'),
                data: []
            },
            alive: {
                name: t('words.alive'),
                data: []
            },
        };

        for (let i in data) {
            let total = 0;

            for (let j in data[i].values) {
                values[j].data.push(data[i].values[j]);
                total += data[i].values[j];
            }

            labels.push(`${t(ZOOGROUP_CHOICES.find((e: any) => e.value === data[i].zooGroup)?.label || '')} (${total})`);
        }

        for (let i in values) {
            series.push(values[i]);
        }

        let opts: any = { ...barChartOptions };
        opts.xaxis = { categories: labels };
        opts.chart = { ...opts.chart, ...{ id: SIGHTINGS_CONDITION } };
        opts.title = { text: t('phrases.chartTitle3') };
        opts.subtitle = { text: t('phrases.chartSubtitle2') };

        setSightingsConditionSeries(series);
        setSightingsConditionOptions(opts);
    }

    const renderMonitoringsTotalChart = (data: any) => {
        let labels: string[] = [];
        let series = [];

        for (let i in data) {
            labels.push(`${t(MONITORING_STRATEGIES.find((e: any) => e.value === data[i].strategy)?.label || '')} (${data[i].value})`);
            series.push(parseInt(data[i].value));
        }

        let opts = { ...pieChartOptions };
        opts.labels = labels;
        opts.chart.id = MONITORINGS_TOTAL;
        opts.title.text = t('phrases.chartTitle4');
        opts.subtitle.text = t('phrases.chartSubtitle3');

        setMonitoringsTotalSeries(series);
        setMonitoringsTotalOptions(opts);
    }

    const renderMonitoringsDistanceChart = (data: any) => {
        let labels: any = [];
        let series: any = [];

        for (let i in data) {
            const value = parseFloat(data[i].value).toFixed(2);
            labels.push([t(MONITORING_STRATEGIES.find((e: any) => e.value === data[i].strategy)?.label || ''), `(${value})`]);
            series.push(value)
        }

        let opts: any = { ...barChartOptions };
        opts.xaxis = {
            categories: labels, labels: {
                show: false
            }
        };
        opts.chart = { ...opts.chart, ...{ id: MONITORINGS_DISTANCE } };
        opts.title = { text: t('phrases.chartTitle5') };
        opts.subtitle = { text: t('phrases.chartSubtitle4') };
        opts.plotOptions.bar.distributed = true;

        setMonitoringsDistanceSeries([{ data: series }]);
        setMonitoringsDistanceOptions(opts);
    }

    const renderMonitoringsTimeChart = (data: any) => {
        let labels: any = [];
        let series: any = [];

        for (let i in data) {
            const value = parseInt(data[i].value);
            labels.push([t(MONITORING_STRATEGIES.find((e: any) => e.value === data[i].strategy)?.label || ''), `(${value})`]);
            series.push(value)
        }

        let opts: any = { ...barChartOptions };
        opts.xaxis = {
            categories: labels, labels: {
                show: false
            }
        };
        opts.chart = { ...opts.chart, ...{ id: MONITORINGS_TIME } };
        opts.title = { text: t('phrases.chartTitle6') };
        opts.subtitle = { text: t('phrases.chartSubtitle5') };
        opts.plotOptions.bar.distributed = true;

        setMonitoringsTimeSeries([{ data: series }]);
        setMonitoringsTimeOptions(opts);
    }

    const showDrawer = (filter: string) => {
        setOpen(true);
        setFilterType(filter)
    };

    const onClose = () => {
        setOpen(false);
        setFilterType(undefined);
    };

    const accessDashboard = async () => {
        const res = await accessControlProvider.can({ resource: 'is_admin', action: '' });
        setIsAdmin(res.can);
    }

    if (isAdmin) {
        return <></>
    }

    const applyGlobalFilterCallback = (values: any) => {

        let filters = [];

        for (let i in values) {
            if (values[i]) {
                filters.push({
                    "field": i,
                    "operator": "eq",
                    "value": values[i]
                })
            }
        }

        setChartFilterConfiguration(GLOBAL_FILTER_KEY, filters);
        updateUserFiltersConfiguration();

        setSightingsTotalFilter([...sightingsTotalFilterDefault, ...filters, ...filters]);
        setSightingsOiledFilter([...sightingsOiledFilterDefault, ...filters, ...filters]);
        setSightingsConditionFilter([...sightingsConditionFilterDefault, ...filters, ...filters]);
        setMonitoringsTotalFilter([...monitoringsTotalFilterDefault, ...filters, ...filters]);
        setMonitoringsDistanceFilter([...monitoringsDistanceFilterDefault, ...filters, ...filters]);
        setMonitoringsTimeFilter([...monitoringsTimeFilterDefault, ...filters, ...filters]);
    }

    const applyFilterCallback = (values: any, updateConfig: boolean = true) => {

        let filters = [];
        const global = getChartFilterConfiguration(GLOBAL_FILTER_KEY);

        for (let i in values) {
            if (i === 'chart')
                continue;

            if (values[i]) {

                if (i === 'date' || i === 'startDate') {
                    filters.push(
                        {
                            field: i,
                            operator: "gte",
                            value: values[i][0],
                        },
                        {
                            field: i,
                            operator: "lte",
                            value: values[i][1],
                        },
                    );
                } else {
                    filters.push({
                        "field": i,
                        "operator": "eq",
                        "value": values[i]
                    })
                }

            }
        }



        switch (values?.chart) {
            case SIGHTINGS_TOTAL:
                setSightingsTotalFilter([...sightingsTotalFilterDefault, ...filters, ...global]);
                break;

            case SIGHTINGS_OILED:
                setSightingsOiledFilter([...sightingsOiledFilterDefault, ...filters, ...global]);
                break;

            case SIGHTINGS_CONDITION:
                setSightingsConditionFilter([...sightingsConditionFilterDefault, ...filters, ...global]);
                break;

            case MONITORINGS_TOTAL:
                setMonitoringsTotalFilter([...monitoringsTotalFilterDefault, ...filters, ...global]);
                break;

            case MONITORINGS_DISTANCE:
                setMonitoringsDistanceFilter([...monitoringsDistanceFilterDefault, ...filters, ...global]);
                break;

            case MONITORINGS_TIME:
                setMonitoringsTimeFilter([...monitoringsTimeFilterDefault, ...filters, ...global]);
                break;

            default:
                break;
        }

        if (values?.chart) {
            setChartFilterConfiguration(values?.chart, filters);
        }

        if (updateConfig) {
            updateUserFiltersConfiguration();
        }

        onClose();
    }

    const updateUserFiltersConfiguration = () => {
        const config = getChartFilterConfiguration();
        mutate({
            url: `${apiUrl}/api/users/update/configurations`,
            method: "post",
            values: {
                configuration: { chartFilters: config }
            },
        },
            {
                onSuccess: (data, variables, context) => {
                },

                onError: (error, variables) => {
                }
            });
    }

    const NoDataComponent = (props: any) => <>
        <span className="anticon anticon-search"
            style={{
                right: 20, top: 20,
                position: 'absolute',
                cursor: 'pointer'
            }}
            onClick={() => showDrawer(props.filterId)}

        ><svg viewBox="64 64 896 896" focusable="false" data-icon="search" width="1em" height="1em" fill="currentColor" aria-hidden="true"><path d="M909.6 854.5L649.9 594.8C690.2 542.7 712 479 712 412c0-80.2-31.3-155.4-87.9-212.1-56.6-56.7-132-87.9-212.1-87.9s-155.5 31.3-212.1 87.9C143.2 256.5 112 331.8 112 412c0 80.1 31.3 155.5 87.9 212.1C256.5 680.8 331.8 712 412 712c67 0 130.6-21.8 182.7-62l259.7 259.6a8.2 8.2 0 0011.6 0l43.6-43.5a8.2 8.2 0 000-11.6zM570.4 570.4C528 612.7 471.8 636 412 636s-116-23.3-158.4-65.6C211.3 528 188 471.8 188 412s23.3-116.1 65.6-158.4C296 211.3 352.2 188 412 188s116.1 23.2 158.4 65.6S636 352.2 636 412s-23.3 116.1-65.6 158.4z"></path></svg></span>

        <div style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            minHeight: 300
        }}>
            <Text style={{ fontSize: 16 }}>{t('phrases.missingData')}</Text>
        </div>
    </>

    return (
        <>
            <GlobalFilter formProps={{ form: formS, applyglobalfiltercallback: applyGlobalFilterCallback }}></GlobalFilter>

            <Row gutter={[16, 16]}>

                <Col md={12}>
                    <Card headStyle={{ display: 'none' }} loading={monitoringsTotalIsFetching}>
                        {
                            (monitoringsTotalSeries && monitoringsTotalSeries.length > 0 && monitoringsTotalOptions) &&

                            <Chart
                                options={monitoringsTotalOptions}
                                series={monitoringsTotalSeries}
                                type="pie"
                                height="300"
                            />
                        }

                        {
                            (!monitoringsTotalSeries || monitoringsTotalSeries.length === 0) &&
                            <NoDataComponent filterId={MONITORINGS_TOTAL} />
                        }
                    </Card>
                </Col>

                <Col md={12}>
                    <Card headStyle={{ display: 'none' }} loading={sightingsTotalIsFetching}>
                        {
                            (sightingsTotalSeries && sightingsTotalSeries.length > 0 && sightingsTotalOptions) &&

                            <Chart
                                options={sightingsTotalOptions}
                                series={sightingsTotalSeries}
                                type="pie"
                                height="300"
                            />
                        }

                        {
                            (!sightingsTotalSeries || sightingsTotalSeries.length === 0) &&
                            <NoDataComponent filterId={SIGHTINGS_TOTAL} />
                        }
                    </Card>
                </Col>

                <Col md={12}>
                    <Card headStyle={{ display: 'none' }} loading={monitoringsDistanceIsFetching}>
                        {
                            (monitoringsDistanceSeries && monitoringsDistanceSeries.length > 0 && monitoringsDistanceOptions) &&

                            <Chart
                                options={monitoringsDistanceOptions}
                                series={monitoringsDistanceSeries}
                                type="bar"
                                height="300"
                            />
                        }

                        {
                            (!monitoringsDistanceSeries || monitoringsDistanceSeries.length === 0) &&
                            <NoDataComponent filterId={MONITORINGS_DISTANCE} />
                        }
                    </Card>
                </Col>

                <Col md={12}>
                    <Card headStyle={{ display: 'none' }} loading={monitoringsTimeIsFetching}>
                        {
                            (monitoringsTimeSeries && monitoringsTimeSeries.length > 0 && monitoringsTimeOptions) &&

                            <Chart
                                options={monitoringsTimeOptions}
                                series={monitoringsTimeSeries}
                                type="bar"
                                height="300"
                            />
                        }

                        {
                            (!monitoringsTimeSeries || monitoringsTimeSeries.length === 0) &&
                            <NoDataComponent filterId={MONITORINGS_TIME} />
                        }
                    </Card>
                </Col>

                <Col md={12}>
                    <Card headStyle={{ display: 'none' }} loading={sightingsOiledIsFetching}>
                        {
                            (sightingsOiledSeries && sightingsOiledSeries.length > 0 && sightingsOiledOptions) &&

                            <Chart
                                options={sightingsOiledOptions}
                                series={sightingsOiledSeries}
                                type="bar"
                                height="300"
                            />
                        }

                        {
                            (!sightingsOiledSeries || sightingsOiledSeries.length === 0) &&
                            <NoDataComponent filterId={SIGHTINGS_OILED} />
                        }
                    </Card>
                </Col>

                <Col md={12}>
                    <Card headStyle={{ display: 'none' }} loading={sightingsConditionIsFetching}>
                        {
                            (sightingsConditionSeries && sightingsConditionSeries.length > 0 && sightingsConditionOptions) &&

                            <Chart
                                options={sightingsConditionOptions}
                                series={sightingsConditionSeries}
                                type="bar"
                                height="300"
                            />
                        }

                        {
                            (!sightingsConditionSeries || sightingsConditionSeries.length === 0) &&
                            <NoDataComponent filterId={SIGHTINGS_CONDITION} />
                        }
                    </Card>
                </Col>
            </Row>

            <Drawer title={t('words.filters')}
                placement="right"
                bodyStyle={{ padding: 0 }}
                width={breakpoint.sm ? "500px" : "100%"}
                zIndex={1001}
                onClose={onClose}
                visible={open}>

                {
                    (filterType && filterType.includes('sightings')) &&
                    < SightingFilter
                        formProps={{ form: formS, applyfiltercallback: applyFilterCallback, chart: filterType }}

                    />
                }

                {
                    (filterType && filterType.includes('monitorings')) &&
                    < MonitoringFilter
                        formProps={{ form: formS, applyfiltercallback: applyFilterCallback, chart: filterType }}

                    />
                }

            </Drawer>
        </>

    );
};